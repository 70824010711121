import Pushe from "pushe-webpush";
import React from "react";
import PWAPrompt from "react-ios-pwa-prompt";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getSocial, loginProperties, updateData } from "../../actions";
import {
  BuyModal,
  DashboardButton,
  Drawer,
  LoadingMotion,
  NotifModal,
  Page,
  ProfileModal,
  RawNotifModal,
  StartGameModal,
  StartTrainModal,
} from "../../components";
import env from "../../env";
import newsModalIcon from "../../resources/img/avatar_icon.png";
import coinIcon from "../../resources/img/coin_main.png";
import dailyChallengeIcon from "../../resources/img/daily_challenge.png";
import dashboardLogo from "../../resources/img/dashboardLogo.png";
import drawerIcon from "../../resources/img/drawer.png";
import gpicTicketIcon from "../../resources/img/game_ticket.png";
import gemIcon from "../../resources/img/gem.svg";
import startButtonIcon from "../../resources/img/group24.png";
import messageIcon from "../../resources/img/ic_pm.png";
import gPicIcon from "../../resources/img/image_guest.png";
import avatarIcon from "../../resources/img/img_avatar.png";
import specialPlansIcon from "../../resources/img/img_euro_icon.png";
import leaderboardIcon from "../../resources/img/leaderboard_main.png";
// import tapCoinIcon from "../../resources/img/tap_coin.png";
import luckySpinIcon from "../../resources/img/lucky_g.png";
import inviteIcon from "../../resources/img/moarefi.png";
import moneyIcon from "../../resources/img/money_main.png";
import moneyWalletIcon from "../../resources/img/mony_wallet.png";
import chestIcon from "../../resources/img/newChest.png";
import progerssChallengeIcon from "../../resources/img/progers_challenge.png";
import startDailyIcon from "../../resources/img/round_dayly_challeng.png";
import shopPosterIcon from "../../resources/img/shop_poster.png";
import {
  checkForPaymentResult,
  getVoice,
  parseAction,
  webShare,
} from "../../utils";
import styles from "./Dashboard.module.css";
const { pusheKey } = env;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cash: 0,
      coin: 0,
      diamond: 0,
      tGpic: 0,
      isLoading: false,
      redirect: "",
      showDrawer: false,
      showStartGameModal: false,
      showStartTrainModal: false,
      showBuyModal: false,
      startGameModalContent: null,
      error: "",
      showNotifModal: false,
      showRawNotifModal: false,
      notifModal: null,
      soundMic: "",
      showProfileModal: false,
    };
  }

  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", this.onBackPressed);
    this.initPushe();
    this.loginProperties();
    setTimeout(() => {
      this.showNewsModal();
      this.checkForPaymentResult();
    }, 1500);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackPressed);
  }

  onBackPressed = (event) => {
    window.history.pushState(null, null, window.location.href);
  };

  initPushe = async () => {
    Pushe.init(pusheKey);
    const dialogRetryRate = 3600 * 24; // 24 hours
    Pushe.subscribe({ dialogRetryRate });
    try {
      const deviceToken = await Pushe.getDeviceId();
      console.log("TCL: Dashboard -> initPushe -> deviceToken", deviceToken);
      this.props.dispatch({
        type: "ADD_DEVICE_TOKEN",
        payload: { deviceToken },
      });
    } catch (error) {
      console.log("TCL: Dashboard -> initPushe -> error", error);
    }
  };

  loginProperties = async () => {
    try {
      this.setState({ isLoading: true });
      const {
        cash = 0,
        coin = 0,
        diamond = 0,
        xp = 0,
        tStar,
        tGpic,
      } = await this.props.dispatch(loginProperties());
      this.props.dispatch(
        updateData({ cash, coin, diamond, xp, tStar, tGpic })
      );
      this.setState({ isLoading: false, cash, coin, diamond, tGpic });
    } catch (error) {
      // this.setState({ isLoading: false, redirect: '/' });
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  checkForPaymentResult = () => {
    const res = checkForPaymentResult(window.location.search);
    console.log("TCL: Dashboard -> checkForPaymentResult -> res", res);
    // {status: true, coin: 30232, gpic: 21, diamond: 62}
    if (res && res.status !== undefined && this.props.isWaitingForPayRes) {
      this.props.dispatch({ type: "TOGGLE_WAIT_FOR_PAY_RES" });
      const { status, coin, gpic, diamond } = res;
      if (status === true) {
        this.props.dispatch(updateData({ coin, diamond, tGpic: gpic }));
        this.setState({
          showRawNotifModal: true,
          notifModal: {
            desc: "پرداخت با موفقیت انجام شد",
            type: "success",
          },
        });
      } else {
        this.setState({
          showRawNotifModal: true,
          notifModal: {
            desc: "پرداخت انجام نشد",
            type: "error",
          },
        });
      }
    }
  };

  showNewsModal = () => {
    if (
      this.props.data &&
      this.props.data.news &&
      this.props.data.news.isShow
    ) {
      this.setState({
        showNotifModal: true,
        notifModal: {
          ...this.props.data.news,
          button2Text: "بیخیال",
          icon: newsModalIcon,
        },
      });
      this.props.dispatch(updateData({ isShowNews: false }));
    }
  };

  onInviteFriendsClick = async () => {
    const { refCode, update } = this.props.data;
    try {
      const shared = await webShare({
        text:
          "اگه میخوای هم بازی کنی هم پول برنده بشی اپلیکیشن هوش برنده رو با این لینک نصب کن و از کد معرف من برای ثبت نام استفاده کن تا هر دو سکه ببریم" +
          "\n\n" +
          `کد معرف من: ${refCode}`,
        url:
          update && update.updateUrl
            ? update.updateUrl
            : "https://myket.ir/app/com.diacoipj.hoshbarandeh",
      });
    } catch (error) {
      console.log("TCL: Dashboard -> onInviteFriendsClick -> error", error);
      this.setState({ error: "دستگاه شما این قابلیت را پشتیبانی نمیکند" });
    }
  };

  onShareInstagramClick = async () => {
    try {
      const shared = await webShare({
        url: "http://www.instagram.com/hooshbarandeh",
      });
      if (shared) {
        await this.props.dispatch(getSocial({ instagram: true }));
      }
    } catch (error) {
      console.log("TCL: Dashboard -> onShareInstagramClick -> error", error);
      this.setState({ error: "دستگاه شما این قابلیت را پشتیبانی نمیکند" });
    }
  };

  onShareTelegramClick = async () => {
    try {
      const shared = await webShare({
        url: "http://telegram.me/hooshbarandeh",
      });
      if (shared) {
        await this.props.dispatch(getSocial({ telegram: true }));
      }
    } catch (error) {
      console.log("TCL: Dashboard -> onShareTelegramClick -> error", error);
      this.setState({ error: "دستگاه شما این قابلیت را پشتیبانی نمیکند" });
    }
  };

  ongPicClick = () => {
    if (this.props.data.gpic) {
      const { desc, prizes, sec } = this.props.data.gpic;
      const startGameModalContent = {
        title: "حدس تصویر",
        icon: gPicIcon,
        color: "#47545A",
        desc,
        prizes,
        redirectTo: "/gamePic",
        leaderBoardUrl: "/rank/gPic",
      };
      if (sec) {
        this.setState({
          showStartGameModal: true,
          startGameModalContent,
        });
      }
    } else {
      this.setState({ error: "اطلاعات مسابقه در دسترس نیست" });
    }
  };

  onDailyClick = () => {
    const { txtChallenge, dailyCount } = this.props.data;
    const startGameModalContent = {
      title: "چالش روزانه - رایگان",
      icon: startDailyIcon,
      color: "#5F6734",
      desc: txtChallenge,
      redirectTo: "/game/C",
      leaderBoardUrl: "/rank/chr",
      dailyCount,
    };
    this.setState({
      showStartGameModal: true,
      startGameModalContent,
    });
  };

  onTarinClick = (haveSec) => {
    if (haveSec > 0) {
      this.setState({ redirect: "/game/T" });
    } else {
      this.setState({
        showStartTrainModal: true,
        soundMic: getVoice.practice.coiner_practice,
      });
    }
  };

  render() {
    const {
      isLoading,
      redirect,
      cash,
      coin,
      diamond,
      tGpic,
      showDrawer,
      showStartGameModal,
      showStartTrainModal,
      showBuyModal,
      startGameModalContent,
      error,
      showNotifModal,
      showRawNotifModal,
      notifModal,
      soundMic,
      showProfileModal,
    } = this.state;

    const {
      name,
      avatar,
      dailyCat,
      inbox: inboxCount,
      roll: haveRoll,
      tStar,
      gpic,
    } = this.props.data;
    const {
      price: trainPrice,
      sec: trainSec,
      id: trainShopId,
      fullPrice: trainFullPrice,
    } = tStar || {};
    const { cat: gpicCat, sec: gpicSec } = gpic || {};
    const trainShopModalText =
      "با خرید تمرین سکه دار، به مدت 7 روز از تمرین کردنت سکه رایگان بگیر و خودتو برای پلن ها آماده کن...";

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        isProtected
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
        style={{
          flexDirection: "column",
          justifyContent: "flex-start",
          alugnItems: "center",
        }}
        soundMic={soundMic}
        goBackOnCloseError={false}
      >
        <PWAPrompt
          timesToShow={Infinity}
          promptOnVisit={1}
          copyTitle="نصب هوش برنده"
          copyBody="برای تجربه بهتر و دسترسی راحت‌تر به بازی، مراحل زیر را انجام دهید "
          copyShareButtonLabel="یک) منوی اشتراک‌گذاری را باز کرده"
          copyAddHomeButtonLabel="دو) گزینه افزودن به صفحه را انتخاب کنید"
          copyClosePrompt="بستن"
          permanentlyHideOnDismiss={false}
        />
        <Header onDrawerClick={() => this.setState({ showDrawer: true })} />
        <FirstRow
          dispatch={this.props.dispatch}
          onInviteFriendsClick={this.onInviteFriendsClick}
          {...{
            isLoading,
            cash,
            coin,
            diamond,
            tGpic,
            name,
            avatar,
            inboxCount,
          }}
        />
        {/* <GamePicRow
          dispatch={this.props.dispatch}
          gpicSec={gpicSec}
          ongPicClick={this.ongPicClick}
        /> */}
        <SecondRow
          dispatch={this.props.dispatch}
          {...{ dailyCat }}
          onDailyClick={this.onDailyClick}
          onTarinClick={() => this.onTarinClick(trainSec)}
          trainSec={trainSec}
        />
        <ThirdRow dispatch={this.props.dispatch} />
        <ForthRow dispatch={this.props.dispatch} {...{ haveRoll }} />
        <FifthRow dispatch={this.props.dispatch} />
        <Drawer
          visible={showDrawer}
          closeModal={() => this.setState({ showDrawer: false })}
          onProfileClick={() =>
            this.setState({ showDrawer: false, showProfileModal: true })
          }
          onInviteFriendsClick={this.onInviteFriendsClick}
          onShareTelegramClick={this.onShareTelegramClick}
          onShareInstagramClick={this.onShareInstagramClick}
        />
        <NotifModal
          visible={showNotifModal}
          closeModal={() => this.setState({ showNotifModal: false })}
          title={notifModal && notifModal.title}
          icon={notifModal && notifModal.icon}
          text={notifModal && notifModal.desc}
          button1Text={notifModal && notifModal.button}
          button2Text={notifModal && notifModal.button2Text}
          to={parseAction(notifModal && notifModal.action)}
        />
        <StartGameModal
          visible={showStartGameModal}
          closeModal={() => this.setState({ showStartGameModal: false })}
          content={startGameModalContent}
        />
        <StartTrainModal
          visible={showStartTrainModal}
          closeModal={() =>
            this.setState({ showStartTrainModal: false, soundMic: "" })
          }
          onBuyClick={() => this.setState({ showBuyModal: true })}
        />
        <BuyModal
          visible={showBuyModal}
          closeModal={() => this.setState({ showBuyModal: false })}
          topText={trainShopModalText}
          priceText={
            trainPrice ? `قیمت اصلی: ${trainPrice.addComma()} تومان` : ""
          }
          buttonText={"خرید تمرین سکه دار"}
          shopId={trainShopId}
        />
        <ProfileModal
          visible={showProfileModal}
          closeModal={() => this.setState({ showProfileModal: false })}
        />
        <RawNotifModal
          visible={showRawNotifModal}
          closeModal={() => this.setState({ showRawNotifModal: false })}
          text={notifModal && notifModal.desc}
          type={notifModal && notifModal.type}
        />
      </Page>
    );
  }
}

const Header = ({ onDrawerClick }) => {
  return (
    <div className={styles.headerRow}>
      <img src={dashboardLogo} className={styles.dashboardLogo} alt="" />
      <img
        src={drawerIcon}
        className={styles.drawerIcon}
        onClick={onDrawerClick}
        alt=""
      />
    </div>
  );
};

const FirstRow = ({
  isLoading = true,
  cash,
  coin,
  diamond,
  tGpic,
  name,
  avatar,
  inboxCount,
  onInviteFriendsClick,
}) => {
  return (
    <div className={styles.firstRow}>
      <div className={styles.firstRowLeft}>
        <div className={styles.firstRowInnerRow}>
          <DashboardButton
            className={styles.messages}
            marginRight
            to="/notifications"
            style={{ height: "7vh" }}
          >
            {inboxCount ? (
              <div className={styles.messageBadgeCntr}>{inboxCount}</div>
            ) : null}
            <img src={messageIcon} className={styles.centerIcon} alt="" />
          </DashboardButton>
          <DashboardButton
            className={styles.leaderBoard}
            marginRight
            to="/ranking"
            style={{ height: "7vh" }}
          >
            <img src={leaderboardIcon} className={styles.centerIcon} alt="" />
          </DashboardButton>
        </div>
        <div className={styles.firstRowInnerRow} style={{ paddingTop: "10px" }}>
          <DashboardButton
            className={styles.moneyWallet}
            marginRight
            to="/wallet"
            style={{ height: "7vh" }}
          >
            <img src={moneyWalletIcon} className={styles.centerIcon} alt="" />
          </DashboardButton>
          <DashboardButton
            className={styles.inviteFriends}
            marginRight
            // to="/"
            onClick={onInviteFriendsClick}
            style={{ height: "7vh" }}
          >
            <div className={styles.inviteText}>{"دعوت دوستان"}</div>
            <img src={inviteIcon} className={styles.inviteIcon} alt="" />
          </DashboardButton>
        </div>
      </div>
      <DashboardButton
        style={{
          justifyContent: "center",
          height: "19vh",
          background: "rgb(52,103,104)",
          background:
            "linear-gradient(90deg, rgba(52,103,104,1) 0%, rgba(52,90,97,1) 35%, rgba(34,70,70,1) 100%)",
        }}
        to="/profile"
      >
        <div className={styles.userDetailContainer}>
          <div className={styles.userAmountsContainer}>
            <div className={styles.amountContainer}>
              <div className={styles.amountText}>
                {isLoading ? (
                  <LoadingMotion width={10} height={10} />
                ) : (
                  coin.addComma()
                )}
              </div>
              <div className={styles.amountBG}></div>
              <img src={coinIcon} className={styles.userDetailIcon} alt="" />
            </div>
            <div className={styles.amountContainer}>
              <div className={styles.amountText}>
                {isLoading ? (
                  <LoadingMotion width={10} height={10} />
                ) : (
                  diamond.addComma()
                )}
              </div>
              <div className={styles.amountBG}></div>
              <img src={gemIcon} className={styles.userDetailIcon} alt="" />
            </div>
            <div className={styles.amountContainer}>
              <div className={styles.amountText}>
                {isLoading ? (
                  <LoadingMotion width={10} height={10} />
                ) : (
                  tGpic.addComma()
                )}
              </div>
              <div className={styles.amountBG}></div>
              <img
                src={gpicTicketIcon}
                className={styles.userDetailIcon}
                alt=""
              />
            </div>
            <div className={styles.amountContainer}>
              <div className={styles.amountText}>
                {isLoading ? (
                  <LoadingMotion width={10} height={10} />
                ) : (
                  cash.addComma()
                )}
              </div>
              <div className={styles.amountBG}></div>
              <img src={moneyIcon} className={styles.userDetailIcon} alt="" />
            </div>
          </div>
          <div className={styles.userProfile}>
            <img
              src={avatar || avatarIcon}
              className={styles.profilePic}
              alt=""
            />
            <div className={styles.selectProfilePic}>{"انتخاب"}</div>
            <div className={styles.usernameText}>{name}</div>
          </div>
        </div>
      </DashboardButton>
    </div>
  );
};

const SecondRow = ({ dailyCat, onDailyClick, onTarinClick, trainSec }) => {
  const dayLength = 60 * 60 * 24;
  const isMoreThanOneDay = trainSec > dayLength;

  return (
    <div className={styles.secondRow}>
      <DashboardButton
        style={{
          flex: "0.5",
          background: "rgb(97,97,105)",
          background:
            "linear-gradient(90deg, rgba(97,97,105,1) 0%, rgba(121,121,131,1) 35%, rgba(148,148,158,1) 100%)",
        }}
        marginRight
        onClick={onTarinClick}
      >
        <img src={progerssChallengeIcon} className={styles.buttonIcon} alt="" />
        <div className={styles.exerciseTextContainer}>
          <div className={styles.exerciseText}>{"تمرین"}</div>
          {trainSec > 0 ? (
            <div className={styles.exerciseTextBottom}>
              {trainSec.secToFa({
                d: isMoreThanOneDay,
                h: !isMoreThanOneDay,
                m: !isMoreThanOneDay,
                ceilD: isMoreThanOneDay,
                farsiDesc: isMoreThanOneDay,
              })}
            </div>
          ) : null}
        </div>
      </DashboardButton>
      <DashboardButton
        style={{
          background: "rgb(237,106,61)",
          background:
            "linear-gradient(90deg, rgba(237,106,61,1) 0%, rgba(244,151,51,1) 35%, rgba(251,199,39,1) 100%)",
        }}
        onClick={onDailyClick}
      >
        <img src={dailyChallengeIcon} className={styles.buttonIcon} alt="" />
        <div>
          <div className={styles.dailyChallengeTextTop}>{"چالش روزانه"}</div>
          <div
            className={styles.dailyChallengeTextBottom}
          >{`موضوع امروز: ${dailyCat}`}</div>
        </div>
      </DashboardButton>
    </div>
  );
};

const ThirdRow = (props) => {
  return (
    <div className={styles.thirdRow}>
      <DashboardButton
        style={{
          background: "rgb(18, 163, 134)",
          background:
            "linear-gradient(90deg, rgba(18,163,134,1) 0%, rgba(28,182,128,1) 35%, rgba(50,232,121,1) 100%)",
        }}
        to="/selectPlan/0"
      >
        <img src={startButtonIcon} className={styles.buttonIcon} alt="" />
        <div>
          <div className={styles.startTextTop}>{"جواب بده"}</div>
          <div className={styles.startTextBottom}>{"پول نقد برنده شو"}</div>
        </div>
      </DashboardButton>
    </div>
  );
};

const ForthRow = ({ haveRoll }) => {
  return (
    <div className={styles.forthRow}>
      <DashboardButton
        style={{
          background: "rgb(110,77,153)",
          background:
            "linear-gradient(90deg, rgba(110,77,153,1) 0%, rgba(92,104,178,1) 35%, rgba(72,142,201,1) 100%)",
        }}
        marginRight
        to="/wheel"
      >
        <img src={luckySpinIcon} className={styles.buttonIcon} alt="" />
        <div>
          {haveRoll ? <div className={styles.haveRollCntr}>{"1"}</div> : null}
          <div className={styles.luckySpinText}>{"گردونه"}</div>
        </div>
      </DashboardButton>
      <DashboardButton
        style={{
          background: "rgb(14,168,208)",
          background:
            "linear-gradient(90deg, rgba(14,168,208,1) 0%, rgba(9,176,220,1) 35%, rgba(0,198,244,1) 100%)",
        }}
        to="/shop"
      >
        <img src={shopPosterIcon} className={styles.buttonIcon} alt="" />
        <div>
          <div className={styles.shopText}>{"فروشگاه"}</div>
        </div>
      </DashboardButton>
    </div>
  );
};

const FifthRow = (props) => {
  return (
    <div className={styles.fifthRow}>
      <DashboardButton
        style={{
          justifyContent: "center",
          flex: "0.5",
          background: "rgb(235,228,147)",
          background:
            "linear-gradient(90deg, rgba(235,228,147,1) 0%, rgba(227,235,190,1) 35%, rgba(210,250,242,1) 100%)",
        }}
        marginRight
        to="/freeCoin"
      >
        <img
          src={chestIcon}
          className={styles.buttonIcon}
          style={{ padding: "2vh", position: "initial" }}
          alt=""
        />
        <div className={styles.chestTextTop}>{"رایگان‌ها"}</div>
      </DashboardButton>
      {/* <DashboardButton style={{ backgroundColor: "#E7A7A8" }}>
        <img src={tapCoinIcon} className={styles.buttonIcon} alt="" />
        <div>
          <div className={styles.tapCoinText}>{"دریافت سکه"}</div>
          <div className={styles.tapCoinText}>{`رایگان`}</div>
        </div>
      </DashboardButton> */}
      <DashboardButton
        style={{
          background: "rgb(67,26,42)",
          background:
            "linear-gradient(90deg, rgba(67,26,42,1) 0%, rgba(101,47,68,1) 35%, rgba(154,78,108,1) 100%)",
        }}
        to="/selectPlan/1"
      >
        <img
          src={specialPlansIcon}
          className={styles.buttonIcon}
          style={{ padding: "1vh" }}
          alt=""
        />
        <div>
          <div className={styles.specialPlanText}>{"لیگ‌های ویژه"}</div>
        </div>
      </DashboardButton>
    </div>
  );
};

const GamePicRow = ({ gpicSec, ongPicClick }) => {
  return (
    <div className={styles.thirdRow}>
      <DashboardButton
        style={{
          background: "rgb(18, 163, 134)",
          background:
            "linear-gradient(90deg, rgba(18,163,134,1) 0%, rgba(28,182,128,1) 35%, rgba(50,232,121,1) 100%)",
        }}
        onClick={ongPicClick}
      >
        <img
          src={gPicIcon}
          className={styles.buttonIcon}
          style={{ padding: "2vh" }}
          alt=""
        />
        <div>
          <div className={styles.gpicTextTop}>{"حدس بزن"}</div>
          {gpicSec ? (
            <div
              className={styles.gpicTextBottom}
            >{`زمان باقی‌مانده: ${gpicSec.secToFa()}`}</div>
          ) : null}
        </div>
      </DashboardButton>
    </div>
  );
};

const select = (store) => {
  return {
    data: store.auth.data,
    isWaitingForPayRes: store.auth.isWaitingForPayRes,
  };
};

export default connect(select)(Dashboard);
