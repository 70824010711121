import React from "react";

export const Sound = ({ src }) => {
  return (
    <>
      <audio autoPlay>
        <source src={src} type="audio/mpeg" />
      </audio>
    </>
  );
};
