import React from "react";
import styles from "./CoinPrice.module.css";
import coinIcon from "../resources/img/coin_main.png";

export const CoinPrice = ({ price, reverseColor, icon, iconStyle }) => (
  <div
    className={styles.container}
    style={reverseColor ? { backgroundColor: "white" } : null}
  >
    <div
      className={styles.price}
      style={reverseColor ? { color: "black" } : null}
    >
      {price}
    </div>
    <img
      src={icon || coinIcon}
      className={styles.coinImg}
      alt=""
      style={iconStyle}
    />
  </div>
);
