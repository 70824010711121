import env from "../env";
const { baseUrl } = env;

function viewAd({ origin } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/ad/view/${origin}`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: viewAd -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: viewAd -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: viewAd -> error", error);
      return Promise.reject(error);
    }
  };
}

function clickAd({ origin } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/ad/click/${origin}`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: clickAd -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: clickAd -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: clickAd -> error", error);
      return Promise.reject(error);
    }
  };
}

export { viewAd, clickAd };
