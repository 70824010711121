import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LogoIcon from "../../resources/img/splash_logo_object.png";
import styles from "./Splash.module.css";
import { Page, LoadingMotion } from "../../components";
import { getData, logInstall } from "../../actions";
import * as serviceWorker from "../../serviceWorker";
import { getVoice } from "../../utils";

class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "",
      isLoading: false,
      error: "",
      soundMic: ""
    };
  }

  async componentDidMount() {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.register({
      onSuccess: () => {
        this.props.dispatch(logInstall());
      }
    });

    this.setState({ soundMic: getVoice.winner.winner_voice });

    const { token } = this.props;
    if (token) {
      try {
        this.setState({ isLoading: true });
        const { acceptRules } = await this.props.dispatch(getData());
        const redirect = acceptRules ? "/dashboard" : "/laws";
        setTimeout(() => {
          this.setState({ isLoading: false, redirect });
        }, 3000);
      } catch (error) {
        console.log("TCL: Splash -> componentDidMount -> error", error);
        this.setState({ isLoading: false }, () => {
          this.setState({ error });
        });
      }
    } else {
      setTimeout(() => {
        this.setState({ redirect: "/login" });
      }, 3000);
    }
  }

  render() {
    const { redirect, isLoading, error, soundMic } = this.state;

    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    } else {
      return (
        <Page
          isProtected={false}
          style={{ justifyContent: "center" }}
          error={error}
          onCloseErrorModal={() => this.setState({ error: "" })}
          soundMic={soundMic}
        >
          <img src={LogoIcon} className={styles.logo} alt="" />
          {isLoading ? (
            <div style={{ position: "absolute", bottom: "20%" }}>
              <LoadingMotion loadingContainer />
            </div>
          ) : null}
        </Page>
      );
    }
  }
}

const select = store => {
  return {
    token: store.auth.token
  };
};

export default connect(select)(Splash);
