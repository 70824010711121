export function webShare({ title = "", text = "", url = "" }) {
  return new Promise(async (resolve, reject) => {
    if (navigator.share) {
      try {
        await navigator.share({ title, text, url });
        console.log("Successful share");
        return resolve(true);
      } catch (error) {
        console.log("Error sharing", error);
        return resolve(false);
      }
    } else {
      return reject("not supporting");
    }
  });
}
