import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import styles from "./Register.module.css";
import { register } from "../../actions";
import { Page, LoginBox } from "../../components";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: "",
      error: ""
    };
  }

  register = async (name, ref) => {
    const { phoneNumber: mobile } = this.props;
    this.setState({ loading: true });
    const onRegisterRedirectUrl = "/checkCode";

    try {
      const response = await this.props.dispatch(
        register({ mobile, name, ref })
      );
      console.log("TCL: Register -> register -> response", response);
      this.setState({
        loading: false,
        redirect: onRegisterRedirectUrl
      });
    } catch (error) {
      console.log("TCL: Register -> register -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  };

  render() {
    const { loading, redirect, error } = this.state;

    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    }

    return (
      <Page
        isProtected={false}
        style={{ justifyContent: "center" }}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        <LoginBox
          title="ثبت نام"
          firstPlaceholder="نام شما"
          secondPlaceholder="کد معرف (اختیاری)"
          buttonText="ثبت نام"
          loading={loading}
          onSubmit={this.register}
        />
      </Page>
    );
  }
}

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(Register);
