import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./RawNotifModal.module.css";
import { ModalForm } from "./index";
import tickIcon from "../resources/img/successBlob.png";
import wrongIcon from "../resources/img/errorBlob.png";
import infoIcon from "../resources/img/infoBlob.png";

class RawNotifModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { visible, type, text } = this.props;
    const icon =
      type === "success" ? tickIcon : type === "error" ? wrongIcon : infoIcon;

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          <img src={icon} className={styles.startIcon} alt="" />
          <div className={styles.resTitle}>{String(text)}</div>
          <div className={styles.startButtonText} onClick={this.closeModal}>
            {"بستن"}
          </div>
        </div>
      </ModalForm>
    );
  }
}

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(RawNotifModal);
