import env from "../env";
const { baseUrl } = env;

function getDaily() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/daily/getV2`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: getDaily -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: getDaily -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: getDaily -> error", error);
      return Promise.reject(error);
    }
  };
}

function checkDaily({ ad, data } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/daily/checkV2`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ ad, data })
      });
      console.log("TCL: checkDaily -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: checkDaily -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: checkDaily -> error", error);
      return Promise.reject(error);
    }
  };
}

function todayLeaderboardDaily() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/daily/leaderboard`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: todayLeaderboardDaily -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: todayLeaderboardDaily -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: todayLeaderboardDaily -> error", error);
      return Promise.reject(error);
    }
  };
}

function yesterdayLeaderboardDaily() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/daily/leaderboardP`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: yesterdayLeaderboardDaily -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log(
          "TCL: yesterdayLeaderboardDaily -> responseJson",
          responseJson
        );
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: yesterdayLeaderboardDaily -> error", error);
      return Promise.reject(error);
    }
  };
}

export {
  getDaily,
  checkDaily,
  todayLeaderboardDaily,
  yesterdayLeaderboardDaily
};
