// @flow
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import { persistStore, persistReducer } from 'redux-persist';
import persistConfig from './persistConfig';
import promise from './promise';

const history = createBrowserHistory();
const rootReducer = createRootReducer(history);
const router = routerMiddleware(history);
const enhancer = applyMiddleware(thunk, promise, router);
const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore(onComplete) {
  let store = createStore(persistedReducer, enhancer);
  let persistor = persistStore(store, undefined, onComplete);
  return { store, persistor };
}

export default { configureStore, history };
