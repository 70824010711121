export function checkForPaymentResult(searchString) {
  // "?status=true&coin=30232&gpic=21&diamond=62"
  searchString = searchString
    .replace("?", "")
    .replace(/=/g, '":')
    .replace(/&/g, ',"');
  if (searchString.length) {
    searchString = '{"' + searchString + "}";
    try {
      return JSON.parse(searchString);
    } catch (error) {
      console.log("TCL: checkForPaymentResult -> error", error);
      return {};
    }
  } else {
    return {};
  }
}
