import env from "../env";
const { baseUrl } = env;

function collaborateAddQuestion({ title, help, qO1, qO2, qO3, qO4 } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/collaborate/question/add`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ title, help, qO1, qO2, qO3, qO4 })
      });
      console.log("TCL: collaborateAddQuestion -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log(
          "TCL: collaborateAddQuestion -> responseJson",
          responseJson
        );
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: collaborateAddQuestion -> error", error);
      return Promise.reject(error);
    }
  };
}

function collaborateGetQuestion() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/collaborate/question/get`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: collaborateGetQuestion -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log(
          "TCL: collaborateGetQuestion -> responseJson",
          responseJson
        );
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: collaborateGetQuestion -> error", error);
      return Promise.reject(error);
    }
  };
}

export { collaborateAddQuestion, collaborateGetQuestion };
