import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./FAQ.module.css";
import { Page } from "../../components";
import { appFaq, updateData } from "../../actions";

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: "",
      list: [],
      error: ""
    };
  }

  componentDidMount() {
    this.appFaq();
  }

  appFaq = async () => {
    try {
      this.setState({ isLoading: true });
      const { coin, list } = await this.props.dispatch(appFaq());
      this.props.dispatch(updateData({ coin }));
      this.setState({ isLoading: false, list });
    } catch (error) {
      console.log("TCL: appFaq -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  render() {
    const { redirect, isLoading, list, error } = this.state;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        showDiamonds
        showPicTickets
        hasHeader
        style={!list.length ? { justifyContent: "center" } : null}
        isLoading={isLoading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        {list.length ? (
          list.map(({ question, answer }, index) => (
            <QA question={question} answer={answer} key={index} />
          ))
        ) : isLoading ? null : (
          <div className={styles.noTransactionText}>{"موردی ثبت نشده است"}</div>
        )}
      </Page>
    );
  }
}

const QA = ({ question, answer }) => (
  <div className={styles.qaContainer}>
    <div className={styles.qContainer}>{question}</div>
    <div className={styles.aContainer}>{answer}</div>
  </div>
);

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(FAQ);
