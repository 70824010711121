import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { ModalLoadingMotion } from './index';

export class ModalForm extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.outsideRef = React.createRef();
    this.state = {
      show: false
    };
  }

  componentWillReceiveProps(nextProps) {
    // check for the mounted props
    if (this.props.mounted && !nextProps.mounted) {
      return this.unMountStyle(); // call outro animation when mounted prop is false
    }

    if (!this.props.mounted && nextProps.mounted) {
      this.setState({
        // remount the node when the mounted prop is true
        show: true
      });
      this.mountStyle(); // call the into animation
    }
  }

  mountStyle = () => {
    //modalControl component motion won't work without timeout. it should by async
    setTimeout(() => {
      if (this.outsideRef.current && this.outsideRef.current.classList) {
        this.outsideRef.current.classList.add('show');
      }
      if (this.formRef.current && this.formRef.current.classList) {
        this.formRef.current.classList.add('show');
      }
    }, 1);
  };

  unMountStyle = () => {
    setTimeout(() => {
      if (this.outsideRef.current && this.outsideRef.current.classList) {
        this.outsideRef.current.classList.remove('show');
      }
      if (this.formRef.current && this.formRef.current.classList) {
        this.formRef.current.classList.remove('show');
      }
    }, 1);
  };

  transitionEnd = () => {
    if (!this.props.mounted) {
      // remove the node on transition end when the mounted prop is false
      this.setState({
        show: false
      });
    }
  };

  render() {
    const {
      children,
      containerChildren,
      handleCloseModal,
      style,
      outsideStyle,
      outsideClass,
      appendOutsideClass,
      modalClass,
      isLoading
    } = this.props;

    return (
      this.state.show && (
          <div
            className={`${outsideClass || 'modalOutside'}${
              appendOutsideClass ? ' ' + appendOutsideClass : ''
            }`}
            style={outsideStyle}
            onClick={handleCloseModal}
            ref={this.outsideRef}
            onTransitionEnd={this.transitionEnd}
          >
            <div
              className={`modalForm${modalClass ? ' ' + modalClass : ''}`}
              style={style}
              onClick={event => event.stopPropagation()}
              ref={this.formRef}
              onTransitionEnd={this.transitionEnd}
            >
              {/* <ModalLoadingMotion
                isLoading={isLoading}
                style={{ top: '0px' }}
              /> */}
              {children}
            </div>
            {containerChildren}
          </div>
      )
    );
  }
}

ModalForm.defaultProps = {
  isLoading: false
};

ModalForm.propTypes = {
  mounted: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]).isRequired,
  containerChildren: PropTypes.node,
  handleCloseModal: PropTypes.func.isRequired,
  style: PropTypes.object,
  outsideStyle: PropTypes.object,
  outsideClass: PropTypes.string,
  appendOutsideClass: PropTypes.string,
  modalClass: PropTypes.string,
  isLoading: PropTypes.bool
};
