import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import styles from "./Login.module.css";
import { Page, LoginBox } from "../../components";
import { checkMobile } from "../../actions";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: "",
      error: ""
    };
  }

  checkMobile = async phoneNumber => {
    this.setState({ loading: true });
    const onLoginRedirectUrl = {
      "0": "/register",
      "1": "/checkCode"
    };

    try {
      const response = await this.props.dispatch(checkMobile(phoneNumber));
      console.log("TCL: Login -> checkMobile -> response", response);
      if (response === 0 || response === 1) {
        this.setState({
          loading: false,
          redirect: onLoginRedirectUrl[response]
        });
      } else {
        //! err wrong number
        this.setState({ loading: false }, () => {
          this.setState({ error: true });
        });
      }
    } catch (error) {
      console.log("TCL: Login -> checkMobile -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  };

  render() {
    const { loading, redirect, error } = this.state;

    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    }

    return (
      <Page
        isProtected={false}
        style={{ justifyContent: "center" }}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        <LoginBox
          title="ورود"
          firstPlaceholder="شماره همراه"
          tipText="استفاده و عضویت از اپلیکیشن هوش برنده کاملا رایگان می‌باشد"
          buttonText="ورود"
          loading={loading}
          onSubmit={this.checkMobile}
          type="tel"
        />
      </Page>
    );
  }
}

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(Login);
