import env from "../env";
const { baseUrl } = env;

function listLeaderboard() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/leaderboard/list`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: listLeaderboard -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: listLeaderboard -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: listLeaderboard -> error", error);
      return Promise.reject(error);
    }
  };
}

function xpMonthlyLeaderboard() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/leaderboard/xpMonthly`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: xpMonthlyLeaderboard -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: xpMonthlyLeaderboard -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: xpMonthlyLeaderboard -> error", error);
      return Promise.reject(error);
    }
  };
}

function xpLeaderboard() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/leaderboard/xp`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: xpLeaderboard -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: xpLeaderboard -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: xpLeaderboard -> error", error);
      return Promise.reject(error);
    }
  };
}

function cashLeaderboard() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/leaderboard/cash`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: cashLeaderboard -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: cashLeaderboard -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: cashLeaderboard -> error", error);
      return Promise.reject(error);
    }
  };
}

function marathonLeaderboard({ type, sub } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/marathon/list/${type}/${sub}`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: marathonLeaderboard -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: marathonLeaderboard -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: marathonLeaderboard -> error", error);
      return Promise.reject(error);
    }
  };
}

export {
  listLeaderboard,
  xpMonthlyLeaderboard,
  xpLeaderboard,
  cashLeaderboard,
  marathonLeaderboard
};
