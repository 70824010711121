import env from "../env";
const { baseUrl } = env;

function intro() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/intro/view`, {
        headers: {
          'Authorization': token
        }
      });
      console.log("TCL: intro -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: intro -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: intro -> error", error);
      return Promise.reject(error);
    }
  };
}

export { intro };
