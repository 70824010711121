import React from "react";
import styles from "./MessageRow.module.css";
import { Redirect } from "react-router-dom";
import { Button } from "./index";
import notifIcon from "../resources/img/notif_bell.png";
import circleClose from "../resources/img/circleClose.png";
import circleOpen from "../resources/img/circleOpen.png";
import { setInboxView, setInboxClick } from "../actions";
import { connect } from "react-redux";
import { parseAction } from "../utils";

class MessageRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maximize: false,
      redirect: ""
    };
  }

  toggleSize = () => {
    const { maximize } = this.state;
    const { id } = this.props;
    this.setState({ maximize: !maximize }, async () => {
      if (!maximize) {
        try {
          await this.props.dispatch(setInboxView({ id }));
        } catch (error) {
          console.log("TCL: MessageRow -> setInboxView -> error", error);
        }
      }
    });
  };

  onButtonClick = async () => {
    const { id, action } = this.props;
    try {
      this.setState({ redirect: parseAction(action) });
      await this.props.dispatch(setInboxClick({ id }));
    } catch (error) {
      console.log("TCL: MessageRow -> setInboxClick -> error", error);
    }
  };

  render() {
    const { maximize, redirect } = this.state;
    const { icon, title, desc, btn_text } = this.props;
    // action: "game"
    // btn_text: "بزن بریم"
    // desc: "با سلام↵اطلاعیه مهم : از امروز در ماراتن استقامت در صورتی که چند بار بازی کنید ، امتیاز های شما با هم جمع خواهد شد↵امیدوارم رقابت خیلی خوبی رو تجربه کنید.↵باتشکر"
    // icon: ""
    // id: 45
    // image: ""
    // imageBtn: ""
    // title: "ماراتن استقامت"
    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    }

    return (
      <Row
        maximize={maximize}
        onClick={this.toggleSize}
        title={title}
        icon={icon}
        desc={desc}
        btn_text={btn_text}
        onButtonClick={this.onButtonClick}
      />
    );
  }
}

const Row = ({
  maximize,
  onClick,
  title,
  icon,
  desc,
  btn_text,
  onButtonClick
}) => (
  <div
    className={styles.container}
    onClick={onClick}
    style={{ flexDirection: "column", paddingBottom: "8vw" }}
  >
    <div className={styles.header}>
      <div className={styles.leftContainer}>
        <div
          className={styles.titleCenter}
          style={
            maximize
              ? { justifyContent: "center", height: "15vw", fontSize: "1.3em" }
              : null
          }
        >
          {title}
        </div>
        {!maximize ? (
          <div className={styles.text} style={{ maxHeight: "16vw" }}>
            {desc}
          </div>
        ) : null}
      </div>
      <img src={icon || notifIcon} className={styles.icon} alt="" />
    </div>

    {maximize ? (
      <>
        <div className={styles.text}>{desc}</div>
        <Button text={btn_text} onClick={onButtonClick} />
        <div className={styles.toggleSizeButton}>
          {"نمایش کمتر"}
          <img
            src={circleClose}
            style={{ width: "3vh", height: "3vh", marginRight: "1vh" }}
          />
        </div>
      </>
    ) : (
      <div className={styles.toggleSizeButton}>
        {"نمایش بیشتر"}
        <img
          src={circleOpen}
          style={{ width: "3vh", height: "3vh", marginRight: "1vh" }}
        />
      </div>
    )}
  </div>
);

export default connect()(MessageRow);
