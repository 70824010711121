import env from "../env";
const { baseUrl } = env;

function viewDialogId({ dialogID } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/dialog/view/${dialogID}`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: viewDialogId -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: viewDialogId -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: viewDialogId -> error", error);
      return Promise.reject(error);
    }
  };
}

function clickDialogId({ dialogID } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/dialog/click/${dialogID}`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: clickDialogId -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: clickDialogId -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: clickDialogId -> error", error);
      return Promise.reject(error);
    }
  };
}

export { viewDialogId, clickDialogId };
