import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Circle } from "rc-progress";
import styles from "./Game.module.css";
import {
  Page,
  LoadingMotion,
  NotifModal,
  GameResModal,
  DailyResModal,
  RawNotifModal
} from "../../components";
import dailyChallengeIcon from "../../resources/img/icon_dayly.png";
import dollarIcon from "../../resources/img/img_dollar_icon.png";
import euroIcon from "../../resources/img/img_euro_icon.png";
import esteghamatIcon from "../../resources/img/img_esteghamat_icon.png";
import silverIcon from "../../resources/img/noghre_icon.png";
import goldIcon from "../../resources/img/talaei_icon.png";
import boronzIcon from "../../resources/img/boronz_icon.png";
import pelekaniIcon from "../../resources/img/step.png";
import trainIcon from "../../resources/img/training_object.png";
import greenTimerRing from "../../resources/img/timer.png";
import marathonTimerRing from "../../resources/img/timer_m.png";
import pelekaniTimerRing from "../../resources/img/timer_pelekani.png";
import dollarTimerRing from "../../resources/img/timer_usd_dollar.png";
import euroTimerRing from "../../resources/img/timer_usd_euro.png";
import dailyChallengeTimerRing from "../../resources/img/timer_daily.png";
import infoIcon from "../../resources/img/info_i.png";
import parchamIcon from "../../resources/img/parcham.png";
import timelineOnIcon from "../../resources/img/timeline_on.png";
import timelineOffIcon from "../../resources/img/timeline_off.png";
import zamanHelpIcon from "../../resources/img/zaman_button.png";
import zamanHelpPelekaniIcon from "../../resources/img/zaman_button_pelekani.png";
import zamanHelpMarathonIcon from "../../resources/img/zaman_button_m.png";
import zamanHelpEuroIcon from "../../resources/img/zaman_button_euro.png";
import zamanHelpDollarIcon from "../../resources/img/zaman_button_dollar.png";
import zamanHelpDisableIcon from "../../resources/img/zaman_button_click.png";
import rahnamaHelpIcon from "../../resources/img/rahnama_button.png";
import rahnamaHelpPelekaniIcon from "../../resources/img/rahnama_pelekani.png";
import rahnamaHelpMarathonIcon from "../../resources/img/rahnama_button_m.png";
import rahnamaHelpEuroIcon from "../../resources/img/rahnama_button_euro.png";
import rahnamaHelpDollarIcon from "../../resources/img/rahnama_button_dollar.png";
import rahnamaHelpDisableIcon from "../../resources/img/rahnama_button_click.png";
import gozineHelpIcon from "../../resources/img/dogozinei_button.png";
import gozineHelpPelekaniIcon from "../../resources/img/dogozinei_button_pelekani.png";
import gozineHelpMarathonIcon from "../../resources/img/dogozinei_button_m.png";
import gozineHelpEuroIcon from "../../resources/img/dogozinei_button_euro.png";
import gozineHelpDollarIcon from "../../resources/img/dogozinei_button_dollar.png";
import gozineHelpDisableIcon from "../../resources/img/dogozinei_button_click.png";
import playAgainHelpIcon from "../../resources/img/pashimani_object.png";
import playAgainHelpPelekaniIcon from "../../resources/img/pashimani_object_pelekani.png";
import playAgainHelpMarathonIcon from "../../resources/img/pashimani_object_m.png";
import playAgainHelpEuroIcon from "../../resources/img/pashimani_object_euro.png";
import playAgainHelpDollarIcon from "../../resources/img/pashimani_object_dollar.png";
import coinIcon from "../../resources/img/coin_main.png";
import closeIcon from "../../resources/img/magex.png";
import moneyIcon from "../../resources/img/money.png";
import gemIcon from "../../resources/img/gem.svg";
import {
  newSession,
  getQuestion,
  checkQuestion,
  getDaily,
  helpQuestion,
  checkDaily,
  updateData
} from "../../actions";
import { parseAction, getVoice, getRandom } from "../../utils";
import { goBack } from "connected-react-router";

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.timerInterval = null;
    this.state = {
      type: "",
      loading: false,
      redirect: "",
      selectedId: null,
      timer: 10,
      totalTime: 10,
      winCount: 0,
      isLoadingQuestion: false,
      earnedCash: 0,
      cash: 0,
      xp: 0,
      diamond: 0,
      gpic: 0,
      isLoadingHelp: [],
      help: [
        { type: "50", price: 0, valid: false, count: 0 },
        { type: "help", price: 0, valid: false, count: 0 },
        { type: "time", price: 0, valid: false, count: 0 }
      ],
      Qnumber: 0,
      question: "",
      options: [],
      answerId: null,
      showRawNotifModal: false,
      showNotifModal: false,
      dialog: null,
      helpTextResult: "",
      help50Result: [],
      showGameResModal: false,
      session: "",
      isTimeout: false,
      haveTimeline: false,
      haveTimelineCheckPoints: true,
      isDailyChallenge: false,
      dailyDataList: [],
      dailySelectedIds: [],
      showDailyResModal: false,
      currentDailyGameResult: null,
      lastDailyGameResult: null,
      error: "",
      isNearEndOfTheTime: false,
      soundMic: "",
      backgroundColor: "",
      timerRing: "",
      gameIcon: "",
      helpIcons: "",
      showCash: false,
      showDiamond: false
    };
  }

  componentDidMount() {
    this.handleBackButton();

    const {
      match: {
        params: { type }
      }
    } = this.props;
    let haveTimeline = false;
    let haveTimelineCheckPoints = true;
    let showCash = true;
    let showDiamond = false;
    if (type === "G" || type === "S" || type === "B") {
      haveTimeline = true;
    }
    if (type === "T") {
      haveTimeline = true;
      haveTimelineCheckPoints = false;
    }
    if (type === "C" || type === "T") {
      showCash = false;
    }
    if (type.includes("USD") || type.includes("EUR")) {
      showDiamond = true;
    }

    const {
      backgroundColor,
      timerRing,
      gameIcon,
      helpIcons
    } = this.getTypeConfigs(type);

    this.setState(
      {
        type,
        haveTimeline,
        haveTimelineCheckPoints,
        showCash,
        showDiamond,
        backgroundColor,
        timerRing,
        gameIcon,
        helpIcons
      },
      () => {
        if (type === "C") {
          this.getDaily();
        } else {
          this.newSession();
        }
      }
    );
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackPressed);
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.closeModalTimeout) {
      clearTimeout(this.closeModalTimeout);
    }
  }

  onBackPressed = event => {
    event.preventDefault();

    if (
      this.state.error ||
      (!this.state.isDailyChallenge && !this.state.session) ||
      (this.state.isDailyChallenge && this.state.Qnumber === 0)
    ) {
      this.props.dispatch(goBack());
      return;
    }

    this.setState(
      {
        showNotifModal: true,
        dialog: {
          titleDialog: "خروج",
          descDialog: "آیا برای خروج مطمعن هستید؟",
          btnDialog: "بله",
          onClick: () => this.props.dispatch(goBack()),
          dismissModal: () => {
            window.history.pushState(null, null, window.location.href);
          }
        }
      },
      () => {
        this.closeModalTimeout = setTimeout(() => {
          if (
            this.state.showNotifModal &&
            this.state.dialog.titleDialog === "خروج"
          ) {
            this.setState({ showNotifModal: false, dialog: null }, () => {
              window.history.pushState(null, null, window.location.href);
            });
          }
        }, 4000);
      }
    );
  };

  handleBackButton = () => {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", this.onBackPressed);
  };

  startTimer = () => {
    this.timerInterval = setInterval(() => {
      if (this.state.timer > 0) {
        if (this.state.timer <= this.state.totalTime / 5) {
          this.setState({
            timer: this.state.timer - 1,
            isNearEndOfTheTime: true,
            soundMic: getRandom(getVoice.time.beforeEnd)
          });
        } else {
          this.setState({ timer: this.state.timer - 1 });
        }
      } else {
        this.checkQuestion();
      }
    }, 1000);
  };

  getDaily = async () => {
    const { type: game } = this.state;
    try {
      this.setState({
        loading: true,
        isDailyChallenge: true,
        totalTime: 120,
        timer: 120
      });
      const { success, data, dialog } = await this.props.dispatch(
        getDaily({ game })
      );
      if (success) {
        // [{id:123, title: 'gg', options: [{id: 123, isTrue: 0, text: 'ewg'}]}]
        this.setState(
          {
            loading: false,
            dailyDataList: data
          },
          () => {
            this.startTimer();
            this.getQuestion();
          }
        );
      } else {
        this.setState({
          loading: false,
          showNotifModal: true,
          dialog: {
            ...dialog,
            dismissModal: () => this.props.dispatch(goBack())
          }
        });
      }
    } catch (error) {
      console.log("TCL: Game -> getDaily -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  };

  newSession = async () => {
    const { type: game } = this.state;
    try {
      this.setState({ loading: true });
      const { message, success, result: session } = await this.props.dispatch(
        newSession({ game })
      );
      if (success && session) {
        this.setState({ loading: false, session }, this.getQuestion);
      } else {
        if (!message) {
          throw true;
        }

        const {
          action,
          bgColor,
          bgColor2,
          btn,
          btnColor,
          btnTextColor,
          foreColor,
          icon,
          text
        } = message;

        this.setState({ loading: false }, () => {
          this.setState({
            showNotifModal: true,
            dialog: {
              titleDialog: "متاسفانه...",
              descDialog: text,
              btnDialog: btn,
              actionDialog: parseAction(action),
              dismissModal: () => this.setState({ redirect: "/dashboard" })
            }
          });
        });
      }
    } catch (error) {
      console.log("TCL: Game -> newSession -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  };

  getTypeConfigs = type => {
    let backgroundColor = "";
    let timerRing = greenTimerRing;
    let gameIcon = dailyChallengeIcon;
    let helpIcons = {
      "50": gozineHelpIcon,
      try: playAgainHelpIcon,
      help: rahnamaHelpIcon,
      time: zamanHelpIcon
    };

    switch (true) {
      case type.includes("USD"):
        backgroundColor = "#404638";
        timerRing = dollarTimerRing;
        gameIcon = dollarIcon;
        helpIcons = {
          "50": gozineHelpDollarIcon,
          try: playAgainHelpDollarIcon,
          help: rahnamaHelpDollarIcon,
          time: zamanHelpDollarIcon
        };
        break;

      case type.includes("EUR"):
        backgroundColor = "#00446B";
        timerRing = euroTimerRing;
        gameIcon = euroIcon;
        helpIcons = {
          "50": gozineHelpEuroIcon,
          try: playAgainHelpEuroIcon,
          help: rahnamaHelpEuroIcon,
          time: zamanHelpEuroIcon
        };
        break;

      case type.includes("M"):
        backgroundColor = "#A038EF";
        timerRing = marathonTimerRing;
        gameIcon = esteghamatIcon;
        helpIcons = {
          "50": gozineHelpMarathonIcon,
          try: playAgainHelpMarathonIcon,
          help: rahnamaHelpMarathonIcon,
          time: zamanHelpMarathonIcon
        };
        break;

      case type.includes("C"):
        backgroundColor = "#5F6734";
        gameIcon = dailyChallengeIcon;
        timerRing = dailyChallengeTimerRing;
        break;

      case type.includes("B"):
        backgroundColor = "#038e6e";
        gameIcon = boronzIcon;
        break;

      case type.includes("S"):
        backgroundColor = "#038e6e";
        gameIcon = silverIcon;
        break;

      case type.includes("G"):
        backgroundColor = "#038e6e";
        gameIcon = goldIcon;
        break;

      case type.includes("P"):
        backgroundColor = "#2999CB";
        timerRing = pelekaniTimerRing;
        gameIcon = pelekaniIcon;
        helpIcons = {
          "50": gozineHelpPelekaniIcon,
          try: playAgainHelpPelekaniIcon,
          help: rahnamaHelpPelekaniIcon,
          time: zamanHelpPelekaniIcon
        };
        break;

      case type.includes("T"):
        backgroundColor = "#038e6e";
        gameIcon = trainIcon;
        break;

      default:
        break;
    }
    return { backgroundColor, timerRing, gameIcon, helpIcons };
  };

  getQuestion = async () => {
    const { session, isDailyChallenge, dailyDataList, Qnumber } = this.state;

    if (isDailyChallenge) {
      if (dailyDataList[Qnumber]) {
        const answerId = dailyDataList[Qnumber].options.reduce(
          (ans, { id, isTrue }) => (!ans && isTrue ? id : ans),
          0
        );
        const question = dailyDataList[Qnumber].title;
        const options = dailyDataList[Qnumber].options;

        this.setState({
          answerId,
          selectedId: null,
          Qnumber: Qnumber + 1,
          question,
          options
        });
      } else {
        this.checkQuestion();
      }
    } else {
      try {
        this.setState({ isLoadingQuestion: true, isNearEndOfTheTime: false });
        const {
          cash,
          coin,
          diamond,
          gpic,
          help,
          number,
          result,
          sec
        } = await this.props.dispatch(getQuestion({ session }));
        this.props.dispatch(updateData({ cash, coin, diamond, tGpic: gpic }));
        this.setState({
          answerId: null,
          helpTextResult: "",
          help50Result: [],
          selectedId: null,
          isTimeout: false,
          cash,
          diamond,
          gpic,
          help,
          Qnumber: number,
          question: result.title,
          options: result.options,
          totalTime: sec,
          timer: sec
        });
        this.startTimer();
        this.setState({ isLoadingQuestion: false });
      } catch (error) {
        console.log("TCL: Game -> getQuestion -> error", error);
        this.setState({ isLoadingQuestion: false }, () => {
          this.setState({ error });
        });
      }
    }
  };

  checkQuestion = async () => {
    const {
      selectedId,
      session,
      isDailyChallenge,
      dailySelectedIds
    } = this.state;

    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    try {
      this.setState({ isLoadingQuestion: true });
      if (isDailyChallenge) {
        const {
          current: currentDailyGameResult,
          last: lastDailyGameResult
        } = await this.props.dispatch(
          checkDaily({ ad: false, data: dailySelectedIds })
        );
        // { all: 15, correct: 0, wrong: 15 }
        this.setState({
          isLoadingQuestion: false,
          showDailyResModal: true,
          currentDailyGameResult,
          lastDailyGameResult
        });
      } else {
        const {
          answerId,
          cash: earnedCash,
          cashAll,
          coin,
          diamond,
          gpic,
          message,
          success,
          timeout,
          videoActive,
          xp
        } = await this.props.dispatch(
          checkQuestion({ id: selectedId, optionID: selectedId, session })
        );
        this.props.dispatch(
          updateData({ cash: cashAll, coin, diamond, tGpic: gpic, xp })
        );
        this.setState({
          isLoadingQuestion: false,
          answerId,
          cash: cashAll,
          earnedCash,
          diamond,
          gpic,
          xp,
          showGameResModal: true,
          isTimeout: timeout
        });
        //message=>
        //action: "refs"
        // bgColor: "#6C63FF"
        // bgColor2: "#6C63FF"
        // btn: "بزن بریم"
        // btnColor: "#F4F4F4"
        // btnTextColor: "#1D393D"
        // foreColor: "#FFFFFF"
        //icon: "<?xml..."
        // id: 18
        // text: "300 تا سکه رایگان میخ"
      }
    } catch (error) {
      console.log("TCL: Game -> checkQuestion -> error", error);
      this.setState({ isLoadingQuestion: false }, () => {
        this.setState({ error });
      });
    }
  };

  helpQuestion = async type => {
    const { session, isLoadingHelp } = this.state;
    try {
      this.setState({ isLoadingHelp: { ...isLoadingHelp, [type]: true } });
      const { cash, coin, help, result, success } = await this.props.dispatch(
        helpQuestion({ session, help: type })
      );
      this.props.dispatch(updateData({ cash, coin }));
      if (success) {
        const extraTime = 3;
        const { help: helpText, help50 } = result;
        if (type === "time") {
          this.setState(({ timer, totalTime }) => ({
            timer: timer + 10 + extraTime,
            totalTime: totalTime + 10 + extraTime,

            isLoadingHelp: { ...isLoadingHelp, [type]: false },
            cash,
            help
          }));
        } else if (type === "try") {
          this.setState(
            ({ timer, totalTime }) => ({
              timer: timer + extraTime,
              totalTime: totalTime + extraTime,
              showGameResModal: false,

              isLoadingHelp: { ...isLoadingHelp, [type]: false },
              cash,
              help
            }),
            this.getQuestion
          );
        } else if (type === "50") {
          this.setState(({ timer, totalTime }) => ({
            timer: timer + extraTime,
            totalTime: totalTime + extraTime,
            help50Result: help50,

            isLoadingHelp: { ...isLoadingHelp, [type]: false },
            cash,
            help
          }));
        } else if (type === "help") {
          this.setState(({ timer, totalTime }) => ({
            timer: timer + extraTime,
            totalTime: totalTime + extraTime,
            helpTextResult: helpText,

            isLoadingHelp: { ...isLoadingHelp, [type]: false },
            cash,
            help
          }));
        }
      } else {
        this.setState(
          { isLoadingHelp: { ...isLoadingHelp, [type]: false } },
          () => {
            this.setState({ error: true });
          }
        );
      }
    } catch (error) {
      console.log("TCL: Game -> helpQuestion -> error", error);
      this.setState(
        { isLoadingHelp: { ...isLoadingHelp, [type]: false } },
        () => {
          this.setState({ error });
        }
      );
    }
  };

  onQuestionReportResult = success => {
    this.setState({
      showRawNotifModal: true,
      dialog: {
        descDialog: success
          ? "گزارش خطا با موفقیت ارسال شد، با تشکر از همکاری شما"
          : "اشکالی در گزارش خطا به وجود آمده است، لطفا دوباره تلاش کنید",
        type: success ? "success" : "info"
      }
    });
  };

  render() {
    const {
      redirect,
      timer,
      isNearEndOfTheTime,
      totalTime,
      loading,
      isLoadingQuestion,
      Qnumber,
      question,
      options,
      selectedId,
      answerId,
      showRawNotifModal,
      showNotifModal,
      dialog,
      earnedCash,
      cash,
      diamond,
      xp,
      help,
      helpTextResult,
      help50Result,
      showGameResModal,
      session,
      isTimeout,
      haveTimeline,
      haveTimelineCheckPoints,
      isDailyChallenge,
      dailySelectedIds,
      showDailyResModal,
      currentDailyGameResult,
      lastDailyGameResult,
      error,
      soundMic,
      backgroundColor,
      timerRing,
      gameIcon,
      helpIcons,
      showCash,
      showDiamond,
      type: gameType,
      isLoadingHelp
    } = this.state;

    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    }

    return (
      <Page
        hasHeader
        headerColor={backgroundColor}
        pageBackgroundColor={backgroundColor}
        isLoading={loading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
        soundMic={soundMic}
      >
        <QuestionNumber
          number={Qnumber}
          color={backgroundColor}
          key={Qnumber}
        />
        <TopContainer
          timer={timer}
          isNearEndOfTheTime={isNearEndOfTheTime}
          totalTime={totalTime}
          isLoadingQuestion={isLoadingQuestion}
          backgroundColor={backgroundColor}
          timerRing={timerRing}
          gameIcon={gameIcon}
          haveTimeline={haveTimeline}
          haveTimelineCheckPoints={haveTimelineCheckPoints}
          Qnumber={Qnumber}
          cash={showCash ? cash : false}
          diamond={showDiamond ? diamond : false}
        />
        <HelpContainer
          help={help}
          helpTextResult={helpTextResult}
          onHelpResultClick={() => this.setState({ helpTextResult: "" })}
          onHelpClick={type => this.helpQuestion(type)}
          borderBottomColor={backgroundColor}
          helpIcons={helpIcons}
          isLoadingHelp={isLoadingHelp}
        />
        <BottomContainer
          backgroundColor={backgroundColor}
          question={question}
          options={options}
          selectedId={selectedId}
          answerId={answerId}
          help50Result={help50Result}
          onOptionClick={selectedId => {
            if (isDailyChallenge) {
              if (!this.state.selectedId) {
                this.setState(
                  {
                    dailySelectedIds: [...dailySelectedIds, { id: selectedId }],
                    selectedId
                  },
                  () => {
                    setTimeout(() => {
                      this.getQuestion();
                    }, 700);
                  }
                );
              } else {
                return;
              }
            } else if (answerId || isLoadingQuestion) {
              return;
            } else if (selectedId == this.state.selectedId) {
              this.checkQuestion();
            } else {
              this.setState({ selectedId });
            }
          }}
        />
        <NotifModal
          visible={showNotifModal}
          closeModal={() => {
            this.setState({ showNotifModal: false });
            if (this.closeModalTimeout) {
              clearTimeout(this.closeModalTimeout);
            }
          }}
          dismissModal={dialog && dialog.dismissModal}
          title={dialog && dialog.titleDialog}
          icon={infoIcon}
          text={dialog && dialog.descDialog}
          button1Text={dialog && dialog.btnDialog}
          button2Text={"بیخیال"}
          button2OnClick={dialog && dialog.button2OnClick}
          to={dialog && dialog.actionDialog}
          onClick={dialog && dialog.onClick}
        />
        <GameResModal
          visible={showGameResModal}
          closeModal={() => {
            if (haveTimeline && Qnumber == 15 && selectedId == answerId) {
              this.setState({ redirect: "/dashboard", soundMic: "" });
            } else if (selectedId == answerId) {
              this.setState(
                { showGameResModal: false, soundMic: "" },
                this.getQuestion
              );
            } else {
              this.setState({ redirect: "/dashboard", soundMic: "" });
            }
          }}
          isWin={selectedId == answerId}
          playVoice={({ isWin, isTimeout, isLastGame }) => {
            const soundMic = isWin
              ? isLastGame
                ? getRandom(getVoice.winner)
                : getRandom(getVoice.right_answer)
              : isTimeout
              ? getRandom(getVoice.time.onEnd)
              : getRandom(getVoice.gameover);

            this.setState({ soundMic });
          }}
          isLastGame={haveTimeline && Qnumber == 15}
          isTimeout={isTimeout || (timer === 0 && !selectedId)}
          helpButton={help
            .filter(({ type, valid }) => type == "try" && valid)
            .map(({ type, price, valid, count }, index) => (
              <HelpButton
                key={index}
                type={type}
                price={price}
                valid={valid}
                count={count}
                onClick={() => this.helpQuestion(type)}
                helpIcons={helpIcons}
                isSpecialPlan={
                  gameType.includes("USD") || gameType.includes("EUR")
                }
                isLoading={isLoadingHelp[type]}
              />
            ))}
          cash={earnedCash}
          xp={xp}
          session={session}
          onEndButtonText={gameType === "T" ? "صفحه اصلی" : "مشاهده کیف پول"}
          goToOnEnd={() =>
            this.setState({
              redirect: gameType === "T" ? "/dashboard" : "/wallet"
            })
          }
          onReportResult={this.onQuestionReportResult}
        />
        <DailyResModal
          visible={showDailyResModal}
          closeModal={() =>
            this.setState({ showDailyResModal: false, redirect: "/dashboard" })
          }
          currentGame={currentDailyGameResult}
          lastGame={lastDailyGameResult}
          goToLeaderBoard={() => this.setState({ redirect: "/rank/chr" })}
        />
        <RawNotifModal
          visible={showRawNotifModal}
          closeModal={() => this.setState({ showRawNotifModal: false })}
          text={dialog && dialog.descDialog}
          type={dialog && dialog.type}
        />
      </Page>
    );
  }
}

const TopContainer = ({
  timer,
  isNearEndOfTheTime,
  totalTime,
  isLoadingQuestion,
  backgroundColor = "#8d8107",
  timerRing,
  gameIcon,
  haveTimeline,
  haveTimelineCheckPoints,
  Qnumber,
  cash,
  diamond
}) => (
  <div className={styles.topContainer} style={{ backgroundColor }}>
    {cash !== false ? (
      <CoinContainer coin={`${cash.addComma()} تومان`} icon={moneyIcon} />
    ) : null}
    {diamond !== false ? (
      <CoinContainer
        coin={diamond}
        outerStyle={{ top: "4vh", right: "1vh", minWidth: "8vh" }}
        innerStyle={{ backgroundColor: "#030E3C", color: "gold" }}
        icon={gemIcon}
      />
    ) : null}
    <img src={gameIcon} className={styles.gameIcon} alt="" />
    {haveTimeline ? (
      <TimeLine
        Qnumber={Qnumber}
        haveTimelineCheckPoints={haveTimelineCheckPoints}
      />
    ) : null}
    <Timer
      timer={timer}
      isNearEndOfTheTime={isNearEndOfTheTime}
      totalTime={totalTime}
      isLoadingQuestion={isLoadingQuestion}
      timerColor={backgroundColor}
      timerRing={timerRing}
    />
  </div>
);

const HelpContainer = ({
  help,
  helpTextResult,
  onHelpResultClick,
  onHelpClick,
  borderBottomColor,
  helpIcons,
  isLoadingHelp
}) => (
  <div className={styles.helpContainer} style={{ borderBottomColor }}>
    {helpTextResult ? (
      <div className={styles.helpTextResult} onClick={onHelpResultClick}>
        <img src={closeIcon} className={styles.closeIcon} alt="" />
        {helpTextResult}
      </div>
    ) : null}
    {help && help.length
      ? help
          .filter(({ type }) => type !== "try")
          .map(({ type, price, valid, count }, index) => (
            <HelpButton
              key={index}
              type={type}
              price={price}
              valid={valid}
              count={count}
              helpIcons={helpIcons}
              onClick={() => onHelpClick(type)}
              isLoading={isLoadingHelp[type]}
            />
          ))
      : null}
  </div>
);

const HelpButton = ({
  type,
  price,
  valid,
  count,
  onClick,
  helpIcons,
  isSpecialPlan,
  isLoading
}) => (
  <div
    className={styles.helpButton}
    onClick={() => {
      if (valid && !isLoading) {
        onClick();
      }
    }}
  >
    {isLoading ? (
      <div className={styles.loadingContainer}>
        <LoadingMotion width={20} height={20} />
      </div>
    ) : null}
    <img
      alt=""
      className={styles.helpButtonIcon}
      src={
        type == "50"
          ? valid
            ? helpIcons["50"]
            : gozineHelpDisableIcon
          : type == "help"
          ? valid
            ? helpIcons["help"]
            : rahnamaHelpDisableIcon
          : type == "time"
          ? valid
            ? helpIcons["time"]
            : zamanHelpDisableIcon
          : type == "try"
          ? valid
            ? helpIcons["try"]
            : helpIcons["try"]
          : ""
      }
    />
    {!count && valid ? (
      <div className={styles.helpButtonPrice}>
        {price}
        <img
          alt=""
          src={isSpecialPlan && type === "try" ? gemIcon : coinIcon}
          className={styles.helpButtonCoinIcon}
        />
      </div>
    ) : null}
  </div>
);

const BottomContainer = ({
  backgroundColor,
  question,
  options,
  answerId,
  selectedId,
  onOptionClick,
  help50Result
}) => (
  <div className={styles.bottomContainer}>
    <QuestionContainer question={question} />
    <div className={styles.answersContainer}>
      <div className={styles.answersRow}>
        <AnswerButton
          disable={help50Result.some(
            res =>
              options && options[0] && options[0].id && options[0].id === res
          )}
          text={options && options[0] ? options[0].text : ""}
          isWaiting={!answerId}
          wasCorrect={options && options[0] && options[0].id == answerId}
          selected={options && options[0] && options[0].id == selectedId}
          borderColor={backgroundColor}
          onClick={() =>
            onOptionClick(options && options[0] ? options[0].id : null)
          }
        />
        <AnswerButton
          disable={help50Result.some(
            res =>
              options && options[1] && options[1].id && options[1].id === res
          )}
          text={options && options[1] ? options[1].text : ""}
          isWaiting={!answerId}
          wasCorrect={options && options[1] && options[1].id == answerId}
          selected={options && options[1] && options[1].id == selectedId}
          borderColor={backgroundColor}
          onClick={() =>
            onOptionClick(options && options[1] ? options[1].id : null)
          }
        />
      </div>
      <div className={styles.answersRow}>
        <AnswerButton
          disable={help50Result.some(
            res =>
              options && options[2] && options[2].id && options[2].id === res
          )}
          text={options && options[2] ? options[2].text : ""}
          isWaiting={!answerId}
          wasCorrect={options && options[2] && options[2].id == answerId}
          selected={options && options[2] && options[2].id == selectedId}
          borderColor={backgroundColor}
          onClick={() =>
            onOptionClick(options && options[2] ? options[2].id : null)
          }
        />
        <AnswerButton
          disable={help50Result.some(
            res =>
              options && options[3] && options[3].id && options[3].id === res
          )}
          text={options && options[3] ? options[3].text : ""}
          isWaiting={!answerId}
          wasCorrect={options && options[3] && options[3].id == answerId}
          selected={options && options[3] && options[3].id == selectedId}
          borderColor={backgroundColor}
          onClick={() =>
            onOptionClick(options && options[3] ? options[3].id : null)
          }
        />
      </div>
    </div>
  </div>
);

const QuestionContainer = ({ question }) => (
  <div className={styles.questionContainer}>{question}</div>
);

const AnswerButton = ({
  isWaiting,
  selected,
  wasCorrect,
  text,
  onClick,
  borderColor = "#04C78B",
  disable
}) => {
  let style = {};
  if (disable) {
    style = {
      color: "white",
      borderColor: "white",
      backgroundColor: "white"
    };
  } else if (isWaiting && !selected) {
    style = {
      color: borderColor,
      borderColor
    };
  } else if (isWaiting && selected) {
    style = {
      backgroundColor: "#5F6833",
      color: "white",
      borderColor: "#F9E354"
    };
  } else if (selected && !wasCorrect) {
    style = {
      backgroundColor: "#723739",
      color: "white",
      borderColor: "#DD0400"
    };
  } else if (selected && wasCorrect) {
    style = {
      backgroundColor: "#00775B",
      color: "white",
      borderColor: "#00C789"
    };
  } else {
    style = {
      color: borderColor,
      borderColor
    };
  }
  return (
    <div
      className={styles.answerButton}
      style={style}
      onClick={() => {
        if (!disable) {
          onClick();
        }
      }}
    >
      {text}
    </div>
  );
};

const QuestionNumber = ({ number, color = "#8d8107" }) => (
  <div className={styles.questionNumber} style={{ color }}>
    {`سوال ${number}`}
  </div>
);

const Timer = ({
  timer,
  isNearEndOfTheTime,
  totalTime,
  isLoadingQuestion,
  timerColor,
  timerRing
}) => {
  timerColor = isNearEndOfTheTime ? "#DF0703" : timerColor;
  return (
    <div className={styles.timerContainer}>
      <img src={timerRing} className={styles.timerImg} alt="" />
      <Circle
        percent={(timer / totalTime) * 100}
        strokeWidth="15"
        trailWidth="0"
        strokeLinecap="butt"
        strokeColor={timerColor}
        style={{ width: "14vh", position: "absolute" }}
      />
      <div className={styles.timerNumber} style={{ color: timerColor }}>
        {isLoadingQuestion ? <LoadingMotion /> : timer}
      </div>
    </div>
  );
};

const TimeLine = ({ Qnumber, haveTimelineCheckPoints }) => (
  <div className={styles.timeLineContainer}>
    {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((_, index) => (
      <TimeLineIcon
        key={index}
        on={Qnumber > index + 1}
        parcham={(index + 1) % 5 === 0 && index && haveTimelineCheckPoints}
      />
    ))}
  </div>
);

const TimeLineIcon = ({ on, parcham }) => (
  <div className={styles.timeLineIconContainer}>
    <img
      src={on ? timelineOnIcon : timelineOffIcon}
      className={styles.timeLineIcon}
    />
    {parcham ? (
      <img
        src={parchamIcon}
        className={styles.timeLineIcon}
        style={{ position: "absolute", top: "-3vw", left: "0px", zIndex: 1 }}
      />
    ) : null}
  </div>
);

const CoinContainer = ({ icon, iconStyle, coin, outerStyle, innerStyle }) => (
  <div className={styles.coinContainer} style={outerStyle}>
    <div className={styles.coinText} style={innerStyle}>
      {coin}
    </div>
    <img
      src={icon || coinIcon}
      className={styles.coinIcon}
      alt=""
      style={iconStyle}
    />
  </div>
);

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(Game);
