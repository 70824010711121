import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class Protected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      allow: false,
      redirectUrl: "/login",
    };
  }

  componentDidMount() {
    // Add your custom validation
    const { token, data } = this.props;
    if (!data || !token) {
      this.setState({
        initialized: true,
        allow: false,
      });
    } else if (!data.acceptRules) {
      const redirectUrl =
        window.location.pathname === "/laws" ? "/login" : "/laws";
      this.setState({
        initialized: true,
        allow: false,
        redirectUrl,
      });
    } else {
      this.setState({
        initialized: true,
        allow: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.token && !this.props.token) {
      this.setState({
        initialized: true,
        allow: false,
      });
    }
  }

  render() {
    const { initialized, allow, redirectUrl } = this.state;
    // eslint-disable-next-line
    const { children } = this.props;
    if (!initialized) {
      return null;
    }
    if (allow) {
      return children;
    }
    return <Redirect to={redirectUrl} />;
  }
}

const select = (store) => {
  return {
    token: store.auth.token,
    data: store.auth.data,
  };
};

export default connect(select)(Protected);
