import env from "../env";
const { baseUrl } = env;

function getAvatar() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/avatar/list`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: getAvatar -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: getAvatar -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: getAvatar -> error", error);
      return Promise.reject(error);
    }
  };
}

function buyAvatar({ id } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/avatar/buy`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ id })
      });
      console.log("TCL: buyAvatar -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: buyAvatar -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: buyAvatar -> error", error);
      return Promise.reject(error);
    }
  };
}

export { getAvatar, buyAvatar };
