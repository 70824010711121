import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./SelectPlan.module.css";
import {
  Page,
  PlanButton,
  TabSelector,
  StartGameModal
} from "../../components";
import SwipeableViews from "react-swipeable-views";
import logoIcon from "../../resources/img/logo_icon.png";
import dollarImage from "../../resources/img/img_dollar.png";
import euroImage from "../../resources/img/img_euro.png";
import dollarIcon from "../../resources/img/img_dollar_icon.png";
import euroIcon from "../../resources/img/img_euro_icon.png";
import newEsteghamatIcon from "../../resources/img/img_esteghamat_icon.png";
import peopleImage from "../../resources/img/img_people.png";
import coinIcon from "../../resources/img/coin_main.png";
import gemIcon from "../../resources/img/gem.svg";
import { getSessionsList } from "../../actions";
import { parseAction } from "../../utils";

class SelectPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: "",
      showStartGameModal: false,
      startGameModalContent: null,
      tabIndex: 0,
      banner: null,
      normal: [],
      special: [],
      error: "",
      footerBar: null
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { type }
      }
    } = this.props;

    if (type === "1") {
      this.setState({ tabIndex: 1 });
    } else {
      this.setState({ tabIndex: 0 });
    }

    try {
      this.setState({ isLoading: true });
      const { banner, normal, special } = await this.props.dispatch(
        getSessionsList()
      );
      this.setState({ isLoading: false, banner, normal, special });
    } catch (error) {
      console.log("TCL: SelectPlan -> componentDidMount -> error", error);
      // this.setState({ isLoading: false, redirect: '/' });
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
    const { data } = this.props;
    if (
      data.adsModel &&
      data.adsModel.ads &&
      data.adsModel.ads.quest &&
      data.adsModel.ads.quest.nameAds
    ) {
      const { actionAds, iconAds, nameAds, target } = data.adsModel.ads.quest;
      this.setState({
        footerBar: { icon: iconAds, text: nameAds, action: actionAds }
      });
    }
  }

  renderFooter = () => {
    const { footerBar } = this.state;
    if (footerBar) {
      return (
        <div
          className={styles.footer}
          onClick={() =>
            this.setState({ redirect: parseAction(footerBar.action) })
          }
        >
          <img src={footerBar.icon} className={styles.logoIcon} alt="" />
          {footerBar.text}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      isLoading,
      redirect,
      tabIndex,
      normal,
      special,
      showStartGameModal,
      startGameModalContent,
      banner,
      error
    } = this.state;
    const tabs = ["لیگ‌های عادی", "لیگ‌های ویژه"];

    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    }

    return (
      <Page
        hasHeader
        showDiamonds
        showPicTickets
        footer={this.renderFooter()}
        isLoading={isLoading}
        style={{
          flexDirection: "column",
          justifyContent: "flex-start",
          alugnItems: "center",
          backgroundColor: "white",
          overflow: "auto"
        }}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        <TabSelector
          tabs={tabs}
          selectedIndex={tabIndex}
          onClick={tabIndex => this.setState({ tabIndex })}
        />
        <SwipeableViews
          enableMouseEvents
          style={{ width: "100%", height: "100%" }}
          onChangeIndex={tabIndex => this.setState({ tabIndex })}
          index={tabIndex}
        >
          <FirstRow
            list={normal}
            onClick={({
              prizes,
              game,
              name: title,
              bgUrl: icon,
              bgColor: color,
              desc
            }) => {
              const startGameModalContent = {
                title,
                icon,
                color,
                prizes,
                desc,
                redirectTo: `/game/${game}`
              };
              this.setState({
                showStartGameModal: true,
                startGameModalContent
              });
            }}
          />
          <SecondRow
            list={
              typeof special === "object"
                ? special.filter(
                    ({ game }) =>
                      String(game).includes("EUR") ||
                      game === "M" ||
                      String(game).includes("USD")
                  )
                : []
            }
            onClick={({ prizes, game, name: title, desc }) => {
              const icon = String(game).includes("EUR")
                  ? euroIcon
                  : game == "M"
                  ? newEsteghamatIcon
                  : dollarIcon,
                color = String(game).includes("EUR")
                  ? "#003F67"
                  : game == "M"
                  ? "#A139EE"
                  : "#404638",
                leaderBoardUrl = String(game).includes("EUR")
                  ? "/rank/EUR"
                  : game == "M"
                  ? "/rank/M"
                  : "/rank/USD";

              const startGameModalContent = {
                title,
                icon,
                color,
                prizes,
                desc,
                redirectTo: `/game/${game}`,
                leaderBoardUrl
              };
              this.setState({
                showStartGameModal: true,
                startGameModalContent
              });
            }}
          />
        </SwipeableViews>

        {banner && tabIndex === 0 ? (
          <div className={styles.bannerContainer}>
            <img
              src={banner.url}
              className={styles.bannerImg}
              onClick={() => {
                const redirect = parseAction(banner && banner.action);
                if (redirect) {
                  this.setState({ redirect });
                }
              }}
            />
          </div>
        ) : null}
        <StartGameModal
          visible={showStartGameModal}
          closeModal={() => this.setState({ showStartGameModal: false })}
          content={startGameModalContent}
        />
      </Page>
    );
  }
}

const FirstRow = ({ list, onClick }) => {
  return (
    <div className={styles.row}>
      {list.map(
        (
          { price, prize, prizes, name, game, desc, count, bgUrl, bgColor },
          index
        ) => {
          return (
            <PlanButton
              key={index}
              title={name}
              backColor={bgColor}
              icon={bgUrl}
              prize={prize}
              price={price}
              havenTickets={count}
              onClick={() =>
                onClick({ prizes, game, name, bgUrl, bgColor, desc })
              }
            />
          );
        }
      )}
    </div>
  );
};

const SecondRow = ({ list, onClick }) => {
  if (!list.length) {
    return <div className={styles.noData}>{"فعلا لیگی نداریم"}</div>;
  }

  return (
    <div className={styles.row}>
      {list.map(
        (
          {
            price,
            prize,
            prizes,
            name,
            game,
            desc,
            cat,
            sec,
            max,
            diamond,
            current
          },
          index
        ) => {
          const icon = String(game).includes("EUR")
              ? euroIcon
              : game == "M"
              ? newEsteghamatIcon
              : dollarIcon,
            priceIcon = game == "M" ? coinIcon : gemIcon,
            backColor = String(game).includes("EUR")
              ? "#003F67"
              : game == "M"
              ? "#A139EE"
              : "#404638",
            backImage = String(game).includes("EUR")
              ? euroImage
              : game == "M"
              ? peopleImage
              : dollarImage,
            prizeCurrency = String(game).includes("EUR")
              ? " یورو"
              : game == "M"
              ? " تومان"
              : " دلار";

          return (
            <PlanButton
              key={index}
              isSpecialPlan
              title={name}
              cat={cat}
              sec={sec}
              current={current}
              max={max}
              backColor={backColor}
              icon={icon}
              priceIcon={priceIcon}
              backImage={backImage}
              imageStyle={
                game == "M"
                  ? {
                      width: "auto",
                      position: "absolute",
                      bottom: "0px",
                      right: "34%"
                    }
                  : null
              }
              bottomColor={game == "M" ? "#C093E6" : null}
              prize={prize + prizeCurrency}
              price={diamond || price}
              onClick={() => onClick({ prizes, game, name, desc })}
            />
          );
        }
      )}
    </div>
  );
};

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(SelectPlan);
