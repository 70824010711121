import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import styles from "./Notifications.module.css";
import { Page, MessageRow } from "../../components";
import { getInbox } from "../../actions";

class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: "",
      list: [],
      error: ""
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const list = await this.props.dispatch(getInbox());
      this.setState({ isLoading: false, list });
    } catch (error) {
      console.log("TCL: Notifications -> componentDidMount -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  }

  render() {
    const { isLoading, redirect, list, error } = this.state;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        title="اطلاعیه‌ها"
        hasHeader
        isLoading={isLoading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        {list.map((data, index) => (
          <MessageRow {...data} key={index} />
        ))}
      </Page>
    );
  }
}

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(Notifications);
