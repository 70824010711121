import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./CoinHistory.module.css";
import { Page } from "../../components";
import moneyIcon from "../../resources/img/mod_money.png";
import dateIcon from "../../resources/img/date_icon.png";
import timeIcon from "../../resources/img/time_icon.png";
import { coinHistory, updateData } from "../../actions";

class CoinHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: "",
      list: [],
      error: ""
    };
  }

  componentDidMount() {
    this.coinHistory();
  }

  coinHistory = async () => {
    try {
      this.setState({ isLoading: true });
      const { coin, list } = await this.props.dispatch(coinHistory());
      this.props.dispatch(updateData({ coin }));
      this.setState({ isLoading: false, list });
    } catch (error) {
      console.log("TCL: CoinHistory -> coinHistory -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  render() {
    const { redirect, isLoading, list, error } = this.state;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        showDiamonds
        showPicTickets
        hasHeader
        isLoading={isLoading}
        style={!list.length ? { justifyContent: "center" } : null}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        {list.length ? (
          list.map((data, index) => <Transaction {...data} key={index} />)
        ) : isLoading ? null : (
          <div className={styles.noTransactionText}>
            {"هنوز تراکنشی ثبت نشده است"}
          </div>
        )}
      </Page>
    );
  }
}

// date: "1398٫7٫27"
// id: 21175311
// neg: false
// sum: 28532
// time: "18:39:42"
// type: "تبدیل پول به سکه"
// value: 11578
const Transaction = ({ date, time, id, neg, sum, type, value }) => (
  <div className={styles.transactionContainer}>
    <div className={styles.top}>
      <div
        className={styles.value}
        style={{ backgroundColor: neg ? "#DF0109" : "#0B6F5A" }}
      >
        {value}
      </div>
      <div className={styles.type}>{type}</div>
    </div>
    <div
      className={styles.bottom}
      style={{ backgroundColor: neg ? "#D74635" : "#27BB65" }}
    >
      <div className={styles.tag}>
        <div className={styles.value}>{sum}</div>
        <img src={moneyIcon} className={styles.icon} />
      </div>
      <div className={styles.tag}>
        <div className={styles.value}>{time}</div>
        <img src={timeIcon} className={styles.icon} />
      </div>
      <div className={styles.tag}>
        <div className={styles.value}>{date}</div>
        <img src={dateIcon} className={styles.icon} />
      </div>
    </div>
  </div>
);

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(CoinHistory);
