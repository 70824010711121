import env from "../env";
const { baseUrl } = env;

function getCash() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/wallet/getCash`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: getCash -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: getCash -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: getCash -> error", error);
      return Promise.reject(error);
    }
  };
}

function getCashHistory() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/wallet/history`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: getCashHistory -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: getCashHistory -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: getCashHistory -> error", error);
      return Promise.reject(error);
    }
  };
}

function requestCoin() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/wallet/requestCoin`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: requestCoin -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: requestCoin -> responseJson", responseJson);
        return Promise.resolve(responseJson);
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: requestCoin -> error", error);
      return Promise.reject(error);
    }
  };
}

function requestPay() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken, card } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/wallet/requestPayV2`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ card })
      });
      console.log("TCL: requestPay -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: requestPay -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: requestPay -> error", error);
      return Promise.reject(error);
    }
  };
}

function coinHistory() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/coinHistory`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: coinHistory -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: coinHistory -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: coinHistory -> error", error);
      return Promise.reject(error);
    }
  };
}

export { getCash, getCashHistory, requestCoin, requestPay, coinHistory };
