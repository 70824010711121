import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./StartGameModal.module.css";
import { ModalForm } from "./index";
import startLeaderboardIcon from "../resources/img/leaderboard_icon.png";
import giftIcon from "../resources/img/gift_flat.png";
import { Link } from "react-router-dom";

class StartGameModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { visible, content } = this.props;
    const {
      title,
      icon,
      color,
      prizes,
      desc,
      redirectTo = "",
      leaderBoardUrl,
      dailyCount
    } = content || {};

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          <img src={icon} className={styles.startIcon} alt="" />
          {dailyCount ? (
            <div className={styles.startCompetitorsText}>{dailyCount}</div>
          ) : null}
          <div
            className={styles.startTitle}
            style={color ? { backgroundColor: color } : null}
          >
            {title}
          </div>
          {prizes ? (
            <>
              <div className={styles.prizesTitle}>{"جوایز:"}</div>
              <div className={styles.prizesContainer}>
                {prizes.map((prize, index) => (
                  <PrizeBox prize={prize} key={index} />
                ))}
              </div>
            </>
          ) : null}
          <div className={styles.startText}>{desc}</div>
          <div className={styles.startButtonsContainer}>
            {leaderBoardUrl ? (
              <Link
                to={leaderBoardUrl}
                style={color ? { backgroundColor: color } : null}
                className={styles.startButtonLeaderboard}
              >
                <img
                  src={startLeaderboardIcon}
                  className={styles.startLeaderboardIcon}
                  alt=""
                />
              </Link>
            ) : null}
            <Link to={redirectTo} className={styles.startButtonText}>
              {"شروع"}
            </Link>
          </div>
        </div>
      </ModalForm>
    );
  }
}

const PrizeBox = ({ prize }) => (
  <div className={styles.prizeBox}>
    <img src={giftIcon} className={styles.giftIcon} />
    {prize}
  </div>
);

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(StartGameModal);
