import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { getCash, requestCoin, requestPay } from "../../actions";
import { NotifModal, Page, RawNotifModal } from "../../components";
import coinWalletIcon from "../../resources/img/coin_wallet.png";
import exchangeIcon from "../../resources/img/exchange.png";
import historyIcon from "../../resources/img/history.png";
import moneyWalletIcon from "../../resources/img/mony_wallet.png";
import styles from "./Wallet.module.css";

class Wallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: "",
      card: "",
      cash: 0,
      percent: 10,
      minPay: 20000,
      error: "",
      showRawNotifModal: false,
      infoModalData: null,
      showNotifModal: false,
      notifModalData: null
    };
  }

  async componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", this.onBackPressed);
    const { card } = this.props.data;
    this.setState({ card, loading: true });
    try {
      const { cash, percent, minPay } = await this.props.dispatch(getCash());
      this.setState({ loading: false, cash, percent, minPay });
    } catch (error) {
      console.log("TCL: Wallet -> componentDidMount -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackPressed);
  }

  onBackPressed = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ redirect: "/dashboard" });
  };

  requestCoin = async () => {
    try {
      this.setState({ loading: true });
      const success = await this.props.dispatch(requestCoin());
      if (success) {
        this.setState({ loading: false }, () => {
          this.setState({
            showRawNotifModal: true,
            infoModalData: {
              text: "سکه شما با موفقیت افزایش یافت",
              type: "success"
            }
          });
        });
      } else {
        this.setState({ loading: false }, () => {
          this.setState({
            error: "متاسفانه موجودی شما کافی نمی‌باشد!"
          });
        });
      }
    } catch (error) {
      console.log("TCL: Wallet -> requestCoin -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  };

  onRequestPayClick = () => {
    const { cash, minPay, percent, showNotifModal } = this.state;
    const intCash = parseInt(cash);
    const coins = intCash + Math.round((intCash * percent) / 100);

    if (intCash >= parseInt(minPay)) {
      if (showNotifModal) {
        this.setState({
          showNotifModal: false
        });
      }
      setTimeout(() => {
        this.setState({
          showNotifModal: true,
          notifModalData: {
            title: "تبدیل به سکه؟",
            text:
              "با تبدیل به سکه، " +
              coins.addComma() +
              "سکه به سکه‌های شما اضافه خواهد شد",
            button1Text: "تبدیل به سکه",
            onClick: this.requestCoin,
            button2Text: "درخواست واریز پول",
            button2OnClick: this.requestPay
          }
        });
      }, 200);
    } else {
      this.setState({
        error:
          `حداقل مبلغ قابل برداشت ${minPay.addComma()} تومن میباشد` +
          "\n\n" +
          "میتوانید موجودی خود را به سکه تبدیل کنید"
      });
    }
  };

  requestPay = async () => {
    try {
      this.setState({ loading: true });
      const { success, message } = await this.props.dispatch(requestPay());
      this.setState({ loading: false }, () => {
        if (success) {
          this.setState({
            showRawNotifModal: true,
            infoModalData: {
              text: message,
              type: "success"
            }
          });
        } else {
          this.setState({
            error: message
          });
        }
      });
    } catch (error) {
      console.log("TCL: Wallet -> requestPay -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  };

  render() {
    const {
      loading,
      redirect,
      card,
      cash,
      percent,
      error,
      showRawNotifModal,
      infoModalData,
      showNotifModal,
      notifModalData
    } = this.state;

    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    }

    const intCash = parseInt(cash);
    const coins = intCash + Math.round((intCash * percent) / 100);

    return (
      <Page
        hasHeader
        showDiamonds
        showPicTickets
        hideHeaderWhileLoading
        onBackPressed={this.onBackPressed}
        style={{ justifyContent: "center" }}
        isLoading={loading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
        goBackOnCloseError={false}
      >
        <div className={styles.container}>
          <Withdraw amount={cash} />
          <div className={styles.fieldsContainer}>
            <InputField
              icon={exchangeIcon}
              value={card}
              onChange={card => this.setState({ card })}
            />
            <div className={styles.rowButtons}>
              <ButtonField
                icon={coinWalletIcon}
                title="تبدیل به سکه"
                style={{ width: "49%" }}
                onClick={this.requestCoin}
              />
              <ButtonField
                icon={moneyWalletIcon}
                title="واریز به حساب"
                style={{ width: "49%" }}
                onClick={this.onRequestPayClick}
              />
            </div>
            <ButtonField
              icon={historyIcon}
              title="تاریخچه واریز"
              onClick={() => this.setState({ redirect: "/withdrawHistory" })}
            />
            <div className={styles.withdrawTipText}>
              {coins
                ? "با تبدیل موجودی به سکه، " +
                  coins.addComma() +
                  "سکه به سکه‌های شما اضافه خواهد شد"
                : `با تبدیل موجودی به سکه ${percent} درصد سکه بیشتری دریافت خواهید کرد!`}
            </div>
          </div>
        </div>
        <NotifModal
          visible={showNotifModal}
          closeModal={() => {
            this.setState({ showNotifModal: false });
          }}
          title={notifModalData && notifModalData.title}
          text={notifModalData && notifModalData.text}
          button1Text={notifModalData && notifModalData.button1Text}
          button2Text={notifModalData && notifModalData.button2Text}
          button2OnClick={notifModalData && notifModalData.button2OnClick}
          onClick={notifModalData && notifModalData.onClick}
        />
        <RawNotifModal
          visible={showRawNotifModal}
          closeModal={() => this.setState({ showRawNotifModal: false })}
          text={infoModalData && infoModalData.text}
          type={infoModalData && infoModalData.type}
        />
      </Page>
    );
  }
}

const Withdraw = ({ amount = "0" }) => (
  <div className={styles.wallet}>
    {amount.addComma()}{" "}
    <span className={styles.withdrawText}>{"تومان قابل برداشت"}</span>
  </div>
);

const InputField = ({ icon, onChange, value }) => (
  <div className={styles.fieldContainer}>
    <img src={icon} className={styles.fieldImg} alt="" />
    <input
      type="number"
      className={styles.fieldInput}
      onChange={({ target: { value } }) => onChange(value)}
      value={value}
    />
  </div>
);

const ButtonField = ({ icon, title, onClick, style }) => (
  <div className={styles.fieldContainer} onClick={onClick} style={style}>
    <img src={icon} className={styles.fieldImg} alt="" />
    <div className={styles.fieldTitle}>{title}</div>
  </div>
);

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(Wallet);
