import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./DailyResModal.module.css";
import { ModalForm } from "./index";
import leaderboardIcon from "../resources/img/laederboder.png";
import stopwatchIcon from "../resources/img/stopwatch_red.png";
import trueIcon from "../resources/img/ic_true.png";
import falseIcon from "../resources/img/ic_false.png";
import { Button } from "./Button";

class DailyResModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { visible, currentGame, lastGame, goToLeaderBoard } = this.props;
    // {all: 15, correct: 0, wrong: 15}

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          <div className={styles.topConatainer}>
            <img src={stopwatchIcon} className={styles.resultImage} alt="" />
            <div className={styles.resTitle} style={{ color: "red" }}>
              {"نتایج این دوره"}
            </div>
            <CountRes isCorrect={false} count={currentGame && currentGame.wrong || 0} />
            <CountRes isCorrect={true} count={currentGame && currentGame.correct || 0} />
            <Button
              text={"مشاهده نتایج"}
              icon={leaderboardIcon}
              onClick={goToLeaderBoard}
              style={{ marginTop: "3vw", fontSize: "20px" }}
            />
          </div>
          <div className={styles.lastGameResTitle}>
            {"امتیاز شما در دوره قبل"}
          </div>
          <div className={styles.lastGameRes}>
            <CountRes isCorrect={true} count={lastGame && lastGame.correct || 0} />
            <CountRes isCorrect={false} count={lastGame && lastGame.wrong || 0} />
          </div>
        </div>
      </ModalForm>
    );
  }
}

const CountRes = ({ isCorrect, count }) => (
  <div
    className={styles.countResContainer}
    style={{ backgroundColor: isCorrect ? "#48D590" : "#D55A4A" }}
  >
    <div className={styles.countResNumber}>{count}</div>
    <div className={styles.countTitleContainer}>
      <img
        className={styles.countResIcon}
        src={isCorrect ? trueIcon : falseIcon}
      />
      {isCorrect ? "صحیح" : "غلط"}
    </div>
  </div>
);

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(DailyResModal);
