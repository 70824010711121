import React from "react";
import Div100vh from "react-div-100vh";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import CheckCode from "./screens/CheckCode";
import CoinHistory from "./screens/CoinHistory";
import Dashboard from "./screens/Dashboard";
import FAQ from "./screens/FAQ";
import FreeCoin from "./screens/FreeCoin";
import Game from "./screens/Game";
import GamePic from "./screens/GamePic";
import Laws from "./screens/Laws";
import Login from "./screens/Login";
import Messages from "./screens/Messages";
import Notifications from "./screens/Notifications";
import Profile from "./screens/Profile";
import QuestionsHistory from "./screens/QuestionsHistory";
import Ranking from "./screens/Ranking";
import RankingType from "./screens/Ranking/RankingType";
import Register from "./screens/Register";
import RegisterQuestion from "./screens/RegisterQuestion";
import SelectPlan from "./screens/SelectPlan";
import Shop from "./screens/Shop";
import Splash from "./screens/Splash";
import Wallet from "./screens/Wallet";
import Wheel from "./screens/Wheel";
import WithdrawHistory from "./screens/WithdrawHistory";

function App() {
  return (
    <Router>
      <Div100vh>
        <Route exact path="/" component={Splash} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/checkCode" component={CheckCode} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/selectPlan/:type" component={SelectPlan} />
        <Route path="/game/:type" component={Game} />
        <Route path="/wallet" component={Wallet} />
        <Route path="/withdrawHistory" component={WithdrawHistory} />
        <Route path="/coinHistory" component={CoinHistory} />
        <Route path="/profile" component={Profile} />
        <Route path="/shop" component={Shop} />
        <Route path="/rank/:type" component={RankingType} />
        <Route path="/ranking" component={Ranking} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/messages" component={Messages} />
        <Route path="/laws" component={Laws} />
        <Route path="/wheel" component={Wheel} />
        <Route path="/faq" component={FAQ} />
        <Route path="/freeCoin" component={FreeCoin} />
        <Route path="/gamePic" component={GamePic} />
        <Route path="/registerQuestion" component={RegisterQuestion} />
        <Route path="/questionsHistory" component={QuestionsHistory} />
        {/* <Redirect to="/" /> */}
      </Div100vh>
    </Router>
  );
}

export default App;
