import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./ProfileModal.module.css";
import { ModalForm } from "./index";
import xpIcon from "../resources/img/xp_icon.png";
import tickIcon from "../resources/img/magetik.png";
import wrongIcon from "../resources/img/magex.png";
import medalIcon from "../resources/img/timeline_on.png";
import { getProfile, updateData } from "../actions";
import { LoadingMotion } from "./LoadingMotion";

class ProfileModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isLoading: false,
      stats: null
    };
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
      this.getProfile();
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  getProfile = async () => {
    try {
      this.setState({ isLoading: true });
      const {
        avatar,
        cash,
        coin,
        xp,
        name,
        stats,
        store
      } = await this.props.dispatch(getProfile());
      this.props.dispatch(updateData({ avatar, cash, coin, xp, name }));
      this.setState({
        isLoading: false,
        stats: stats && stats.all ? stats.all : null
      });
      // stats: {
      //   A: {all: 0, true: 0, false: 0},
      //   B: {all: 0, true: 0, false: 0},
      //   D: {all: 0, true: 0, false: 0},
      //   EUR: {all: 0, true: 0, false: 0},
      //   G: {all: 0, true: 0, false: 0},
      //   GBP: {all: 0, true: 0, false: 0},
      //   M: {all: 0, true: 0, false: 0},
      //   P: {all: 0, true: 0, false: 0},
      //   S: {all: 0, true: 0, false: 0},
      //   T: {all: 0, true: 0, false: 0},
      //   USD: {all: 0, true: 0, false: 0},
      //   all: {all: 0, true: 0, false: 0}
      // }
      // store: {
      //   h50: {B: 0, S: 0, G: 0, D: 0, P: 0},
      //   help: {B: 0, S: 0, G: 0, D: 0, P: 0},
      //   ticket: {B: 0, S: 0, G: 0, D: 0, P: 0},
      //   time: {B: 0, S: 0, G: 0, D: 0, P: 0},
      //   try: {B: 0, S: 0, G: 0, D: 0, P: 0}
      // }
    } catch (error) {
      console.log("TCL: ProfileModal -> getProfile -> error", error);
      this.setState({ isLoading: false });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { isLoading, stats } = this.state;
    const { visible, data } = this.props;
    const { name, avatar, xp } = data;

    const score = parseInt(xp / 50) + 1;
    const trueStats = stats ? stats.true : "-";
    const falseStats = stats ? stats.false : "-";

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          <div className={styles.avatarConatainer}>
            <img src={avatar} className={styles.avatar} alt="" />
          </div>
          <div className={styles.name}>{`نام کاربری: ${name}`}</div>
          <div className={styles.row}>
            <Field
              leftIcon={medalIcon}
              title="سطح"
              value={xp}
              isLoading={isLoading}
            />
            <Field
              rightIcon={tickIcon}
              title="پاسخ صحیح"
              value={trueStats}
              isLoading={isLoading}
            />
          </div>
          <div className={styles.row}>
            <Field
              leftIcon={xpIcon}
              title="امتیاز"
              value={score}
              isLoading={isLoading}
            />
            <Field
              rightIcon={wrongIcon}
              title="پاسخ غلط"
              value={falseStats}
              isLoading={isLoading}
            />
          </div>
        </div>
      </ModalForm>
    );
  }
}

const Field = ({ title, value, leftIcon, rightIcon, isLoading }) => (
  <div className={styles.field}>
    <img
      src={leftIcon || rightIcon}
      className={styles.icon}
      style={leftIcon ? { left: "0.5vh" } : { right: "0.5vh" }}
    />
    <div className={styles.value}>
      {isLoading ? <LoadingMotion width={10} height={10} /> : value}
    </div>
    <div className={styles.title}>{title}</div>
  </div>
);

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(ProfileModal);
