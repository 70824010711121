import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./GamePic.module.css";
import {
  Page,
  LoadingMotion,
  NotifModal,
  GpicResModal
} from "../../components";
import {
  gamePicList,
  gamePicGet,
  gamePicValidate,
  gamePicHelp
} from "../../actions";
import { goBack } from "connected-react-router";
import starOnIcon from "../../resources/img/goldMedal.png";
import starOffIcon from "../../resources/img/silverMedal.png";
import speakerOFFIcon from "../../resources/img/mute_voice.png";
import speakerONIcon from "../../resources/img/play_voice.png";
import gpicIcon from "../../resources/img/game_ticket.png";
import timerIcon from "../../resources/img/time_icon.png";
import helpIcon from "../../resources/img/help_gozine.png";
import infoIcon from "../../resources/img/info_i.png";
import { shuffleArray, parseAction } from "../../utils";

class GamePic extends React.Component {
  constructor(props) {
    super(props);
    this.initialKeyboard = [
      "ا",
      "ب",
      "پ",
      "ت",
      "ث",
      "ج",
      "چ",
      "ح",
      "خ",
      "د",
      "ذ",
      "ر",
      "ز",
      "ژ",
      "س",
      "ش",
      "ص",
      "ض",
      "ط",
      "ظ",
      "ع",
      "غ",
      "ف",
      "ق",
      "ک",
      "گ",
      "ل",
      "م",
      "ن",
      "و",
      "ه",
      "ی"
    ];

    this.initialGameStates = {
      keyboard: [...this.initialKeyboard],
      rawAnswer: "",
      answer: "",
      sec: 30,
      timer: 30,
      image: "",
      currentStage: 1,
      selectedAns: [],
      currentAnsIndex: 0,
      helpPrice: 40,
      showResultModal: false,
      winStars: 0,
      showGame: false
    };

    this.state = {
      isLoading: false,
      showNotifModal: false,
      notifModalContent: null,
      redirect: "",
      loadingGamePicId: null,
      life: 0,
      list: [],
      star: 0,
      isMusicActive: true,
      error: "",
      ...this.initialGameStates
    };
  }

  componentDidMount() {
    this.handleBackButton();
    this.gamePicList();
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackPressed);
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.closeModalTimeout) {
      clearTimeout(this.closeModalTimeout);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showGame && !this.state.showGame) {
      this.gamePicList();
    }
  }

  onBackPressed = event => {
    event.preventDefault();

    if (this.state.error || !this.state.answer) {
      this.props.dispatch(goBack());
      return;
    }

    if (!this.state.showGame) {
      this.props.dispatch(goBack());
    } else {
      this.setState(
        {
          showNotifModal: true,
          notifModalContent: {
            title: "خروج",
            text:
              "آیا برای خروج مطمعن هستید؟ در صورت خروج بلیط روزانه شما برگشت داده نخواهد شد.",
            btn: "بله",
            onClick: this.endGame,
            dismissModal: () => {
              window.history.pushState(null, null, window.location.href);
            }
          }
        },
        () => {
          this.closeModalTimeout = setTimeout(() => {
            if (
              this.state.showNotifModal &&
              this.state.notifModalContent.title === "خروج"
            ) {
              this.setState(
                { showNotifModal: false, notifModalContent: null },
                () => {
                  window.history.pushState(null, null, window.location.href);
                }
              );
            }
          }, 4000);
        }
      );
    }
  };

  handleBackButton = () => {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", this.onBackPressed);
  };

  endGame = () => {
    this.setState({ ...this.initialGameStates, showNotifModal: false });
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  };

  gamePicList = async () => {
    try {
      this.setState({ isLoading: true });
      const {
        currentVideo,
        life,
        list,
        maxVideo,
        star,
        videoTime
      } = await this.props.dispatch(gamePicList());
      this.setState({ life, list, star, isLoading: false });
    } catch (error) {
      console.log("TCL: GamePic -> gamePicList -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  gamePicValidate = async () => {
    const { timer, currentStage } = this.state;
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    try {
      this.setState({ isLoading: true });
      const { star } = await this.props.dispatch(
        gamePicValidate({ stage: currentStage, time: timer })
      );
      this.setState({
        isLoading: false,
        showResultModal: true,
        winStars: star
      });
    } catch (error) {
      console.log("TCL: GamePic -> gamePicValidate -> error", error);
      this.setState({
        isLoading: false,
        showResultModal: true,
        winStars: 0,
        error
      });
    }
  };

  gamePicGet = async stage => {
    const { keyboard } = this.state;
    try {
      this.setState({ loadingGamePicId: stage });
      const result = await this.props.dispatch(gamePicGet({ stage }));
      const {
        answer: rawAnswer,
        level,
        messsage,
        sec,
        success,
        type,
        url,
        word
      } = result;
      if (success) {
        const answer = rawAnswer.replace(/[\u200B-\u200D\uFEFF]/g, ""); //remove half spaces
        let shuffleKeyboard = [...keyboard];
        shuffleKeyboard = shuffleArray(shuffleKeyboard).slice(
          0,
          15 - answer.length
        );
        shuffleKeyboard = shuffleArray([
          ...answer.split(""),
          ...shuffleKeyboard
        ]);
        shuffleKeyboard = shuffleKeyboard.reduce(
          (acc, letter) => [...acc, { letter, show: true }],
          []
        );
        this.setState({
          loadingGamePicId: null,
          rawAnswer,
          answer,
          sec,
          timer: sec,
          image: url,
          showGame: true,
          currentStage: stage,
          helpPrice: word,
          keyboard: shuffleKeyboard,
          selectedAns: new Array(answer.length)
        });
      } else {
        const {
          action,
          bgColor,
          bgColor2,
          btn,
          btnColor,
          btnTextColor,
          foreColor,
          text
        } = result;
        this.setState({
          loadingGamePicId: null,
          showNotifModal: true,
          notifModalContent: { text, action: parseAction(action), btn }
        });
      }
    } catch (error) {
      console.log("TCL: GamePic -> gamePicGet -> error", error);
      this.setState({ loadingGamePicId: null }, () => {
        this.setState({ error });
      });
    }
  };

  gamePicHelp = async () => {
    try {
      this.setState({ isLoading: true });
      const ttt = await this.props.dispatch(gamePicHelp());
      this.setState({ isLoading: false });
    } catch (error) {
      console.log("TCL: GamePic -> gamePicValidate -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  onImageLoad = () => {
    console.log("loaded");
    this.timerInterval = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState({ timer: this.state.timer - 1 });
      } else {
        clearInterval(this.timerInterval);
        if (!this.state.isLoading && !this.state.winStars) {
          this.setState({ showResultModal: true, winStars: 0 });
        }
      }
    }, 1000);
  };

  onImageLoadError = () => {
    console.log("not loaded");
  };

  renderHeader = () => {
    const { life, star, isMusicActive } = this.state;

    return (
      <div className={styles.header}>
        <div className={styles.infoTag}>
          <div className={styles.infoNumber} style={{ color: "brown" }}>
            {life}
          </div>
          <img src={gpicIcon} className={styles.gpicIcon} />
        </div>
        <div className={styles.infoTag}>
          <div className={styles.infoNumber} style={{ color: "var(--green)" }}>
            {star}
          </div>
          <img src={starOnIcon} className={styles.starIcon} />
        </div>
        <div
          className={styles.infoTag}
          style={{ width: "fit-content", cursor: "pointer", padding: "1.5vw" }}
          onClick={() => this.setState({ isMusicActive: !isMusicActive })}
        >
          <img
            src={isMusicActive ? speakerONIcon : speakerOFFIcon}
            className={styles.speakerIcon}
          />
        </div>
      </div>
    );
  };

  renderList = () => {
    const { loadingGamePicId, list } = this.state;

    return (
      <>
        {this.renderHeader()}
        <div className={styles.listContainer}>
          {list.map(({ stage, star }, index) => (
            <GameStage
              stage={stage}
              star={star}
              key={index}
              isLoading={loadingGamePicId === stage}
              onClick={this.gamePicGet}
            />
          ))}
        </div>
      </>
    );
  };

  renderGame = () => {
    const {
      answer,
      timer,
      image,
      selectedAns,
      helpPrice,
      keyboard,
      currentAnsIndex
    } = this.state;
    const blurValue =
      5 + timer * 0.5 > 20
        ? 5 + timer * 0.3
        : 5 + timer * 0.3 > 10
        ? 5 + timer * 0.5
        : 10;

    return (
      <>
        <div className={styles.gameTopSection}>
          <div className={styles.innerContainer}>
            <div className={styles.timer}>
              {timer}
              <img src={timerIcon} className={styles.timerIcon} />
            </div>
            <img
              src={image}
              style={{ filter: `blur(${blurValue}px)` }}
              className={styles.gameImage}
              onLoad={this.onImageLoad}
              onError={this.onImageLoadError}
            />
            <div className={styles.selectedAnsContainer}>
              {answer.split("").map((_, index) => (
                <LetterBox
                  letter={selectedAns[index] || {}}
                  onClick={() => {
                    if (selectedAns[index] && selectedAns[index].letter) {
                      const refIndex = selectedAns[index].refIndex;
                      const newKeyBoard = [...keyboard];
                      newKeyBoard[refIndex].show = true;
                      const newSelectedAns = [...selectedAns];
                      newSelectedAns[index] = null;
                      const newCurrentAnsIndex = index;
                      this.setState({
                        keyboard: [...newKeyBoard],
                        selectedAns: newSelectedAns,
                        currentAnsIndex: newCurrentAnsIndex
                      });
                    }
                  }}
                  key={index + Math.random()}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={styles.gameKeyboardSection}>
          {keyboard.slice(0, 15).map(({ letter, show }, index) => (
            <LetterBox
              letter={{ letter, show }}
              key={index + Math.random()}
              onClick={() => {
                if (show) {
                  const newKeyBoard = [...keyboard];
                  newKeyBoard[index].show = false;
                  const newSelectedAns = [...selectedAns];
                  newSelectedAns[currentAnsIndex] = { letter, refIndex: index };
                  if (newSelectedAns.every(ans => ans && ans.letter)) {
                    if (
                      newSelectedAns.reduce(
                        (acc, { letter }) => acc + letter,
                        ""
                      ) === answer
                    ) {
                      this.gamePicValidate();
                    } else {
                      newSelectedAns.forEach(({ refIndex }, index) => {
                        newKeyBoard[refIndex].show = true;
                        newSelectedAns[index] = null;
                      });
                    }
                  }
                  let newCurrentAnsIndex = newSelectedAns.reduce(
                    (acc, ans, index) =>
                      acc !== -1 ? acc : !ans ? index : acc,
                    -1
                  );
                  if (newCurrentAnsIndex !== -1) {
                    this.setState({
                      keyboard: [...newKeyBoard],
                      selectedAns: newSelectedAns,
                      currentAnsIndex: newCurrentAnsIndex
                    });
                  }
                }
              }}
            />
          ))}
        </div>
        <div className={styles.gameHelpSection} onClick={this.gamePicHelp}>
          <img src={helpIcon} className={styles.helpIcon} />
          {"نمایش یک حرف"}
          <div className={styles.helpPrice}>{helpPrice}</div>
        </div>
      </>
    );
  };

  render() {
    const {
      isLoading,
      redirect,
      showGame,
      currentStage,
      showNotifModal,
      notifModalContent,
      showResultModal,
      winStars,
      rawAnswer,
      image,
      error
    } = this.state;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }
    return (
      <Page
        title={showGame ? `مرحله ${currentStage}` : "حدس تصویر"}
        hasHeader
        pageBackgroundColor={!showGame ? "#42495C" : null}
        isLoading={isLoading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        {showGame ? this.renderGame() : this.renderList()}
        <NotifModal
          visible={showNotifModal}
          closeModal={() => {
            this.setState({ showNotifModal: false });
            if (this.closeModalTimeout) {
              clearTimeout(this.closeModalTimeout);
            }
          }}
          dismissModal={notifModalContent && notifModalContent.dismissModal}
          title={(notifModalContent && notifModalContent.title) || "ببخشید..."}
          icon={infoIcon}
          text={notifModalContent && notifModalContent.text}
          button1Text={notifModalContent && notifModalContent.btn}
          button2Text={"بیخیال"}
          to={notifModalContent && notifModalContent.action}
          onClick={notifModalContent && notifModalContent.onClick}
        />
        <GpicResModal
          visible={showResultModal}
          closeModal={() => {
            this.setState({ ...this.initialGameStates });
            if (this.timerInterval) {
              clearInterval(this.timerInterval);
            }
          }}
          answer={rawAnswer}
          image={image}
          winStars={winStars}
        />
      </Page>
    );
  }
}

const GameStage = ({ stage, star, onClick, isLoading }) => (
  <div className={styles.gameStageContainer} onClick={() => onClick(stage)}>
    {isLoading ? (
      <LoadingMotion width={20} height={20} />
    ) : (
      <div className={styles.gameStageText}>{stage}</div>
    )}
    <div className={styles.gameStageIcons}>
      <img
        src={star >= 1 ? starOnIcon : starOffIcon}
        className={styles.gameStageIcon}
      />
      <img
        src={star >= 2 ? starOnIcon : starOffIcon}
        className={styles.gameStageIcon}
      />
      <img
        src={star === 3 ? starOnIcon : starOffIcon}
        className={styles.gameStageIcon}
      />
    </div>
  </div>
);

const LetterBox = ({ letter, onClick }) => (
  <div className={styles.letterBox} onClick={onClick}>
    {letter.show != undefined
      ? (letter.show && letter.letter) || ""
      : letter.letter || ""}
  </div>
);

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(GamePic);
