import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./WithdrawHistory.module.css";
import { Page } from "../../components";
import { getCashHistory } from "../../actions";

class WithdrawHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: "",
      list: [],
      error: ""
    };
  }

  componentDidMount() {
    this.getCashHistory();
  }

  getCashHistory = async () => {
    try {
      this.setState({ isLoading: true });
      const list = await this.props.dispatch(getCashHistory());
      this.setState({ isLoading: false, list });
    } catch (error) {
      console.log("TCL: WithdrawHistory -> getCashHistory -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  render() {
    const { redirect, isLoading, list, error } = this.state;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        title="تاریخچه واریزی"
        hasHeader
        hideCoins
        isLoading={isLoading}
        style={!list.length ? { justifyContent: "center" } : null}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        {list.length ? (
          list.map((data, index) => <Transaction {...data} key={index} />)
        ) : isLoading ? null : (
          <div className={styles.noTransactionText}>
            {"هنوز تراکنشی ثبت نشده است"}
          </div>
        )}
      </Page>
    );
  }
}

const Transaction = ({ date, desc, status, trackingCode, val }) => (
  <div className={styles.transactionContainer}>
    <div className={styles.top}>
      <div
        className={styles.price}
        style={{ backgroundColor: status === 1 ? "#8080E6" : "#E3E901" }}
      >
        {`${val.addComma()} تومان`}
      </div>
      <div className={styles.date}>{date}</div>
    </div>
    <div
      className={styles.bottom}
      style={{ backgroundColor: status === 1 ? "#359F67" : "#ECED15" }}
    >
      {status === 1 ? `کد رهگیری: ${trackingCode}` : desc}
    </div>
  </div>
);

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(WithdrawHistory);
