import env from "../env";
import { updateData } from "./auth";
const { baseUrl, version } = env;

function getSocial({ instagram, telegram } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/social`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({
          name: instagram ? "instagram" : telegram ? "telegram" : ""
        })
      });
      console.log("TCL: getSocial -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: getSocial -> responseJson", responseJson);
        if (responseJson) {
          if (responseJson.success) {
            dispatch(updateData({ coin: responseJson.coin }));
          }
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: getSocial -> error", error);
      return Promise.reject(error);
    }
  };
}

export { getSocial };
