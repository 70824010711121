import env from "../env";
const { baseUrl } = env;

function gamePicList() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/game/pic/listV2`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: gamePicList -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: gamePicList -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: gamePicList -> error", error);
      return Promise.reject(error);
    }
  };
}

function gamePicGet({ stage } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/game/pic/getV3`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ stage })
      });
      console.log("TCL: gamePicGet -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: gamePicGet -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: gamePicGet -> error", error);
      return Promise.reject(error);
    }
  };
}

function gamePicValidate({ stage, time } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/game/pic/validateV2`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ stage, time })
      });
      console.log("TCL: gamePicValidate -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: gamePicValidate -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: gamePicValidate -> error", error);
      return Promise.reject(error);
    }
  };
}

function gamePicHelp() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/game/pic/help/word`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: gamePicHelp -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: gamePicHelp -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: gamePicHelp -> error", error);
      return Promise.reject(error);
    }
  };
}

export { gamePicList, gamePicGet, gamePicValidate, gamePicHelp };
