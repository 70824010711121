import env from "../env";
const { baseUrl } = env;

function getQuestion({ session } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/question/getV2`, {
        method: 'POST',
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ session })
      });
      console.log("TCL: getQuestion -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: getQuestion -> responseJson", responseJson);
        if (responseJson && responseJson.success) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: getQuestion -> error", error);
      return Promise.reject(error);
    }
  };
}

function checkQuestion({ id, optionID, session } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/question/checkV6`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ id, optionID, session })
      });
      console.log("TCL: checkQuestion -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: checkQuestion -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: checkQuestion -> error", error);
      return Promise.reject(error);
    }
  };
}

function reportQuestion({ session } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/question/report`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ session })
      });
      console.log("TCL: reportQuestion -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: reportQuestion -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: reportQuestion -> error", error);
      return Promise.reject(error);
    }
  };
}

function currencyTryInfoQuestion() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/question/currencyTryInfo`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: currencyTryInfoQuestion -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log(
          "TCL: currencyTryInfoQuestion -> responseJson",
          responseJson
        );
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: currencyTryInfoQuestion -> error", error);
      return Promise.reject(error);
    }
  };
}

function helpQuestion({ help, session } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/question/help`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({ help, session })
      });
      console.log("TCL: helpQuestion -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: helpQuestion -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: helpQuestion -> error", error);
      return Promise.reject(error);
    }
  };
}

export {
  getQuestion,
  checkQuestion,
  reportQuestion,
  currencyTryInfoQuestion,
  helpQuestion
};
