import React from "react";
import styles from "./PlanButton.module.css";
import { CoinPrice } from "./index";

export const PlanButton = ({
  icon,
  priceIcon,
  backImage,
  imageStyle,
  backColor,
  bottomColor,
  title,
  titleColor = "white",
  price,
  prize,
  havenTickets,
  onClick,
  comingSoon,
  isSpecialPlan,
  cat,
  sec,
  current,
  max
}) =>
  isSpecialPlan ? (
    <div className={styles.specialContainer} onClick={onClick}>
      <div className={styles.topSection} style={{ backgroundColor: backColor }}>
        <Price price={price.addComma()} icon={priceIcon} />
        <div className={styles.title} style={{ color: titleColor }}>
          {title}
        </div>
        <img src={icon} className={styles.icon} alt="" />
      </div>
      <div
        className={styles.bottomSection}
        style={bottomColor ? { backgroundColor: bottomColor } : null}
      >
        {backImage ? (
          <img
            src={backImage}
            className={styles.backImage}
            style={imageStyle}
            alt=""
          />
        ) : null}
        <div className={styles.details}>
          <div className={styles.col} style={{ paddingRight: "17%" }}>
            <div className={styles.key}>{"موضوع:"}</div>
            <div className={styles.value}>{cat}</div>
            <div className={styles.key}>{"زمان باقی‌مانده:"}</div>
            <div className={styles.value}>{sec.secToFa()}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.key}>{"جایزه:"}</div>
            <div className={styles.value}>{prize.addComma()}</div>
            <div className={styles.key}>{"تعداد شرکت‌کننده:"}</div>
            <div className={styles.value}>{`${current.addComma()} ${
              max ? "از " + max.addComma() + " " : ""
            }نفر`}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.topSection} style={{ backgroundColor: backColor }}>
        <div className={styles.title} style={{ color: titleColor }}>
          {title}
        </div>
        <img src={icon} className={styles.icon} alt="" />
      </div>
      {comingSoon ? (
        <div className={styles.bottomSection}>
          <div className={styles.prize}>{"به زودی ..."}</div>
        </div>
      ) : (
        <div className={styles.bottomSection}>
          <div className={styles.prize} style={{ color: "black" }}>
            {"جایزه"}
          </div>
          <div className={styles.prize}>{prize.addComma() + " تومان"}</div>
          {havenTickets && havenTickets > 0 ? (
            <div
              className={styles.havenTickets}
            >{`موجودی: ${havenTickets}`}</div>
          ) : (
            <CoinPrice price={price.addComma()} />
          )}
        </div>
      )}
    </div>
  );

const Price = ({ icon, price }) => (
  <div className={styles.priceCom}>
    <img src={icon} className={styles.icon} alt="" />
    <div className={styles.price}>{price}</div>
  </div>
);
