import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./StartTrainModal.module.css";
import { ModalForm } from "./index";
import coinGiftIcon from "../resources/img/coin_gift_object.png";
import { Link } from "react-router-dom";

class StartTrainModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { visible, onBuyClick } = this.props;
    const text1 =
      "تا حالا شده به این فکر کنی که با تمرین کردنت بتونی سکه رایگان بدست بیاری؟" +
      "\n" +
      "پس تمرینت رو سکه دار کن";
    const text2 =
      "با فعال سازی تمرین سکه دار با هر جواب درست، سکه رایگان بگیر!";

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          padding: "2vw",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          <img src={coinGiftIcon} className={styles.startIcon} alt="" />
          <div className={styles.startText}>{text1}</div>
          <div className={styles.startButtonsContainer}>
            <div
              onClick={onBuyClick}
              style={{ backgroundColor: "red" }}
              className={styles.startButtonText}
            >
              {"تمرین سکه دار"}
            </div>
            <Link to={"/game/T"} className={styles.startButtonText}>
              {"تمرین عادی"}
            </Link>
          </div>
          <div className={styles.startText}>{text2}</div>
        </div>
      </ModalForm>
    );
  }
}

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(StartTrainModal);
