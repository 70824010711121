import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./BuyModal.module.css";
import { ModalForm } from "./index";
import { Button } from "./Button";
import { requestPayment, checkGiftCode } from "../actions";

class BuyModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      showCouponInput: false,
      codeTakhfif: "",
      codeTakhfifValue: 0,
      isLoading: false,
      error: ""
    };
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  requestPayment = async id => {
    const { codeTakhfif } = this.state;
    try {
      this.setState({ isLoading: true });
      const { success, url } = await this.props.dispatch(
        requestPayment({ shop: id, codeTakhfif })
      );
      if (success && url) {
        if (!this.props.isWaitingForPayRes) {
          this.props.dispatch({ type: "TOGGLE_WAIT_FOR_PAY_RES" });
          window.location.assign(url);
        }
      } else {
        this.setState({ isLoading: false }, () => {
          this.setState({ error: true });
        });
      }
    } catch (error) {
      console.log("TCL: Shop -> requestPayment -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error: true });
      });
    }
  };

  checkGiftCode = async code => {
    try {
      this.setState({ isLoading: true });
      const { value, message } = await this.props.dispatch(
        checkGiftCode({ code })
      );
      if (!value && message) {
        this.setState({ isLoading: false }, () => {
          this.setState({ error: message });
        });
      } else if (value) {
        this.setState({ codeTakhfifValue: value, isLoading: false });
      }
    } catch (error) {
      console.log("TCL: Shop -> requestPayment -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error: true });
      });
    }
  };

  render() {
    const {
      showCouponInput,
      codeTakhfif,
      codeTakhfifValue,
      isLoading,
      error
    } = this.state;

    const {
      visible,
      canHaveCouponCode = true,
      topText,
      priceText,
      buttonText,
      shopId
    } = this.props;

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          <div
            className={styles.startText}
            style={priceText && !error ? { paddingBottom: "0vw" } : null}
          >
            {error || topText}
          </div>
          {priceText && !error ? (
            <div className={styles.priceText}>{priceText}</div>
          ) : null}
          {showCouponInput && !isLoading ? (
            <input
              placeholder={"کد تخفیف"}
              onChange={({ target: { value: codeTakhfif } }) => {
                if (codeTakhfif.length > 4) {
                  return;
                } else if (codeTakhfif.length === 4) {
                  this.checkGiftCode(codeTakhfif);
                } else if (codeTakhfif.length < 4 && error) {
                  this.setState({ error: "" });
                }
                this.setState({ codeTakhfif });
              }}
              value={codeTakhfif}
              className={styles.input}
            />
          ) : null}
          <Button
            onClick={() => this.requestPayment(shopId)}
            text={buttonText}
            loading={isLoading}
          />
          {canHaveCouponCode ? (
            <div className={styles.discountContainer}>
              {"آیا کد تخفیف دارید؟"}
              <input
                type="checkbox"
                onChange={() =>
                  this.setState({ showCouponInput: !showCouponInput })
                }
                value={showCouponInput}
                style={{ marginLeft: "4vw" }}
              />
            </div>
          ) : null}
        </div>
      </ModalForm>
    );
  }
}

const select = store => {
  return {
    data: store.auth.data,
    isWaitingForPayRes: store.auth.isWaitingForPayRes
  };
};

export default connect(select)(BuyModal);
