const initialState = {
  phoneNumber: "",
  token: "",
  deviceToken: "",
  data: {},
  rulesAccepted: false,
  isSpeakerActive: true,
  isMicActive: true,
  isWaitingForPayRes: false
};

function auth(state = initialState, action) {
  switch (action.type) {
    case "ADD_PHONE_NUMBER": {
      const { phoneNumber } = action.payload;
      return {
        ...state,
        phoneNumber
      };
    }

    case "ADD_DEVICE_TOKEN": {
      const { deviceToken } = action.payload;
      return {
        ...state,
        deviceToken
      };
    }

    case "TOGGLE_SPEAKER": {
      return {
        ...state,
        isSpeakerActive: !state.isSpeakerActive
      };
    }

    case "TOGGLE_MIC": {
      return {
        ...state,
        isMicActive: !state.isMicActive
      };
    }

    case "TOGGLE_WAIT_FOR_PAY_RES": {
      return {
        ...state,
        isWaitingForPayRes: !state.isWaitingForPayRes
      };
    }

    case "LOGGED_IN": {
      const { token, data } = action.payload;

      return {
        ...state,
        token,
        data
      };
    }

    case "DATA_LOADED": {
      const { data } = action.payload;

      return {
        ...state,
        data: { ...data }
      };
    }

    case "UPDATE_DATA": {
      const { data } = action.payload;

      return {
        ...state,
        data: { ...state.data, ...data }
      };
    }

    case "LOGGED_OUT":
      return { ...initialState, phoneNumber: state.phoneNumber };

    default:
      return state;
  }
}

export default auth;
