import React, { Component } from "react";
import styles from "./LoginBox.module.css";
import { Button } from "./index";

export class LoginBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstInputValue: "",
      secondInputValue: ""
    };
  }

  render() {
    const {
      title,
      firstPlaceholder,
      secondPlaceholder,
      tipText,
      onTipTextClick = () => {},
      buttonText,
      loading,
      onSubmit,
      type
    } = this.props;
    const { firstInputValue, secondInputValue } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <form
          className={styles.body}
          onSubmit={event => {
            event.preventDefault();
            onSubmit(firstInputValue, secondInputValue);
          }}
        >
          <input
            placeholder={firstPlaceholder}
            onChange={({ target: { value: firstInputValue } }) =>
              this.setState({ firstInputValue })
            }
            value={firstInputValue}
            className={styles.input}
            type={type || "text"}
            autoFocus
          />
          {secondPlaceholder && (
            <input
              placeholder={secondPlaceholder}
              onChange={({ target: { value: secondInputValue } }) =>
                this.setState({ secondInputValue })
              }
              value={secondInputValue}
              className={styles.input}
            />
          )}
          {tipText && (
            <div className={styles.tipText} onClick={onTipTextClick}>
              {tipText}
            </div>
          )}
          <Button loading={loading} text={buttonText} submit />
        </form>
      </div>
    );
  }
}
