import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./Shop.module.css";
import { Page, ShopButton, BuyModal, RawNotifModal } from "../../components";
import coinIcon from "../../resources/img/coin_main.png";
import gemIcon from "../../resources/img/gem.svg";
import gpicIcon from "../../resources/img/game_ticket.png";
// import noghreIcon from "../../resources/img/noghre_icon.png";
// import talaeiIcon from "../../resources/img/talaei_icon.png";
// import stepIcon from "../../resources/img/step.png";
// import boronzIcon from "../../resources/img/boronz_icon.png";
// import almasIcon from "../../resources/img/almas_icon.png";
// import ticketIcon from "../../resources/img/ticket_object.png";
// import textHelpIcon from "../../resources/img/rahnama_button.png";
// import twoOptionHelpIcon from "../../resources/img/dogozinei_button.png";
// import extraTimeHelpIcon from "../../resources/img/zaman_button.png";
// import secondChanceHelpIcon from "../../resources/img/pashimani_object.png";
import { listShop, buyShop, updateData } from "../../actions";
import { checkForPaymentResult, getVoice } from "../../utils";

class Shop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: "",
      codeTakhfif: "",
      banner: null,
      coin: [],
      diaCoin: [],
      diamond: [],
      gpic: [],
      isBuyingId: null,
      showBuyModal: false,
      error: "",
      showNotifModal: false,
      notifModal: null,
      soundMic: ""
    };
  }

  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", this.onBackPressed);
    this.listShop();
    setTimeout(() => {
      this.checkForPaymentResult();
    }, 1500);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackPressed);
  }

  onBackPressed = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ redirect: "/dashboard" });
  };

  checkForPaymentResult = () => {
    const res = checkForPaymentResult(window.location.search);
    console.log("TCL: Shop -> checkForPaymentResult -> res", res);
    // {status: true, coin: 30232, gpic: 21, diamond: 62}
    if (res && res.status !== undefined && this.props.isWaitingForPayRes) {
      this.props.dispatch({ type: "TOGGLE_WAIT_FOR_PAY_RES" });
      const { status, coin, gpic, diamond } = res;
      if (status === true) {
        this.props.dispatch(updateData({ coin, diamond, tGpic: gpic }));
        this.setState({
          showNotifModal: true,
          notifModal: { desc: "پرداخت با موفقیت انجام شد", type: "success" }
        });
      } else {
        this.setState({
          showNotifModal: true,
          notifModal: { desc: "پرداخت انجام نشد", type: "error" }
        });
      }
    }
  };

  listShop = async () => {
    try {
      this.setState({ loading: true });
      const {
        banner,
        coin,
        diaCoin,
        diamond,
        gpic,
        myCoin,
        myDiamond,
        myGPic
      } = await this.props.dispatch(listShop());
      this.props.dispatch(
        updateData({ coin: myCoin, diamond: myDiamond, tGpic: myGPic })
      );
      this.setState({
        loading: false,
        banner,
        coin,
        diaCoin,
        diamond,
        gpic,
        soundMic: getVoice.store.buy_coin
      });
      // banner:
      // bannerUrl: "http://hooshebarandeh.com/data/banner/coin_banner_siz_big.png"
      // bz: 0
      // fullPrice: 79000
      // id: 23
      // name: "بسته 10000 عددی سکه"
      // price: 47920
      // quantity: 10000
      // type: "coin"
    } catch (error) {
      console.log("TCL: Shop -> listShop -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  };

  buyShop = async ({ id }) => {
    try {
      this.setState({ isBuyingId: id });
      const { coin, diamond, gpic, item, success } = await this.props.dispatch(
        buyShop({ id })
      );
      // item: {id: 78, name: null, price: 1400, quantity: 2, count: 0}
      if (success) {
        this.props.dispatch(updateData({ coin, diamond, tGpic: gpic }));
        this.setState({ isBuyingId: null });
      } else {
        this.setState({ isBuyingId: null }, () => {
          this.setState({ error: true });
        });
      }
    } catch (error) {
      console.log("TCL: Shop -> buyShop -> error", error);
      this.setState({ isBuyingId: null }, () => {
        this.setState({ error });
      });
    }
  };

  handlePayment = (
    {
      id,
      price,
      fullPrice,
      quantity,
      name = "",
      showPrice = false,
      canHaveCouponCode = false
    },
    paymentType
  ) => {
    const type =
      paymentType === "coin"
        ? "سکه"
        : paymentType === "diamond"
        ? "الماس"
        : "بلیت حدس تصویر";
    const shopModalData = {
      text: `با خرید ${name || type} به میلیونر شدن نزدیک میشی!`,
      buttonText: `خرید ${quantity} ${type}`,
      id,
      priceText: showPrice ? `قیمت: ${price.addComma()} تومان` : "",
      canHaveCouponCode: canHaveCouponCode || !fullPrice
    };
    this.setState({ showBuyModal: true, shopModalData });
  };

  render() {
    const {
      loading,
      redirect,
      coin,
      diaCoin,
      diamond,
      gpic,
      isBuyingId,
      showBuyModal,
      shopModalData,
      banner,
      error,
      showNotifModal,
      notifModal,
      soundMic
    } = this.state;

    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    }

    return (
      <Page
        hasHeader
        onBackPressed={this.onBackPressed}
        showDiamonds
        showPicTickets
        isLoading={loading}
        hideHeaderWhileLoading
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
        soundMic={soundMic}
      >
        {banner && banner.bannerUrl ? (
          <img
            src={banner.bannerUrl}
            className={styles.bannerImg}
            onClick={() => {
              const { quantity, price, id, fullPrice, type, name } = banner;
              const buyData = {
                quantity,
                price,
                id,
                fullPrice,
                name,
                canHaveCouponCode: true,
                showPrice: true
              };
              this.handlePayment(buyData, type);
            }}
          />
        ) : null}
        <BuyCoin
          key={"buyCoin"}
          list={coin}
          title={"خرید سکه"}
          icon={coinIcon}
          onClick={buyData => this.handlePayment(buyData, "coin")}
          isBuyingId={isBuyingId}
        />
        <BuyCoin
          key={"buyDiamond"}
          list={diamond}
          title={"خرید الماس"}
          icon={gemIcon}
          onClick={buyData => this.handlePayment(buyData, "diamond")}
          isBuyingId={isBuyingId}
        />
        <BuyCoin
          key={"buyGpic"}
          list={gpic}
          title={"خرید بلیط حدس تصویر"}
          icon={gpicIcon}
          iconStyle={{ width: "8vw", height: "5vw" }}
          onClick={buyData => this.handlePayment(buyData, "gPic")}
          isBuyingId={isBuyingId}
          priceIconStyle={{ height: "auto" }}
        />
        <BuyCoin
          key={"coinToDiamond"}
          list={diaCoin}
          title={"تبدیل سکه به الماس"}
          icon={gemIcon}
          onClick={this.buyShop}
          isBuyingId={isBuyingId}
          isConvert
        />
        <BuyModal
          visible={showBuyModal}
          closeModal={() => this.setState({ showBuyModal: false })}
          topText={shopModalData && shopModalData.text}
          priceText={shopModalData && shopModalData.priceText}
          buttonText={shopModalData && shopModalData.buttonText}
          shopId={shopModalData && shopModalData.id}
          canHaveCouponCode={shopModalData && shopModalData.canHaveCouponCode}
        />
        <RawNotifModal
          visible={showNotifModal}
          closeModal={() => this.setState({ showNotifModal: false })}
          text={notifModal && notifModal.desc}
          type={notifModal && notifModal.type}
        />
      </Page>
    );
  }
}
const SectionHeader = ({
  icon,
  iconStyle = {},
  title,
  coinHeader,
  backgroundColor
}) => (
  <div
    className={styles.sectionHeader}
    style={
      coinHeader
        ? {
            backgroundColor: backgroundColor || "#F9E35A",
            direction: "rtl",
            justifyContent: "center"
          }
        : { backgroundColor }
    }
  >
    <img
      src={icon}
      className={styles.sectionHeaderIcon}
      style={
        coinHeader ? { marginLeft: "10px", ...iconStyle } : { ...iconStyle }
      }
      alt=""
    />
    <div
      className={styles.sectionHeaderTitle}
      style={coinHeader ? { color: "#028B6D" } : null}
    >
      {title}
    </div>
  </div>
);

const BuyCoin = ({
  icon,
  iconStyle,
  priceIconStyle,
  title,
  list,
  onClick,
  isBuyingId,
  isConvert
}) => {
  return (
    <Fragment>
      <SectionHeader
        icon={icon}
        title={title}
        coinHeader
        iconStyle={iconStyle}
        backgroundColor={isConvert ? "white" : "gold"}
      />
      <div className={styles.row}>
        {list.map(({ bz, fullPrice, id, name, price, quantity }, index) => (
          <ShopButton
            key={index}
            icon={icon}
            beforeDiscountPrice={fullPrice}
            price={price}
            coinPrice={quantity}
            onClick={() => onClick({ id, price, fullPrice, quantity })}
            loading={isBuyingId === id}
            isConvert={isConvert}
            iconStyle={priceIconStyle}
          />
        ))}
      </div>
    </Fragment>
  );
};

const select = store => {
  return {
    isWaitingForPayRes: store.auth.isWaitingForPayRes
  };
};

export default connect(select)(Shop);
