import React from "react";
import styles from "./Button.module.css";
import { LoadingMotion } from "./index";

export const Button = ({
  loading,
  loadingWidth = 20,
  text,
  onClick,
  backgroundColor,
  submit,
  textColor,
  icon,
  rightIcon,
  style = {}
}) => {
  if (loading) {
    return (
      <div
        className={styles.container}
        style={{ ...style, backgroundColor: "transparent" }}
      >
        <LoadingMotion width={loadingWidth} height={loadingWidth} />
      </div>
    );
  } else {
    return (
      <button
        type={submit ? "submit" : "button"}
        className={styles.container}
        onClick={onClick}
        style={{ backgroundColor: backgroundColor || "#01C68E", ...style }}
      >
        <div
          className={styles.text}
          style={textColor ? { color: textColor } : null}
        >
          {icon ? (
            <img
              src={icon}
              className={styles.icon}
              style={{ marginRight: "2vw" }}
            />
          ) : null}
          {text}
          {rightIcon ? (
            <img
              src={rightIcon}
              className={styles.icon}
              style={{ marginLeft: "2vw" }}
            />
          ) : null}
        </div>
      </button>
    );
  }
};
