import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./GpicResModal.module.css";
import { ModalForm } from "./index";
import starOnIcon from "../resources/img/timeline_on.png";
import starOffIcon from "../resources/img/timeline_off.png";

class GpicResModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { visible, answer, image, winStars } = this.props;

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          <div className={styles.answer}>{answer}</div>
          <img src={image} className={styles.gameImage} alt="" />
          {winStars ? (
            <>
              <div
                className={styles.resTitle}
                style={{ color: "#00C383" }}
              >
                {"جوابت درسته"}
              </div>
              <div className={styles.gameStageIcons}>
                <img
                  src={winStars >= 1 ? starOnIcon : starOffIcon}
                  className={styles.gameStageIcon}
                />
                <img
                  src={winStars >= 2 ? starOnIcon : starOffIcon}
                  className={styles.gameStageIcon}
                />
                <img
                  src={winStars === 3 ? starOnIcon : starOffIcon}
                  className={styles.gameStageIcon}
                />
              </div>
              <div className={styles.resText} style={{ color: "#00C383" }}>
                {"یک بلیط بدست آوردی"}
              </div>
            </>
          ) : (
            <>
              <div className={styles.resTitle} style={{ color: "red" }}>
                {"وقتت تموم شد"}
              </div>
              <div className={styles.resText} style={{ color: "red" }}>
                {"یک بلیط از دست دادی"}
              </div>
            </>
          )}
          <div className={styles.startButtonsContainer}>
            <div className={styles.startButtonText} onClick={this.closeModal}>
              {"بازگشت"}
            </div>
          </div>
        </div>
      </ModalForm>
    );
  }
}

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(GpicResModal);
