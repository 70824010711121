import * as Sentry from "@sentry/browser";
import env from "../env";

const { baseUrl, version } = env;

function addPhoneNumber(phoneNumber) {
  return {
    type: "ADD_PHONE_NUMBER",
    payload: { phoneNumber }
  };
}

function updateData({
  coin,
  diamond,
  tGpic,
  xp,
  cash,
  tStar,
  avatar,
  name,
  acceptRules,
  isShowNews
}) {
  const data = {
    ...(coin ? { coin } : {}),
    ...(diamond ? { diamond } : {}),
    ...(tGpic ? { tGpic } : {}),
    ...(xp ? { xp } : {}),
    ...(cash ? { cash } : {}),
    ...(tStar ? { tStar } : {}),
    ...(avatar ? { avatar } : {}),
    ...(name ? { name } : {}),
    ...(acceptRules ? { acceptRules } : {}),
    ...(typeof isShowNews === "boolean" ? { news: { isShow: isShowNews } } : {})
  };
  return {
    type: "UPDATE_DATA",
    payload: { data }
  };
}

function checkMobile(mobile) {
  return async (dispatch, getState) => {
    const store = getState();
    const { phoneNumber } = store.auth;
    if (phoneNumber && phoneNumber !== mobile) {
      return Promise.reject("قبلا با شماره دیگری ازین دستگاه وارد شده‌اید");
    }

    try {
      const response = await fetch(`${baseUrl}/login/checkMobile`, {
        method: "POST",
        body: JSON.stringify({ mobile })
      });
      console.log("TCL: checkMobile -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: checkMobile -> responseJson", responseJson);
        if (responseJson.result !== undefined) {
          dispatch(addPhoneNumber(mobile));
          return Promise.resolve(responseJson.result);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: checkMobile -> error", error);
      return Promise.reject(error);
    }
  };
}

function register({ mobile, name, ref = "" }) {
  return async (dispatch, getState) => {
    try {
      const response = await fetch(`${baseUrl}/login/register`, {
        method: "POST",
        body: JSON.stringify({
          mobile,
          name,
          ref
        })
      });
      console.log("TCL: register -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: register -> responseJson", responseJson);
        if (responseJson.result === true) {
          return Promise.resolve();
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: register -> error", error);
      return Promise.reject(error);
    }
  };
}

function checkCode({ mobile, code }) {
  return async (dispatch, getState) => {
    const store = getState();
    const { deviceToken } = store.auth;
    try {
      const response = await fetch(`${baseUrl}/login/checkCodeV6`, {
        method: "POST",
        body: JSON.stringify({
          mobile,
          code,
          version,
          deviceToken
        })
      });
      console.log("TCL: checkCode -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: checkCode -> responseJson", responseJson);
        const { result, token, data } = responseJson;
        if (result === "success") {
          dispatch({ type: "LOGGED_IN", payload: { token, data } });
          return Promise.resolve();
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: checkCode -> error", error);
      return Promise.reject(error);
    }
  };
}

function getData() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;
    try {
      const response = await fetch(`${baseUrl}/login/dataV6`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({
          version,
          deviceToken
        })
      });
      console.log("TCL: getData -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: getData -> responseJson", responseJson);
        dispatch({ type: "DATA_LOADED", payload: { data: responseJson } });
        Sentry.configureScope(scope => {
          scope.setUser({
            name: responseJson.name
          });
        });
        return Promise.resolve(responseJson);
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: getData -> error", error);
      return Promise.reject(error);
    }
  };
}

function editProfile({ name, card }) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;
    try {
      const response = await fetch(`${baseUrl}/login/editV6`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({
          name,
          card,
          shaba: ""
        })
      });
      console.log("TCL: editProfile -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: editProfile -> responseJson", responseJson);
        if (responseJson.data) {
          dispatch({
            type: "DATA_LOADED",
            payload: { data: responseJson.data }
          });
        }
        return Promise.resolve(responseJson);
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: editProfile -> error", error);
      return Promise.reject(error);
    }
  };
}

function loginProperties() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;
    try {
      const response = await fetch(`${baseUrl}/login/properties`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: loginProperties -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: loginProperties -> responseJson", responseJson);
        return Promise.resolve(responseJson);
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: loginProperties -> error", error);
      return Promise.reject(error);
    }
  };
}

function acceptRules() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;
    try {
      const response = await fetch(`${baseUrl}/login/acceptRules`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: acceptRules -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: acceptRules -> responseJson", responseJson);
        return Promise.resolve(responseJson);
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: acceptRules -> error", error);
      return Promise.reject(error);
    }
  };
}

function setRef({ ref } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;
    try {
      const response = await fetch(`${baseUrl}/login/ref`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({
          ref
        })
      });
      console.log("TCL: setRef -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: setRef -> responseJson", responseJson);
        return Promise.resolve(responseJson);
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: setRef -> error", error);
      return Promise.reject(error);
    }
  };
}

function setEmail({ email } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;
    try {
      const response = await fetch(`${baseUrl}/login/email`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({
          email
        })
      });
      console.log("TCL: setEmail -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: setEmail -> responseJson", responseJson);
        return Promise.resolve(responseJson);
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: setEmail -> error", error);
      return Promise.reject(error);
    }
  };
}

export {
  addPhoneNumber,
  updateData,
  checkMobile,
  register,
  checkCode,
  getData,
  editProfile,
  loginProperties,
  acceptRules,
  setRef,
  setEmail
};
