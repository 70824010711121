import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./Messages.module.css";
import { Page } from "../../components";
import sendIcon from "../../resources/img/send.png";
import { supportGet, supportAdd } from "../../actions";
import { getVoice } from "../../utils";

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: "",
      text: "",
      list: [],
      error: "",
      soundMic: ""
    };
  }

  componentDidMount() {
    this.supportGet();
    this.setState({ soundMic: getVoice.general.asked_question });
  }

  scrollToEndOfList = () => {
    setTimeout(() => {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  supportGet = async () => {
    try {
      this.setState({ isLoading: true });
      const list = await this.props.dispatch(supportGet());
      this.setState({ isLoading: false, list }, this.scrollToEndOfList);
    } catch (error) {
      console.log("TCL: Messages -> supportGet -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  supportAdd = async () => {
    const { text, list } = this.state;

    try {
      this.setState({ isLoading: true });
      const { success } = await this.props.dispatch(supportAdd({ text }));
      if (success) {
        this.setState(
          {
            isLoading: false,
            text: "",
            list: [...list, { text, timestamp: "اکنون", answer: false }]
          },
          this.scrollToEndOfList
        );
      } else {
        this.setState({ isLoading: false }, () => {
          this.setState({ error: true });
        });
      }
    } catch (error) {
      console.log("TCL: Messages -> supportAdd -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  render() {
    const { redirect, isLoading, text, list, error, soundMic } = this.state;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        title="پیام‌ها"
        hasHeader
        hideCoins
        isLoading={isLoading}
        style={{
          justifyContent: !list.length ? "center" : "space-between",
          backgroundColor: "white"
        }}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
        soundMic={soundMic}
      >
        <div className={styles.listContainer}>
          {list.length ? (
            <>
              {list.map((data, index) => (
                <Message {...data} key={index} />
              ))}
              <div
                style={{ float: "left", clear: "both" }}
                ref={ref => {
                  this.messagesEnd = ref;
                }}
              ></div>
            </>
          ) : (
            <div className={styles.noTransactionText}>
              {"موردی ثبت نشده است"}
            </div>
          )}
        </div>
        <div className={styles.inputContainer}>
          <input
            className={styles.input}
            placeholder="پیغام خود را اینجا وارد نمایید"
            value={text}
            onChange={({ target: { value: text } }) => this.setState({ text })}
          />
          <img
            className={styles.sendButton}
            src={sendIcon}
            onClick={this.supportAdd}
          />
        </div>
      </Page>
    );
  }
}

const Message = ({ text, answer, timestamp }) => (
  <div
    className={styles.messageContainer}
    style={{ alignItems: answer ? "flex-end" : "flex-start" }}
  >
    <div
      className={styles.textContainer}
      style={{
        backgroundColor: answer ? "var(--green)" : "#F3E6EC",
        color: answer ? "white" : "black"
      }}
    >
      {text}
    </div>
    <div className={styles.dateContainer}>{timestamp}</div>
  </div>
);

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(Messages);
