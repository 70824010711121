import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./GameResModal.module.css";
import { ModalForm } from "./index";
import successIcon from "../resources/img/success.png";
import failIcon from "../resources/img/reject.png";
import playIcon from "../resources/img/play_button.png";
import stopwatchIcon from "../resources/img/stopwatch_red.png";
import { Button } from "./Button";
import { reportQuestion } from "../actions";

class GameResModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      isReporting: false
    };
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
      nextProps.playVoice({
        isWin: nextProps.isWin,
        isTimeout: nextProps.isTimeout,
        isLastGame: nextProps.isLastGame,
      });
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  reportQuestion = async session => {
    const { onReportResult } = this.props;
    try {
      this.setState({ isReporting: true });
      const { success } = await this.props.dispatch(
        reportQuestion({ session })
      );
      this.setState({ isReporting: false });
      if (onReportResult) {
        onReportResult(success);
      }
    } catch (error) {
      console.log("TCL: GameResModal -> reportQuestion -> error", error);
      this.setState({ isReporting: false });
      onReportResult(false);
    }
  };

  render() {
    const { isReporting } = this.state;
    const {
      visible,
      isWin,
      cash,
      xp,
      helpButton,
      session,
      goToOnEnd,
      onEndButtonText,
      isLastGame,
      isTimeout
    } = this.props;

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          <img
            src={isWin ? successIcon : isTimeout ? stopwatchIcon : failIcon}
            className={styles.resultImage}
            alt=""
          />
          {isWin ? (
            <>
              <div className={styles.resTitle} style={{ color: "#00C383" }}>
                {"درست"}
              </div>
              {cash ? (
                <div className={styles.resText} style={{ color: "#00C383" }}>
                  {isLastGame
                    ? `َشما موفق شدید ${cash.addComma()} تومان برنده شوید!`
                    : `${cash.addComma()} تومان برنده شده اید`}
                </div>
              ) : null}
              <div className={styles.startButtonsContainer}>
                {isLastGame ? (
                  <Button text={onEndButtonText} onClick={goToOnEnd} />
                ) : (
                  <Button
                    text={"سوال بعدی"}
                    onClick={this.closeModal}
                    rightIcon={playIcon}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div className={styles.resTitle} style={{ color: "red" }}>
                {isTimeout ? "اتمام زمان" : "اشتباه"}
              </div>
              {helpButton && helpButton.length && !isTimeout
                ? helpButton
                : null}
              <Button
                text={"گزارش خطا در سوال"}
                backgroundColor={"#FDD849"}
                textColor={"red"}
                onClick={() => this.reportQuestion(session)}
                loading={isReporting}
              />
              <div className={styles.startButtonsContainer}>
                {isTimeout ? (
                  <div
                    className={styles.startButtonText}
                    onClick={this.closeModal}
                  >
                    {"بازگشت به صفحه اصلی"}
                  </div>
                ) : (
                  <div className={styles.startButtonText} onClick={goToOnEnd}>
                    {onEndButtonText}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </ModalForm>
    );
  }
}

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(GameResModal);
