import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./RegisterQuestion.module.css";
import { Page } from "../../components";
import { collaborateAddQuestion } from "../../actions";
import questionHistory from "../../resources/img/questionHistory.png";

class RegisterQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: "",
      question: "",
      tip: "",
      option1: "",
      option2: "",
      option3: "",
      option4: "",
      error: ""
    };
  }

  collaborateAddQuestion = async () => {
    const {
      question: title,
      tip: help,
      option1: qO1,
      option2: qO2,
      option3: qO3,
      option4: qO4
    } = this.state;

    try {
      this.setState({ isLoading: true });
      const { success } = await this.props.dispatch(
        collaborateAddQuestion({ title, help, qO1, qO2, qO3, qO4 })
      );
      if (success) {
        this.setState({ isLoading: false });
      } else {
        this.setState({ isLoading: false }, () => {
          this.setState({ error: true });
        });
      }
    } catch (error) {
      console.log(
        "TCL: RegisterQuestion -> collaborateAddQuestion -> error",
        error
      );
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  render() {
    const {
      redirect,
      isLoading,
      question,
      tip,
      option1,
      option2,
      option3,
      option4,
      error
    } = this.state;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        title="ارسال سوال"
        hasHeader
        isLoading={isLoading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        <div className={styles.title}>{"متن سوال"}</div>
        <textarea
          rows="3"
          value={question}
          onChange={({ target: { value } }) =>
            this.setState({ question: value })
          }
          placeholder="صورت سوال را وارد نمایید"
          className={styles.questionInput}
        ></textarea>
        <div className={styles.title}>{"گزینه‌ها"}</div>
        <div className={styles.optionsRow}>
          <Option
            placeholder={"گزینه دوم"}
            value={option2}
            onChange={option2 => this.setState({ option2 })}
          />
          <Option
            placeholder={"گزینه صحیح"}
            value={option1}
            onChange={option1 => this.setState({ option1 })}
            isCorrect
          />
        </div>
        <div className={styles.optionsRow}>
          <Option
            placeholder={"گزینه چهارم"}
            value={option4}
            onChange={option4 => this.setState({ option4 })}
          />
          <Option
            placeholder={"گزینه سوم"}
            value={option3}
            onChange={option3 => this.setState({ option3 })}
          />
        </div>
        <div className={styles.title}>{"راهنما"}</div>
        <textarea
          rows="2"
          value={tip}
          onChange={({ target: { value } }) => this.setState({ tip: value })}
          placeholder="راهنما"
          className={styles.questionInput}
        ></textarea>
        <div
          className={styles.submitButton}
          onClick={this.collaborateAddQuestion}
        >
          {"ثبت سوال"}
        </div>
        <div
          className={styles.questionsListButton}
          onClick={() => this.setState({ redirect: "/questionsHistory" })}
        >
          <img
            src={questionHistory}
            style={{
              width: "5vh",
              height: "5vh",
              position: "absolute",
              left: "2vh"
            }}
          />
          {"لیست سوال‌های ارسالی شما"}
        </div>
      </Page>
    );
  }
}

const Option = ({ placeholder, value, onChange, isCorrect }) => (
  <input
    className={styles.option}
    placeholder={placeholder}
    value={value}
    onChange={({ target: { value } }) => onChange(value)}
    style={{ borderColor: isCorrect ? "#00C488" : "#FD7581" }}
  />
);

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(RegisterQuestion);
