import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./QuestionsHistory.module.css";
import { Page } from "../../components";
import { collaborateGetQuestion, updateData } from "../../actions";
import acceptedQuestionBlack from "../../resources/img/acceptedQuestionBlack.png";
import rejectedQuestionBlack from "../../resources/img/rejectedQuestionBlack.png";
import waitingQuestionBlack from "../../resources/img/waitingQuestionBlack.png";
import acceptedQuestionWhite from "../../resources/img/acceptedQuestionWhite.png";
import rejectedQuestionWhite from "../../resources/img/rejectedQuestionWhite.png";
import waitingQuestionWhite from "../../resources/img/waitingQuestionWhite.png";

class QuestionsHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: "",
      list: [],
      accepteed: 0,
      rejected: 0,
      awaitng: 0,
      error: ""
    };
  }

  componentDidMount() {
    this.collaborateGetQuestion();
  }

  collaborateGetQuestion = async () => {
    try {
      this.setState({ isLoading: true });
      const {
        accepteed,
        rejected,
        awaitng,
        all,
        coin,
        coinAll,
        list
      } = await this.props.dispatch(collaborateGetQuestion());
      // [{title, o1, o2, o3, o4, status, date: "1398٫7٫12", hour: "20:41"}]
      this.props.dispatch(updateData({ coin: coinAll }));
      this.setState({ isLoading: false, list, accepteed, rejected, awaitng });
    } catch (error) {
      console.log(
        "TCL: QuestionsHistory -> collaborateGetQuestion -> error",
        error
      );
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  renderFooter = () => {
    const { accepteed, rejected, awaitng } = this.state;

    return (
      <div className={styles.footer}>
        <InfoTag
          number={rejected}
          icon={rejectedQuestionWhite}
          title="تایید‌نشده"
        />
        <InfoTag
          number={awaitng}
          icon={waitingQuestionWhite}
          title="درحال بررسی"
        />
        <InfoTag
          number={accepteed}
          icon={acceptedQuestionWhite}
          title="تایید‌شده"
        />
      </div>
    );
  };

  render() {
    const { redirect, isLoading, list, error } = this.state;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        title="تاریخچه سوالات"
        hasHeader
        isLoading={isLoading}
        style={
          !list.length
            ? { justifyContent: "center" }
            : { paddingBottom: "20vw" }
        }
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        {list.length ? (
          list.map((data, index) => <Question key={index} {...data} />)
        ) : !isLoading ? (
          <div className={styles.noTransactionText}>
            {"هنوز سوالی ثبت نشده‌است"}
          </div>
        ) : null}
        {this.renderFooter()}
      </Page>
    );
  }
}

const Question = ({ title, o1, o2, o3, o4, status, date, hour }) => (
  <div className={styles.questionContainer}>
    <div
      className={styles.columnFlex}
      style={{ marginRight: "3vw", minWidth: "fit-content" }}
    >
      <div className={styles.text}>{"وضعیت سوال"}</div>
      <img
        src={
          status === 0
            ? waitingQuestionBlack
            : status === 1
            ? acceptedQuestionBlack
            : rejectedQuestionBlack
        }
        style={{ width: "8vh", height: "8vh" }}
      />
      <div
        className={styles.text}
        style={{ fontSize: "0.8em", whiteSpace: "mowrap" }}
      >
        {status === 0
          ? "در دست بررسی..."
          : status === 1
          ? "تایید‌شده"
          : "تایید‌نشده"}
      </div>
    </div>
    <div
      className={styles.columnFlex}
      style={{ width: "65%", alignItems: "flex-end" }}
    >
      <div className={styles.text} style={{ color: "#78D4F5" }}>
        {"متن سوال"}
      </div>
      <div className={styles.text}>{title}</div>
      <div className={styles.rowFlex}>
        <div className={styles.ansContainer}>{o1}</div>
        <div className={styles.text} style={{ color: "#78D4F5" }}>
          {"پاسخ"}
        </div>
      </div>
    </div>
  </div>
);

const InfoTag = ({ number, icon, title }) => (
  <div className={styles.infoTagContainer}>
    <div className={styles.infoTagTitle}>{title}</div>
    <div className={styles.infoTag}>
      <div className={styles.infoNumber}>{number}</div>
      <img src={icon} className={styles.footerIcon} />
    </div>
  </div>
);

export default connect()(QuestionsHistory);
