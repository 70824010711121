import env from "../env";
const { baseUrl } = env;

function getInbox() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/inbox/get`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: getInbox -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: getInbox -> responseJson", responseJson);
        return Promise.resolve(responseJson);
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: getInbox -> error", error);
      return Promise.reject(error);
    }
  };
}

function setInboxView({ id } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/inbox/view/${id}`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: setInboxView -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: setInboxView -> responseJson", responseJson);
        return Promise.resolve(responseJson);
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: setInboxView -> error", error);
      return Promise.reject(error);
    }
  };
}

function setInboxClick({ id } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/inbox/click/${id}`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: setInboxClick -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: setInboxClick -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: setInboxClick -> error", error);
      return Promise.reject(error);
    }
  };
}

export { getInbox, setInboxView, setInboxClick };
