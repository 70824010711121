import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./ErrorModal.module.css";
import { ModalForm } from "./index";
import errorIcon from "../resources/img/errorBlob.png";

class ErrorModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  parseText = text => {
    if (text === true) {
      text = "متاسفانه اشکالی به وجود آمده است.\nلطفا دوباره تلاش نمایید";
    } else if (String(text).includes("TypeError: Failed to fetch")) {
      text = "لطفا اتصال اینترنت خود را بررسی نمایید";
    } else {
      text = String(text);
    }
    return text;
  };

  render() {
    const { visible, text } = this.props;
    const showText = this.parseText(text);

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          <div className={styles.header}>
            {"خطا"}
            <img src={errorIcon} className={styles.startIcon} alt="" />
          </div>
          <div className={styles.startText}>{showText}</div>
          <div className={styles.startButtonsContainer}>
            <div
              className={styles.startButtonText}
              onClick={this.closeModal}
              style={{
                borderColor: "var(--green)",
                backgroundColor: "white",
                color: "black",
                width: "50vw"
              }}
            >
              {"بستن"}
            </div>
          </div>
        </div>
      </ModalForm>
    );
  }
}

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(ErrorModal);
