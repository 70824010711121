import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./Wheel.module.css";
import { Page, NotifModal } from "../../components";
import wheelImage from "../../resources/img/circle_gift_object.png";
import indicatorImage from "../../resources/img/shaskulak_object.png";
import spinIcon from "../../resources/img/becharkhun_object.png";
import prizeIcon from "../../resources/img/ic_prize_code.png";
import { getRoll, doRoll, updateData } from "../../actions";
import { getVoice } from "../../utils";

class Wheel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: "",
      rotateWheel: false,
      canRotate: false,
      error: "",
      soundMic: "",
      price: 0
    };
  }

  async componentDidMount() {
    try {
      this.setState({ soundMic: getVoice.general.lucky_arrow, loading: true });
      const { coin, count, price } = await this.props.dispatch(getRoll());
      this.props.dispatch(updateData({ coin }));
      this.setState({ loading: false, canRotate: !!count, price });
    } catch (error) {
      console.log("TCL: Wheel -> componentDidMount -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  }

  doRoll = async force => {
    const { canRotate, rotateWheel } = this.state;

    if (rotateWheel) {
      return;
    }

    if (!canRotate && !force) {
      this.showPriceModal();
      return;
    }

    try {
      this.setState({
        canRotate: false,
        rotateWheel: true,
        soundMic: getVoice.roll.turning
      });
      const { coin, info, success, text, val } = await this.props.dispatch(
        doRoll()
      );
      // {success: true, text: "تبریک! شما برنده 80 سکه شدید.", info: "coin", val: "80", coin: "30312"}
      // {success: true, text: "تبریک! شما برنده یک بلیط «برنز» شدید.", info: "ticket", val: "B", coin: "30112"}
      if (success) {
        setTimeout(() => {
          this.props.dispatch(updateData({ coin }));
          this.setState({
            soundMic: "",
            rotateWheel: false,
            showNotifModal: true,
            notifModal: {
              title: "هوورا...",
              desc: text,
              button: "یکبار دیگه بچرخون",
              button2Text: "بیخیال",
              icon: prizeIcon,
              onClick: this.showPriceModal
            }
          });
        }, 8000);
      } else {
        this.setState({ rotateWheel: false, soundMic: "" }, () => {
          this.setState({ error: text });
        });
      }
    } catch (error) {
      console.log("TCL: Wheel -> doRoll -> error", error);
      this.setState(
        {
          canRotate: true,
          rotateWheel: false,
          soundMic: ""
        },
        () => {
          this.setState({ error });
        }
      );
    }
  };

  showPriceModal = () => {
    this.setState({ showNotifModal: false }, () => {
      setTimeout(() => {
        this.setState({
          showNotifModal: true,
          notifModal: {
            title: "توجه...",
            desc:
              "شما بلیط روزانه گردونه خودتون رو استفاده کردین!" +
              "\n\n" +
              "اگر میخوای یکبار دیگه گردونه رو بچرخونی، میتونی با دیدن یک ویدیو گردونه رو بچرخونی و جایزه بگیری و یا با کسر سکه یکبار دیگه گردونه رو بچرخونی" +
              "\n" +
              "انتخاب کن",
            button: "مشاهده ویدیو",
            button2Text: `${this.state.price} سکه`,
            icon: prizeIcon,
            button2OnClick: () => this.doRoll(true)
          }
        });
      }, 500);
    });
  };

  render() {
    const {
      loading,
      redirect,
      canRotate,
      rotateWheel,
      error,
      showNotifModal,
      notifModal,
      soundMic
    } = this.state;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        showDiamonds
        showPicTickets
        hasHeader
        hideHeaderWhileLoading
        style={{ justifyContent: "space-around" }}
        isLoading={loading}
        soundMic={soundMic}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        <img src={indicatorImage} className={styles.indicatorImage} alt="" />
        <img
          src={wheelImage}
          className={classnames({
            [styles.wheelImage]: true,
            [styles.wheelMotion]: rotateWheel
          })}
          alt=""
        />
        <div
          className={styles.spinIconContainer}
          onClick={() => this.doRoll(false)}
        >
          {canRotate ? (
            <div className={styles.spinIconCounter}>{"1"}</div>
          ) : null}
          <img src={spinIcon} className={styles.spinIcon} alt="" />
        </div>
        <NotifModal
          visible={showNotifModal}
          closeModal={() => this.setState({ showNotifModal: false })}
          title={notifModal && notifModal.title}
          icon={notifModal && notifModal.icon}
          text={notifModal && notifModal.desc}
          button1Text={notifModal && notifModal.button}
          button2Text={notifModal && notifModal.button2Text}
          button2OnClick={notifModal && notifModal.button2OnClick}
          onClick={notifModal && notifModal.onClick}
        />
      </Page>
    );
  }
}

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(Wheel);
