import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./Profile.module.css";
import profileIcon from "../../resources/img/profile_icon.png";
import avatarIcon from "../../resources/img/img_avatar.png";
import tickIcon from "../../resources/img/magetik.png";
import lockIcon from "../../resources/img/lock.png";
import coinIcon from "../../resources/img/coin_main.png";
import { Page, Button, LoadingMotion, RawNotifModal } from "../../components";
import { getAvatar, buyAvatar, updateData, editProfile } from "../../actions";
import { goBack } from "connected-react-router";
import { getVoice } from "../../utils";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showRawNotifModal: false,
      notifModal: null,
      isEditingProfile: false,
      isBuyingAvatar: false,
      redirect: "",
      name: "",
      card: "",
      showAvatarsPanel: false,
      boy: [],
      girl: [],
      special: [],
      filter: "boy",
      selectedAvatar: null,
      error: ""
    };
  }

  componentDidMount() {
    const { name, card } = this.props.data;
    this.setState({ name, card });
  }

  getAvatars = async () => {
    this.setState({ isLoading: true });
    const onLoginRedirectUrl = {
      "0": "/register",
      "1": "/checkCode"
    };

    try {
      const { boy, girl, special } = await this.props.dispatch(getAvatar());
      this.setState({ isLoading: false, boy, girl, special });
    } catch (error) {
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  onBackPressed = () => {
    const { showAvatarsPanel } = this.state;
    if (showAvatarsPanel) {
      this.setState({ showAvatarsPanel: false });
    } else {
      this.props.dispatch(goBack());
    }
  };

  buyAvatar = async () => {
    const { selectedAvatar } = this.state;
    const { bought, id, level, open, price, url } = selectedAvatar;

    try {
      this.setState({ isBuyingAvatar: true });
      const { success, coin } = await this.props.dispatch(buyAvatar({ id }));
      if (success) {
        this.props.dispatch(updateData({ coin, avatar: url }));
        this.setState({ isBuyingAvatar: false, showAvatarsPanel: false });
      } else {
        this.setState({ isBuyingAvatar: false }, () => {
          this.setState({ error: true });
        });
      }
    } catch (error) {
      console.log("TCL: Profile -> buyAvatar -> error", error);
      this.setState({ isBuyingAvatar: false }, () => {
        this.setState({ error });
      });
    }
  };

  editProfile = async () => {
    const { name, card } = this.state;
    try {
      this.setState({ isEditingProfile: true });
      const { success, data } = await this.props.dispatch(
        editProfile({ name, card })
      );
      if (success) {
        this.setState({
          isEditingProfile: false,
          showRawNotifModal: true,
          notifModal: { desc: "ثبت شد" }
        });
      } else {
        this.setState({ isEditingProfile: false }, () => {
          this.setState({ error: true });
        });
      }
    } catch (error) {
      console.log("TCL: Profile -> editProfile -> error", error);
      this.setState({ isEditingProfile: false }, () => {
        this.setState({ error });
      });
    }
  };

  renderForm = () => {
    const { avatar } = this.props.data;
    const { name, card, isEditingProfile } = this.state;
    return (
      <div className={styles.formBody}>
        <div
          className={styles.profileContainer}
          onClick={() =>
            this.setState(
              {
                showAvatarsPanel: true
              },
              this.getAvatars
            )
          }
        >
          <img src={avatar || avatarIcon} className={styles.profilePic} />
          <div className={styles.selectProfilePic}>{"انتخاب"}</div>
        </div>
        <form
          className={styles.form}
          onSubmit={event => {
            event.preventDefault();
            this.editProfile();
          }}
        >
          <input
            placeholder="نام کاربری"
            onChange={({ target: { value: name } }) => this.setState({ name })}
            value={name}
            className={styles.input}
          />
          <input
            placeholder="شماره کارت"
            onChange={({ target: { value: card } }) => this.setState({ card })}
            value={card}
            className={styles.input}
          />
          <Button loading={isEditingProfile} text="ثبت" submit />
        </form>
      </div>
    );
  };

  renderSelectAvatar = () => {
    const {
      boy,
      girl,
      special,
      filter,
      selectedAvatar,
      isBuyingAvatar
    } = this.state;
    const list = filter == "boy" ? boy : filter == "girl" ? girl : special;
    const selectedFilterStyle = { color: "gold" };

    return (
      <div className={styles.selectAvatarBody}>
        {isBuyingAvatar ? (
          <div
            className={styles.buyButton}
            style={{ justifyContent: "center" }}
          >
            <LoadingMotion />
          </div>
        ) : selectedAvatar ? (
          <div
            className={styles.buyButton}
            style={{
              justifyContent: selectedAvatar.bought ? "center" : "space-between"
            }}
            onClick={this.buyAvatar}
          >
            {!selectedAvatar.bought ? (
              <div className={styles.tag}>
                {selectedAvatar.price || "رایگان"}
              </div>
            ) : null}
            <div
              className={styles.text}
              style={{
                color:
                  selectedAvatar.bought || selectedAvatar.open
                    ? "var(--green)"
                    : "#841818"
              }}
            >
              {selectedAvatar.bought
                ? "انتخاب"
                : !selectedAvatar.open
                ? `در سطح: ${selectedAvatar.level}`
                : "خرید و انتخاب"}
            </div>
          </div>
        ) : null}
        <div className={styles.header}>
          <div className={styles.left}>
            <div
              className={styles.option}
              style={filter == "girl" ? selectedFilterStyle : null}
              onClick={() => this.setState({ filter: "girl" })}
            >
              {"دختر"}
            </div>
            <div
              className={styles.option}
              style={filter == "boy" ? selectedFilterStyle : null}
              onClick={() => this.setState({ filter: "boy" })}
            >
              {"پسر"}
            </div>
            <div
              className={styles.option}
              style={
                filter == "special"
                  ? { ...selectedFilterStyle, border: "none" }
                  : { border: "none" }
              }
              onClick={() => this.setState({ filter: "special" })}
            >
              {"VIP"}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.title}>{"آواتار"}</div>
            <img src={profileIcon} className={styles.profileIcon} />
          </div>
        </div>
        <div className={styles.avatars}>
          {list.map((data, index) => (
            <Avatar
              {...data}
              key={index}
              onClick={() => this.setState({ selectedAvatar: data })}
              isSelected={
                selectedAvatar ? selectedAvatar.id === data.id : data.selected
              }
            />
          ))}
        </div>
      </div>
    );
  };

  render() {
    const {
      isLoading,
      redirect,
      showAvatarsPanel,
      error,
      showRawNotifModal,
      notifModal
    } = this.state;
    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }
    return (
      <Page
        title="تغییر پروفایل"
        hasHeader
        style={{ justifyContent: "center" }}
        onBackPressed={this.onBackPressed}
        isLoading={isLoading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
        soundMic={getVoice.general.avatar_profile}
      >
        {showAvatarsPanel ? this.renderSelectAvatar() : this.renderForm()}
        <RawNotifModal
          visible={showRawNotifModal}
          closeModal={() => this.setState({ showRawNotifModal: false })}
          text={notifModal && notifModal.desc}
        />
      </Page>
    );
  }
}

const Avatar = ({
  bought,
  id,
  level,
  open,
  price,
  selected,
  url,
  onClick,
  isSelected
}) => (
  <div className={styles.avatar} onClick={onClick}>
    {isSelected ? <img src={tickIcon} className={styles.tickIcon} /> : null}
    {!open ? <img src={lockIcon} className={styles.lockIcon} /> : null}
    <img src={url} className={styles.pic} />
    {!bought ? (
      <div className={styles.footer}>
        <div className={styles.tag}>{`سطح: ${level}`}</div>
        <div className={styles.tag}>
          <div
            className={styles.text}
            style={{ marginRight: price ? "4vw" : "0px" }}
          >
            {price || "رایگان"}
          </div>
          {price ? (
            <img src={coinIcon} className={styles.coinIcon} alt="" />
          ) : null}
        </div>
      </div>
    ) : null}
  </div>
);

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(Profile);
