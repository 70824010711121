import React from "react";
import { connect } from "react-redux";
import {
  cashLeaderboard,
  marathonLeaderboard,
  todayLeaderboardDaily,
  xpLeaderboard,
  xpMonthlyLeaderboard,
  yesterdayLeaderboardDaily
} from "../../actions";
import { Page, TabSelector } from "../../components";
import cupIcon from "../../resources/img/cup_icon.png";
import medalIcon from "../../resources/img/goldMedal.png";
import icFalse from "../../resources/img/ic_false.png";
import icTrue from "../../resources/img/ic_true.png";
import whiteGift from "../../resources/img/whiteGift.png";
import xpIcon from "../../resources/img/xp_icon.png";
import styles from "./RankingType.module.css";

class RankingType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      type: "",
      tabIndex: 1,
      list: [],
      self: null,
      tryName: "",
      error: ""
    };
  }

  componentDidMount() {
    const {
      match: {
        params: { type }
      }
    } = this.props;

    let tabIndex = 1;
    if (type == "All") {
      tabIndex = 2;
    }

    const { title, backgroundColor, tabs, getList } = this.getTypeConfigs(type);

    this.setState(
      { type, tabIndex, title, backgroundColor, tabs, getList },
      this.getList
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tabIndex != this.state.tabIndex) {
      this.getList();
    }
  }

  getTypeConfigs = type => {
    let title = "",
      backgroundColor = "",
      tabs = ["قبلی", "جاری"],
      getList = [];

    switch (type) {
      case "All":
        title = "جدول رقابت";
        tabs = ["بر اساس پول", "امتیاز کلی", "امتیاز این ماه"];
        getList = [cashLeaderboard, xpLeaderboard, xpMonthlyLeaderboard];
        break;

      case "USD":
        title = "لیگ‌های دلار";
        backgroundColor = "#404638";
        tabs = ["قبلی", "جاری"];
        getList = [
          () => marathonLeaderboard({ type, sub: "l" }),
          () => marathonLeaderboard({ type, sub: "c" })
        ];
        break;

      case "EUR":
        title = "لیگ‌های یورو";
        backgroundColor = "#00446B";
        tabs = ["قبلی", "جاری"];
        getList = [
          () => marathonLeaderboard({ type, sub: "l" }),
          () => marathonLeaderboard({ type, sub: "c" })
        ];
        break;

      case "M":
        title = "ماراتن استقامت";
        backgroundColor = "#A038EF";
        tabs = ["قبلی", "جاری"];
        getList = [
          () => marathonLeaderboard({ type, sub: "l" }),
          () => marathonLeaderboard({ type, sub: "c" })
        ];
        break;

      case "chr":
        title = "نفرات برتر چالش روزانه";
        backgroundColor = "#5F6734";
        tabs = ["قبلی", "جاری"];
        getList = [
          () => yesterdayLeaderboardDaily(),
          () => todayLeaderboardDaily()
        ];
        break;

      case "gPic":
        title = "لیگ حدس تصویر";
        backgroundColor = "#3F4C5F";
        tabs = ["قبلی", "جاری"];
        getList = [
          () => marathonLeaderboard({ type, sub: "l" }),
          () => marathonLeaderboard({ type, sub: "c" })
        ];
        break;

      default:
        break;
    }
    return { title, backgroundColor, tabs, getList };
  };

  getList = async () => {
    const { tabIndex, getList } = this.state;
    if (getList[tabIndex]) {
      try {
        this.setState({ isLoading: true });
        const { list, self, tryName } = await this.props.dispatch(
          getList[tabIndex]()
        );
        // tryName: "تعداد بازی" || "مرحله"

        // list && self:
        // avatar: "http://hooshebarandeh.com/data/profiles/profile_boy_013.png"
        // gift: "10٫000 تومان"
        // id: 68998
        // name: "aleeHe"
        // pos: 733
        // self: 1 || true
        // xp: 227
        // xpAll: 509
        // totalCash: 182250
        // point: 45
        // try: "" || 2

        // daily ->
        // total: 38;
        this.setState({ isLoading: false, list, self, tryName });
      } catch (error) {
        console.log("TCL: RankingType -> componentDidMount -> error", error);
        this.setState({ isLoading: false }, () => {
          this.setState({ error });
        });
      }
    }
  };

  render() {
    const {
      isLoading,
      list,
      self,
      tryName,
      tabIndex,
      type,
      error,
      title,
      backgroundColor,
      tabs
    } = this.state;

    return (
      <Page
        title={title}
        hideCoins
        hasHeader
        pageBackgroundColor={backgroundColor}
        headerColor={backgroundColor}
        isLoading={isLoading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        <TabSelector
          tabs={tabs}
          selectedIndex={tabIndex}
          onClick={tabIndex => this.setState({ tabIndex })}
        />
        <div
          className={styles.ranksContainer}
          style={
            !list.length
              ? { justifyContent: "center", alignItems: "center" }
              : null
          }
        >
          {self ? (
            <>
              <TagLine text="رتبه شما" />
              <Row {...self} key="self" tryName={tryName} />
              <TagLine text="نفرات برتر" />
            </>
          ) : null}
          {list.length ? (
            list.map((data, index) => (
              <Row
                {...data}
                key={index}
                tryName={tryName}
                isGpic={type === "gPic"}
              />
            ))
          ) : !list.length && !isLoading ? (
            <div className={styles.noTransactionText}>
              {"اطلاعاتی در دسترس نیست!"}
            </div>
          ) : null}
        </div>
      </Page>
    );
  }
}

const Row = ({
  pos,
  gift,
  avatar,
  name,
  self,
  id,
  xp,
  xpAll,
  totalCash,
  point,
  total,
  try: trid,
  tryName,
  isGpic
}) => (
  <div
    className={styles.rowContainer}
    style={{ backgroundColor: self || totalCash || gift ? "#EEFFA3" : "white" }}
  >
    <img src={avatar} className={styles.rowAvatar} alt="" />
    <div className={styles.rowLeft}>
      <div
        className={styles.rowLeftTop}
        style={{ justifyContent: xp || point ? "space-between" : "flex-end" }}
      >
        {xp || point ? (
          <div className={styles.rowScore}>
            {xp || point}
            <img
              src={isGpic ? medalIcon : xp ? xpIcon : cupIcon}
              className={styles.rowScoreIcon}
              alt=""
            />
          </div>
        ) : null}
        <div className={styles.rowName}>{name}</div>
      </div>
      <div className={styles.rowLeftBottom}>
        {tryName ? (
          <div className={styles.columnFlex}>
            <div className={styles.rowLeftBottomTitle}>
              {tryName || "سطح کاربر"}
            </div>
            <div className={styles.rowLeftBottomNumber}>{trid || "3"}</div>
          </div>
        ) : null}
        {total != null ? (
          <>
            <div
              className={styles.scoreBox}
              style={{ backgroundColor: "#00c78c" }}
            >
              <div className={styles.rowLeftBottomNumber}>{point}</div>
              <img src={icTrue} className={styles.icon} alt="" />
            </div>
            <div
              className={styles.scoreBox}
              style={{ backgroundColor: "#e10101" }}
            >
              <div className={styles.rowLeftBottomNumber}>{total - point}</div>
              <img src={icFalse} className={styles.icon} alt="" />
            </div>
          </>
        ) : null}
        {totalCash || gift ? (
          <div className={styles.rowFlex}>
            <div className={styles.rowLeftBottomNumber}>
              {gift || `${totalCash.addComma()} تومان`}
            </div>
            <img src={whiteGift} className={styles.rowScoreIcon} alt="" />
          </div>
        ) : null}
      </div>
    </div>
    <div className={styles.rowPos}>{pos}</div>
  </div>
);

const TagLine = ({ text }) => (
  <div className={styles.tagLine}>
    <div className={styles.tagLineText}>{text}</div>
  </div>
);

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(RankingType);
