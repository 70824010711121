import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./Laws.module.css";
import { Page, Button } from "../../components";
import ruleIcon from "../../resources/img/rule_icon.png";
import { appRules, updateData, acceptRules } from "../../actions";

class Laws extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingAccept: false,
      redirect: "",
      list: [],
      error: ""
    };
  }

  componentDidMount() {
    this.appRules();
  }

  appRules = async () => {
    try {
      this.setState({ isLoading: true });
      const { coin, list } = await this.props.dispatch(appRules());
      this.props.dispatch(updateData({ coin }));
      this.setState({ isLoading: false, list });
    } catch (error) {
      console.log("TCL: appRules -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  };

  acceptRules = async () => {
    try {
      this.setState({ isLoadingAccept: true });
      const { success } = await this.props.dispatch(acceptRules());
      if (success) {
        this.props.dispatch(updateData({ acceptRules: true }));
        this.setState({ isLoadingAccept: false, redirect: "/dashboard" });
      } else {
        this.setState({ isLoadingAccept: false }, () => {
          this.setState({ error: true });
        });
      }
    } catch (error) {
      console.log("TCL: acceptRules -> error", error);
      this.setState({ isLoadingAccept: false }, () => {
        this.setState({ error });
      });
    }
  };

  render() {
    const { redirect, isLoading, list, isLoadingAccept, error } = this.state;
    const { acceptRules } = this.props.data;

    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }

    return (
      <Page
        title="قوانین"
        hasHeader
        style={{ justifyContent: !list.length ? "center" : "space-between" }}
        isLoading={isLoading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        {list.length ? (
          list.map(({ title, text }, index) => (
            <Law title={title} text={text} key={index} />
          ))
        ) : !isLoading ? (
          <div className={styles.noTransactionText}>{"موردی ثبت نشده است"}</div>
        ) : null}
        {acceptRules ? null : !isLoading ? (
          <Button
            loading={isLoadingAccept}
            onClick={this.acceptRules}
            text={"قبول دارم"}
            style={{ margin: "7vw 0px", minHeight: "12vw" }}
          />
        ) : null}
      </Page>
    );
  }
}

const Law = ({ title, text }) => (
  <div className={styles.lawContainer}>
    <img src={ruleIcon} className={styles.ruleIcon} alt="" />
    <div className={styles.title}>{title}</div>
    <div className={styles.text}>{text}</div>
  </div>
);

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(Laws);
