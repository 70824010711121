import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./FreeCoin.module.css";
import {
  Page,
  LoadingMotion,
  NotifModal,
  RawNotifModal
} from "../../components";
import coinIcon from "../../resources/img/img_plus_seke.png";
import addQuestionIcon from "../../resources/img/add_q_coin.svg";
// import chargeSimIcon from "../../resources/img/ic_sim_coin.png";
import coinsIcon from "../../resources/img/ic_coins.png";
import coinsRedeemIcon from "../../resources/img/ic_prize_code.png";
import infoIcon from "../../resources/img/info_i.png";
import dudeIcon from "../../resources/img/dude_refer.png";
import gameConIcon from "../../resources/img/Group 248.svg";
import emailIcon from "../../resources/img/email.svg";
import chestIcon from "../../resources/img/chest.svg";
import {
  setRef,
  setEmail,
  redeem,
  openGift,
  getProgressiveGift,
  updateData,
  getSocial
} from "../../actions";
import {
  webShare,
  parseAction,
  validateEmail,
  getVoice,
  getRandom
} from "../../utils";

class FreeCoin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingEmail: false,
      isLoadingOpenGift: false,
      isLoadingProgressiveGift: false,
      isLoadingRedeem: false,
      isLoadingRef: false,
      redirect: "",
      ref: "",
      email: "",
      code: "",
      showRawNotifModal: false,
      showInfoModal: false,
      infoModalData: null,
      error: "",
      soundMic: ""
    };
  }

  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", this.onBackPressed);
    this.setState({ soundMic: getRandom(getVoice.free_coin) });
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackPressed);
  }

  onBackPressed = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({ redirect: "/dashboard" });
  };

  setRef = async () => {
    const { ref } = this.state;

    if (!ref.length) {
      this.setState({ error: "لطفا به صورت کامل کد را وارد نمایید" });
      return;
    }

    try {
      this.setState({ isLoadingRef: true });
      const { result, text, coin } = await this.props.dispatch(setRef({ ref }));
      // {result: 0, text: "کد معرف وارد شده معتبر نمی‌باشد!", coin: "30137"}
      if (result) {
        this.props.dispatch(updateData({ coin }));
        this.setState({
          isLoadingRef: false,
          ref: "",
          showRawNotifModal: true,
          infoModalData: {
            text,
            type: "info"
          }
        });
      } else {
        this.setState({ isLoadingRef: false }, () => {
          this.setState({ error: text });
        });
      }
    } catch (error) {
      console.log("TCL: FreeCoin -> setRef -> error", error);
      this.setState({ isLoadingRef: false }, () => {
        this.setState({ error });
      });
    }
  };

  setEmail = async () => {
    const { email } = this.state;

    if (!email.length || !validateEmail(email)) {
      this.setState({ error: "لطفا ایمیل خود را به شکل صحیح وارد نمایید" });
      return;
    }

    try {
      this.setState({ isLoadingEmail: true });
      const { message, success, coin } = await this.props.dispatch(
        setEmail({ email })
      );
      // {message: "ایمیل شما با موفقیت ویرایش شد!", success: true, coin: "30137"}
      if (success) {
        this.props.dispatch(updateData({ coin }));
        this.setState({
          isLoadingEmail: false,
          showRawNotifModal: true,
          infoModalData: {
            text: message,
            type: "info"
          }
        });
      } else {
        this.setState({ isLoadingEmail: false }, () => {
          this.setState({ error: message });
        });
      }
    } catch (error) {
      console.log("TCL: FreeCoin -> setEmail -> error", error);
      this.setState({ isLoadingEmail: false }, () => {
        this.setState({ error });
      });
    }
  };

  redeem = async () => {
    const { code } = this.state;

    if (code.length < 4) {
      this.setState({ error: "لطفا به صورت کامل کد را وارد نمایید" });
      return;
    }

    try {
      this.setState({ isLoadingRedeem: true });
      const { success, message, diamond, coin } = await this.props.dispatch(
        redeem({ code })
      );
      if (success) {
        this.props.dispatch(updateData({ coin, diamond }));
        this.setState({
          isLoadingRedeem: false,
          code: "",
          showRawNotifModal: true,
          infoModalData: {
            text: message,
            type: "info"
          }
        });
      } else {
        this.setState({ isLoadingRedeem: false }, () => {
          this.setState({ error: message });
        });
      }
    } catch (error) {
      console.log("TCL: FreeCoin -> redeem -> error", error);
      this.setState({ isLoadingRedeem: false }, () => {
        this.setState({ error });
      });
    }
  };

  openGift = async () => {
    if (this.props.data.sec) {
      this.setState({
        error: "برای دریافت سکه رایگان باید منتظر بمونی!"
      });
      return;
    }
    try {
      this.setState({ isLoadingOpenGift: true });
      const {
        coin,
        content,
        success,
        title,
        val,
        valType
      } = await this.props.dispatch(openGift());
      if (success) {
        this.props.dispatch(updateData({ coin }));
        this.setState({
          isLoadingOpenGift: false,
          showInfoModal: true,
          infoModalData: {
            centerText: true,
            title: "تبریک!",
            text: `${String(val).addComma()}تا ${
              valType === "gem" ? "الماس" : "سکه"
            } هدیه گرفتی!`,
            button2Text: "بستن"
          }
        });
      } else {
        this.setState({ isLoadingOpenGift: false }, () => {
          this.setState({ error: "برای دریافت سکه رایگان باید منتظر بمونی!" });
        });
      }
    } catch (error) {
      console.log("TCL: FreeCoin -> openGift -> error", error);
      this.setState({ isLoadingOpenGift: false }, () => {
        this.setState({ error });
      });
    }
  };

  getProgressiveGift = async () => {
    try {
      this.setState({ isLoadingProgressiveGift: true });
      const {
        freeCoinTimer,
        nextVideoTimer,
        txtCharge,
        data,
        ad
      } = await this.props.dispatch(getProgressiveGift());
      // ad:
      // actionAd: "https://play.google.com/store/apps/details?id=com.diacoipj.QDay&hl=fa"
      // btnAd: "بازی کیودی"
      // descAd: "سلام. با نصب بازی جذاب کیودی میتونید شما هم برنده جوایز +3 میلیون تومنی هر شب باشید!
      // ↵راستی در لیگ طلایی کیودی جایزه به نفرات برتر میرسه و فقط برای خودته هااا.
      // ↵
      // ↵نکته: اگر کیودی رو نصب کنید و با همین شماره که در هوش برنده عضو شدین در اون بازی هم ثبت نام کنید 1000 تومن به کیف پول شما در هوش برنده هدیه اضافه میشود"
      // homeText: "جایزه نقدی
      // ↵
      // ↵1000 تومان"
      // id: 3
      // imageAd: "https://lh3.googleusercontent.com/fchfYTueNvTKlctIdw_e6hdBxj3DSjCG-aVnrKl8Clc8XpEgE0i22OCJM253nHjvrwJA=s180-rw"
      // nameAd: "هدیه شما"
      // titletAd: "بازی جذاب کیودی"

      // data:
      // [{orderr: 1, val: 25, used: 0}, .., {orderr: 15, val: 65, used: 0}]
      this.setState({ isLoadingProgressiveGift: false });
    } catch (error) {
      console.log("TCL: FreeCoin -> getProgressiveGift -> error", error);
      this.setState({ isLoadingProgressiveGift: false, error });
    }
  };

  onInviteFriendsClick = async () => {
    const { refCode, update } = this.props.data;
    try {
      const shared = await webShare({
        text:
          "اگه میخوای هم بازی کنی هم پول برنده بشی اپلیکیشن هوش برنده رو با این لینک نصب کن و از کد معرف من برای ثبت نام استفاده کن تا هر دو سکه ببریم" +
          "\n\n" +
          `کد معرف من: ${refCode}`,
        url:
          update && update.updateUrl
            ? update.updateUrl
            : "https://myket.ir/app/com.diacoipj.hoshbarandeh"
      });
    } catch (error) {
      console.log("TCL: FreeCoin -> onInviteFriendsClick -> error", error);
      this.setState({ error: "دستگاه شما این قابلیت را پشتیبانی نمیکند" });
    }
  };

  onShareInstagramClick = async () => {
    try {
      const shared = await webShare({
        url: "http://www.instagram.com/hooshbarandeh"
      });
      if (shared) {
        await this.props.dispatch(getSocial({ instagram: true }));
      }
    } catch (error) {
      console.log("TCL: FreeCoin -> onShareInstagramClick -> error", error);
      this.setState({ error: "دستگاه شما این قابلیت را پشتیبانی نمیکند" });
    }
  };

  onCodeInfoClick = () => {
    this.setState({
      showInfoModal: true,
      infoModalData: {
        title: "کد جایزه چیه؟",
        text:
          "با دنبال‌کردن ما در اینستاگرام میتونی کدهای جایزه که میذاریم زودتر از همه باخبر بشی و کلی جایره بگیری." +
          "\n\n" +
          "فقط کافیه بعد از اومدن کد خیلی سریع بیای و داخل کادر پایین واردش کنی...",
        button: "فالو میکنم",
        onClick: this.onShareInstagramClick,
        button2Text: "بیخیال"
      }
    });
  };

  onChargeClick = () => {
    this.setState({
      showInfoModal: true,
      infoModalData: {
        title: "شارژ و اینترنت",
        text:
          "شما میتونید بدون اینکه مبلغ اضافه تری پرداخت کنید از این به بعد شارژ و اینترنت گوشیتونو هر اپراتوری که هستید از هوش برنده بخرید و به ازای خرید شارژ سکه رایگان دریافت کنید" +
          "\n\t" +
          "ینی هم شارژ میگیری هم سکه میگیری بدون اینکه مبلغ بیشتری نسبت به بقیه جاها بخوای بپردازی." +
          "\n\t" +
          "پس حتما یکبار امتحانش کن",
        button: "بزن بریم",
        onClick: this.onShareInstagramClick,
        button2Text: "بیخیال"
      }
    });
  };

  onQDayClick = () => {
    this.setState({
      showInfoModal: true,
      infoModalData: {
        title: "بازی جذاب کیودی",
        text:
          "سلام. با نصب بازی جذاب کیودی میتونید شما هم برنده جوایز +3 میلیون تومنی هرشب بشید!" +
          "\n" +
          "راستی در لیگ طلایی کیودی جایزه به نفرات برتر میرسه و فقط برا خودته هاااا." +
          "\n\n" +
          "نکته: اگر کیودی رو نصب کنید و با همین شماره که در هوش برنده عضو شدین در اون بازی هم ثبت‌نام کنید 1000 تومن به کیف پول شما در هوش برنده هدیه اضافه میشود",
        button: "بازی کیودی",
        onClick: this.onShareInstagramClick,
        button2Text: "بیخیال"
      }
    });
  };

  render() {
    const {
      redirect,
      isLoading,
      isLoadingEmail,
      isLoadingOpenGift,
      isLoadingProgressiveGift,
      isLoadingRedeem,
      isLoadingRef,
      ref,
      email,
      code,
      showRawNotifModal,
      showInfoModal,
      infoModalData,
      error,
      soundMic
    } = this.state;
    const {
      addQuestion: coinAddQuestion,
      email: coinEmail,
      refer: coinRefer,
      refered: coinRefered
    } = this.props.data.coinStats;
    const { sec } = this.props.data;

    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    }

    return (
      <Page
        showDiamonds
        showPicTickets
        hasHeader
        hideHeaderWhileLoading
        isLoading={isLoading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
        goBackOnCloseError={false}
        onBackPressed={this.onBackPressed}
        soundMic={soundMic}
      >
        <FirstRow
          refCode={ref}
          coinRefered={coinRefered}
          coinRefer={coinRefer}
          onChangeRef={ref => this.setState({ ref })}
          onSubmitRef={this.setRef}
          isLoadingRef={isLoadingRef}
          onInviteFriend={this.onInviteFriendsClick}
        />
        <SecondRow
          coinAddQuestion={coinAddQuestion}
          onAddQuestionClick={() =>
            this.setState({ redirect: "/registerQuestion" })
          }
          onChargeClick={this.onChargeClick}
        />
        <ThirdRow
          email={email}
          coinEmail={coinEmail}
          onChangeEmail={email => this.setState({ email })}
          onSubmitEmail={this.setEmail}
          isLoadingEmail={isLoadingEmail}
        />
        <FourthRow
          code={code}
          sec={sec}
          onChangeCode={code => this.setState({ code })}
          onSubmitCode={this.redeem}
          isLoadingRedeem={isLoadingRedeem}
          onCodeInfoClick={this.onCodeInfoClick}
          onQDayClick={this.onQDayClick}
          onFreeCoinClick={this.openGift}
          isLoadingOpenGift={isLoadingOpenGift}
        />
        <NotifModal
          visible={showInfoModal}
          closeModal={() => this.setState({ showInfoModal: false })}
          title={infoModalData && infoModalData.title}
          icon={infoIcon}
          text={infoModalData && infoModalData.text}
          button1Text={infoModalData && infoModalData.button}
          button2Text={infoModalData && infoModalData.button2Text}
          onClick={infoModalData && infoModalData.onClick}
          to={
            infoModalData && infoModalData.action
              ? parseAction(infoModalData.action)
              : ""
          }
          centerText={infoModalData && infoModalData.centerText}
        />
        <RawNotifModal
          visible={showRawNotifModal}
          closeModal={() => this.setState({ showRawNotifModal: false })}
          text={infoModalData && infoModalData.text}
          type={infoModalData && infoModalData.type}
        />
      </Page>
    );
  }
}

const FirstRow = ({
  refCode,
  coinRefered,
  coinRefer,
  onChangeRef,
  onSubmitRef,
  isLoadingRef,
  onInviteFriend
}) => (
  <Row singleChild>
    <GreenBox>
      <div
        className={styles.columnFlex}
        style={{ position: "relative", padding: "2vh 0px", marginRight: "8vw" }}
      >
        <img src={dudeIcon} className={styles.FirstRowImg} />
        <PrizeTag prize={coinRefer} />
        <PrizeTag prize={coinRefered} />
      </div>
      <div className={styles.columnFlex} style={{ width: "100%" }}>
        <div className={styles.rowFlex}>
          <div className={styles.shareButton} onClick={onInviteFriend}>
            {"دوستت رو معرفی کن و سکه بگیر"}
          </div>
          <div className={styles.title}>{"کد معرف"}</div>
        </div>
        <div className={styles.tipText}>
          {"کد معرف دوستت رو وارد کن و سکه بگیر"}
        </div>
        <TaggedInput
          placeholder={"کد معرف"}
          value={refCode}
          onChange={onChangeRef}
          onSubmit={onSubmitRef}
          isLoading={isLoadingRef}
          style={{ width: "90%" }}
        />
      </div>
    </GreenBox>
  </Row>
);

const SecondRow = ({ coinAddQuestion, onAddQuestionClick, onChargeClick }) => (
  <Row>
    {/* <GreenBox onClick={onChargeClick}>
      <div
        className={styles.columnFlex}
        style={{ alignItems: "flex-end", height: "15vw" }}
      >
        <div className={styles.title}>{"شارژ و اینترنت"}</div>
        <div className={styles.tipText}>{"شارژ و اینترنت بخر و سکه بگیر"}</div>
        <img src={chargeSimIcon} className={styles.chargeSimIcon} />
      </div>
    </GreenBox> */}
    <GreenBox onClick={onAddQuestionClick}>
      <img src={addQuestionIcon} className={styles.addQuestionIcon} />
      <div className={styles.columnFlex} style={{ alignItems: "flex-end" }}>
        <div className={styles.title}>{"افزودن سوال"}</div>
        <PrizeTag prize={coinAddQuestion} />
      </div>
    </GreenBox>
  </Row>
);

const ThirdRow = ({
  email,
  coinEmail,
  onChangeEmail,
  onSubmitEmail,
  isLoadingEmail
}) => (
  <Row singleChild>
    <GreenBox>
      <img src={emailIcon} className={styles.addQuestionIcon} />
      <div className={styles.columnFlex} style={{ width: "100%" }}>
        <div className={styles.rowFlex} style={{ justifyContent: "flex-end" }}>
          <PrizeTag prize={coinEmail} />
          <div className={styles.title} style={{ marginLeft: "4vw" }}>
            {"ایمیلتو وارد کن"}
          </div>
        </div>
        <TaggedInput
          placeholder={"example@email.com"}
          value={email}
          onChange={onChangeEmail}
          onSubmit={onSubmitEmail}
          isLoading={isLoadingEmail}
          style={{ width: "90%" }}
        />
      </div>
    </GreenBox>
  </Row>
);

const FourthRow = ({
  code,
  sec,
  onChangeCode,
  onSubmitCode,
  isLoadingRedeem,
  onCodeInfoClick,
  onQDayClick,
  onFreeCoinClick,
  isLoadingOpenGift
}) => (
  <Row>
    <GreenBox style={{ height: "20vh" }}>
      <img
        src={infoIcon}
        className={styles.infoIcon}
        onClick={onCodeInfoClick}
      />
      <div
        className={styles.columnFlex}
        style={{ position: "relative", width: "100%" }}
      >
        <img src={coinsRedeemIcon} className={styles.coinsIcon} />
        <div className={styles.title}>{"کد جایزه"}</div>
        <TaggedInput
          placeholder={"HooshR"}
          value={code}
          onChange={onChangeCode}
          onSubmit={onSubmitCode}
          style={{ flexDirection: "column", width: "100%" }}
          isLoading={isLoadingRedeem}
        />
      </div>
    </GreenBox>
    <GreenBox style={{ height: "20vh" }} onClick={onFreeCoinClick}>
      <div
        className={styles.columnFlex}
        style={{ position: "relative", width: "100%" }}
      >
        <img src={coinsIcon} className={styles.coinsIcon} />
        <div className={styles.title} style={{ marginTop: "3vw" }}>
          {"سکه رایگان"}
        </div>
        {sec ? (
          <div className={styles.title} style={{ marginTop: "1vw" }}>
            {sec.secToFa({ s: true, farsiDesc: false })}
          </div>
        ) : null}
        {isLoadingOpenGift ? (
          <LoadingMotion width={30} height={30} />
        ) : (
          <img src={chestIcon} style={{ width: "20vw" }} />
        )}
      </div>
    </GreenBox>
    <GreenBox style={{ height: "20vh" }} onClick={onQDayClick}>
      <div
        className={styles.columnFlex}
        style={{ position: "relative", width: "100%" }}
      >
        <img
          src={gameConIcon}
          className={styles.coinsIcon}
          style={{ width: "18vw", marginTop: "-2vw" }}
        />
        <div className={styles.title} style={{ marginTop: "8vw" }}>
          {"هدیه شما"}
        </div>
        <div className={styles.title}>{"جایزه نقدی"}</div>
        <div className={styles.title}>{`${"1000".addComma()} تومان`}</div>
      </div>
    </GreenBox>
  </Row>
);

const GreenBox = ({ children, style, onClick }) => (
  <div className={styles.greenBox} style={style} onClick={onClick}>
    {children}
  </div>
);

const PrizeTag = ({ prize, style }) => (
  <div className={styles.prizeTag} style={style}>
    {prize}
    <img src={coinIcon} className={styles.prizeIcon} />
  </div>
);

const TaggedInput = ({
  placeholder,
  value,
  onChange,
  onSubmit,
  style = {},
  isLoading
}) => (
  <div className={styles.taggedInputContainer} style={style}>
    <div className={styles.taggedInputInner}>
      <input
        className={styles.taggedInputInput}
        placeholder={placeholder}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
      />
    </div>
    <div
      className={styles.taggedInputButton}
      onClick={event => {
        event.stopPropagation();
        onSubmit();
      }}
    >
      {isLoading ? <LoadingMotion width={15} height={15} /> : "ثبت"}
    </div>
  </div>
);

const Row = ({ children, singleChild }) => (
  <div
    className={styles.row}
    style={singleChild ? { justifyContent: "center" } : null}
  >
    {children}
  </div>
);

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(FreeCoin);
