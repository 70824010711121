import React from "react";
import styles from "./DashboardButton.module.css";
import { Link } from "react-router-dom";
const classNames = require("classnames");

export const DashboardButton = ({
  children,
  style,
  marginRight,
  to,
  onClick,
  className = ""
}) =>
  to ? (
    <Link
      to={to}
      style={{ marginRight: marginRight ? "10px" : "0px", ...style }}
      className={classNames(styles.container, className)}
    >
      {children}
    </Link>
  ) : (
    <div
      onClick={onClick}
      style={{ marginRight: marginRight ? "10px" : "0px", ...style }}
      className={classNames(styles.container, className)}
    >
      {children}
    </div>
  );
