const general = {
  // ? where should i use?
  bronze_winner: 'https://hooshebarandeh.com/data/voice/bronze_winner.mp3',
  // ? where should i use?
  answer_to_question: 'https://hooshebarandeh.com/data/voice/answer_to_question.mp3',
  // ? where should i use?
  second_person: 'https://hooshebarandeh.com/data/voice/second_person.mp3',
  // ? where should i use?
  less_coin: 'https://hooshebarandeh.com/data/voice/less_coin.mp3',
  //* Done
  asked_question: 'https://hooshebarandeh.com/data/voice/asked_question.mp3',
  //* Done
  encouragement: 'https://hooshebarandeh.com/data/voice/encouragement.mp3',
  // ? where should i use?
  go_to_bronze: 'https://hooshebarandeh.com/data/voice/go_to_bronze.mp3',
  // ? where should i use?
  dont_fotget_that: 'https://hooshebarandeh.com/data/voice/dont_fotget_that.mp3',
  // ? where should i use?
  nemdunam_chie: 'https://hooshebarandeh.com/data/voice/nemdunam_chie.mp3',
  // ? where should i use?
  false_answer: 'https://hooshebarandeh.com/data/voice/false_answer.mp3',
  // ? where should i use?
  hooooshbarandeh: 'https://hooshebarandeh.com/data/voice/hooooshbarandeh.mp3',
  // ? where should i use?
  end_of_bronzeplan: 'https://hooshebarandeh.com/data/voice/end_of_bronzeplan.mp3',
  // ? where should i use?
  answer_to_your_question: 'https://hooshebarandeh.com/data/voice/answer_to_your_question.mp3',
  //* Done
  lucky_arrow: 'https://hooshebarandeh.com/data/voice/lucky_arrow.mp3',
  // ? where should i use?
  god_bless_you: 'https://hooshebarandeh.com/data/voice/god_bless_you.mp3',
  // ? where should i use?
  mission_accomplished: 'https://hooshebarandeh.com/data/voice/mission_accomplished.mp3',
  // ? where should i use?
  wow_wow: 'https://hooshebarandeh.com/data/voice/wow_wow.mp3',
  // ? where should i use?
  help_chance: 'https://hooshebarandeh.com/data/voice/help_chance.mp3',
  //* Done
  avatar_profile: 'https://hooshebarandeh.com/data/voice/avatar_profile.mp3',
};

// فروشگاه
const store = {
  //* Done
  buy_coin: 'https://hooshebarandeh.com/data/voice/store/buy_coin.mp3',
};

// سکه رایگان
const free_coin = {
  //* Done
  give_free_coin: 'https://hooshebarandeh.com/data/voice/free_coin/give_free_coin.mp3',
  //* Done
  Reagent_code: 'https://hooshebarandeh.com/data/voice/free_coin/Reagent_code.mp3',
  //* Done
  question_coin: 'https://hooshebarandeh.com/data/voice/free_coin/question_coin.mp3',
};

// گردونه
const roll = {
  // ? where should i use?
  one_turn: 'https://hooshebarandeh.com/data/voice/roll/one_turn.mp3',
  // ? where should i use?
  free_turning: 'https://hooshebarandeh.com/data/voice/roll/free_turning.mp3',
  // * Done
  turning: 'https://hooshebarandeh.com/data/voice/roll/turning.mp3',
};

// تمرین سکه دار
const practice = {
  // * Done
  coiner_practice: 'https://hooshebarandeh.com/data/voice/practice/coiner_practice.mp3',
  // ? where should i use?
  go_to_plan: 'https://hooshebarandeh.com/data/voice/practice/go_to_plan.mp3',
  // ? where should i use?
  middle_of_practice: 'https://hooshebarandeh.com/data/voice/practice/middle_of_practice.mp3',
  // ? where should i use?
  more_practice: 'https://hooshebarandeh.com/data/voice/practice/more_practice.mp3',
};

// لیدربرد
const leaderboard = {
  // ? where should i use?
  topic_leaderboard: 'https://hooshebarandeh.com/data/voice/leaderboard/topic_leaderboard.mp3',
  // ? where should i use?
  end_list: 'https://hooshebarandeh.com/data/voice/leaderboard/end_list.mp3',
  // ? where should i use?
  leader: 'https://hooshebarandeh.com/data/voice/leaderboard/leader.mp3',
  // ? where should i use?
  leader_board: 'https://hooshebarandeh.com/data/voice/leaderboard/leader_board.mp3',
};

// جواب های درست
const right_answer = {
  //* Done
  afterrightanswer: 'https://hooshebarandeh.com/data/voice/right_answer/afterrightanswer.mp3',
  //* Done
  after_right_answer: 'https://hooshebarandeh.com/data/voice/right_answer/after_right_answer.mp3',
  //* Done
  goodjob: 'https://hooshebarandeh.com/data/voice/right_answer/goodjob.mp3',
  //* Done
  right_answer: 'https://hooshebarandeh.com/data/voice/right_answer/right_answer.mp3',
  //* Done
  after_right_answer_2: 'https://hooshebarandeh.com/data/voice/right_answer/after_right_answer_2.mp3',
  //* Done
  right: 'https://hooshebarandeh.com/data/voice/right_answer/right.mp3',
};

// باختن و اشتباه زدن
const gameover = {
  //* Done
  gameover_1: 'https://hooshebarandeh.com/data/voice/gameover/gameover_1.mp3',
  //* Done
  game_over_2: 'https://hooshebarandeh.com/data/voice/gameover/game_over_2.mp3',
  //* Done
  say_buy: 'https://hooshebarandeh.com/data/voice/gameover/say_buy.mp3',
  //* Done
  gameover: 'https://hooshebarandeh.com/data/voice/gameover/gameover.mp3',
  //* Done
  game_over_4: 'https://hooshebarandeh.com/data/voice/gameover/game_over_4.mp3',
  //* Done
  gameover_gameover: 'https://hooshebarandeh.com/data/voice/gameover/gameover_gameover.mp3',
  //* Done
  after_gameover: 'https://hooshebarandeh.com/data/voice/gameover/after_gameover.mp3',
};

// زمان اتمام ، قبل اینکه زمانش تموم بشه ، بعضیاش هم موقع اتمام زمان است
const time = {
  onEnd: {
    //* Done
    oooo: 'https://hooshebarandeh.com/data/voice/time/oooo.mp3',
    //* Done
    ten_second: 'https://hooshebarandeh.com/data/voice/time/ten_second.mp3',
  },
  beforeEnd: {
    //* Done
    timer: 'https://hooshebarandeh.com/data/voice/time/timer.mp3',
    //* Done
    timeover: 'https://hooshebarandeh.com/data/voice/time/timeover.mp3',
    //* Done
    timeover2: 'https://hooshebarandeh.com/data/voice/time/timeover2.mp3',
    //* Done
    error_for_time: 'https://hooshebarandeh.com/data/voice/time/error_for_time.mp3',
  },
};

// برای خروج ها
const exit = {
  exit_1: 'https://hooshebarandeh.com/data/voice/exit/exit_1.mp3',
  going_out: 'https://hooshebarandeh.com/data/voice/exit/going_out.mp3',
  exit_end: 'https://hooshebarandeh.com/data/voice/exit/exit_end.mp3',
  dont_go: 'https://hooshebarandeh.com/data/voice/exit/dont_go.mp3',
  exit_2: 'https://hooshebarandeh.com/data/voice/exit/exit_2.mp3',
  exit_gameover: 'https://hooshebarandeh.com/data/voice/exit/exit_gameover.mp3',
  come_back: 'https://hooshebarandeh.com/data/voice/exit/come_back.mp3',
};

// هر 3 برای موقع برنده شدن کاربر
const winner = {
  //* Done
  winner: 'https://hooshebarandeh.com/data/voice/winner/winner.mp3',
  //* Done
  win: 'https://hooshebarandeh.com/data/voice/winner/win.mp3',
  //* Done
  winner_voice: 'https://hooshebarandeh.com/data/voice/winner/winner_voice.mp3',
};

export const getVoice = {
  general,
  store,
  free_coin,
  roll,
  practice,
  leaderboard,
  right_answer,
  gameover,
  time,
  exit,
  winner,
};
