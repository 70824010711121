const general = {
  baseUrl: 'https://api.hooshebarandeh.com',
  version: "1.0.0 WebApp",
  storeVersion: 1,
  pusheKey: "0gr64ww108rl79yg",
}

const envVars = {
  ...general,
}

export default {...envVars}
