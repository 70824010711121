import React from "react";
import ReactLoading from "react-loading";

export const LoadingMotion = ({
  width = 30,
  height = 30,
  color = "red",
  loadingContainer = false,
  backgroundColor = "white"
}) => (
  <div
    style={
      loadingContainer
        ? {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            borderRadius: "5px",
            backgroundColor
          }
        : null
    }
  >
    <ReactLoading type={"spin"} width={width} height={height} color={color} />
  </div>
);
