import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./Ranking.module.css";
import { Page, RankRow, RankSelectType, ScoreTag } from "../../components";
import avatarIcon from "../../resources/img/avatar_icon.png";
import leaderBoardIcon from "../../resources/img/intersection.png";
import dollarIcon from "../../resources/img/img_dollar_icon.png";
import euroIcon from "../../resources/img/img_euro_icon.png";
import newEsteghamatIcon from "../../resources/img/img_esteghamat_icon.png";
import { listLeaderboard } from "../../actions";

class Ranking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      redirect: "",
      list: [],
      error: ""
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const list = await this.props.dispatch(listLeaderboard());
      this.setState({ isLoading: false, list });
    } catch (error) {
      console.log("TCL: Ranking -> componentDidMount -> error", error);
      this.setState({ isLoading: false }, () => {
        this.setState({ error });
      });
    }
  }

  render() {
    const { isLoading, redirect, list, error } = this.state;
    if (redirect) {
      return <Redirect push={true} to={redirect} />;
    }
    const ranks = [
      {
        name: "علی حشمتی",
        prize: "1000 تومان",
        userLevel: "100",
        score: "13000"
      },
      {
        name: "علی حشمتی",
        prize: "1000 تومان",
        userLevel: "100",
        highetsAnswer: "13000"
      },
      {
        name: "علی حشمتی",
        prize: "1000 تومان",
        userLevel: "100",
        money: "13000"
      },
      {
        name: "علی حشمتی",
        gameCount: "100",
        xp: "13000"
      },
      {
        name: "علی حشمتی",
        prize: "1000 تومان",
        userLevel: "100",
        score: "13000"
      },
      {
        name: "علی حشمتی",
        prize: "1000 تومان",
        userLevel: "100",
        highetsAnswer: "13000"
      },
      {
        name: "علی حشمتی",
        prize: "1000 تومان",
        userLevel: "100",
        money: "13000"
      },
      {
        name: "علی حشمتی",
        gameCount: "100",
        xp: "13000"
      },
      {
        name: "علی حشمتی",
        prize: "1000 تومان",
        userLevel: "100",
        score: "13000"
      },
      {
        name: "علی حشمتی",
        prize: "1000 تومان",
        userLevel: "100",
        highetsAnswer: "13000"
      },
      {
        name: "علی حشمتی",
        prize: "1000 تومان",
        userLevel: "100",
        money: "13000"
      },
      {
        name: "علی حشمتی",
        gameCount: "100",
        xp: "13000"
      }
    ];
    const sortType = ["بر اساس پول", "امتیاز کلی", "امتیاز این ماه"];
    return (
      <Page
        showDiamonds
        showPicTickets
        hasHeader
        // style={{ backgroundColor: "white" }}
        // footer={<UserRank />}
        isLoading={isLoading}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        {/* <RankSelectType
          options={sortType}
          onOptionClick={() => {}}
          selectedOption={sortType[1]}
        /> */}
        {/* <div className={styles.ranksContainer}>
          {ranks.map((data, rank) => (
            <RankRow {...data} rank={rank + 1} key={rank} />
          ))}
        </div> */}
        {list.map((data, index) => (
          <TypeRow
            {...data}
            key={index}
            onClick={type => this.setState({ redirect: `rank/${type}` })}
          />
        ))}
      </Page>
    );
  }
}

const TypeRow = ({ name, icon, type, onClick }) => (
  <div className={styles.typeRow} onClick={() => onClick(type)}>
    <div className={styles.text}>{name}</div>
    <img src={leaderBoardIcon} className={styles.backImg} />
    <img src={icon} className={styles.icon} />
  </div>
);

const UserRank = ({ avatar, rank }) => (
  <div className={styles.userRankContainer}>
    <ScoreTag xp={"0"} />
    <div className={styles.userLevelRight}>
      <div className={styles.userLevelContainer}>
        {rank || "?"}
        <div className={styles.userLevelTop}>{"رتبه شما"}</div>
      </div>
      <img
        src={avatar || avatarIcon}
        className={styles.userRankProfilePicture}
        alt=""
      />
    </div>
  </div>
);

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(Ranking);
