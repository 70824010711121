import env from "../env";
const { baseUrl, version } = env;

const promiseTimeout = function(ms, promise) {
  // Create a promise that rejects in <ms> milliseconds
  let timeout = new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject("Timed out in " + ms + "ms.");
    }, ms);
  });

  // Returns a race between our timeout and the passed in promise
  return Promise.race([promise, timeout]);
};

function requestPayment({ shop, codeTakhfif } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    const reqPayment = (gate = 0) =>
      fetch(`${baseUrl}/payment/request2`, {
        method: "POST",
        headers: {
          Authorization: token
        },
        body: JSON.stringify({
          shop,
          gate,
          codeTakhfif,
          versionName: version,
          wa_url: window.location.origin + window.location.pathname
          // window.location.href is not suitable
        })
      });
    // window.location.origin
    // "https://b6f97d37.ngrok.io"
    // window.location.host
    // "b6f97d37.ngrok.io"
    // window.location.href
    // "https://b6f97d37.ngrok.io/shop#/"

    // callback url
    // http://localhost:3000/shop?status=true&coin=30232&gpic=21&diamond=62#/

    try {
      const response = await promiseTimeout(10000, reqPayment());
      console.log("TCL: requestPayment -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: requestPayment -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: requestPayment -> error", error);
      if (String(error).startsWith("Timed out")) {
        try {
          const response = await reqPayment(1);
          console.log("TCL: requestPayment -> response", response);
          if (response.ok) {
            const responseJson = await response.json();
            console.log("TCL: requestPayment -> responseJson", responseJson);
            if (responseJson) {
              return Promise.resolve(responseJson);
            } else {
              return Promise.reject(true);
            }
          } else {
            return Promise.reject(true);
          }
        } catch (error) {
          console.log("TCL: requestPayment -> error", error);
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
  };
}

export { requestPayment };
