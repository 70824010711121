import localForage from "localforage";
import { createMigrate } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { createFilter } from "redux-persist-transform-filter";
import env from "../env";
const { storeVersion } = env;

// const authFilter = createBlacklistFilter('auth', []);
const authFilter = createFilter("auth", [
  "phoneNumber",
  "token",
  "deviceToken",
  "rulesAccepted",
  "data",
  "isWaitingForPayRes"
]);

const migrations = {};

const whitelist = ["auth"];

const transforms = [authFilter];

const persistConfig = {
  key: "root",
  version: storeVersion,
  storage: localForage,
  whitelist,
  transforms,
  migrate: createMigrate(migrations, { debug: false }),
  stateReconciler: autoMergeLevel2
};

export default { ...persistConfig };
