import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Drawer.module.css";
import { ModalForm } from "./index";
import { Link } from "react-router-dom";
import microphoneONIcon from "../resources/img/microphone.png";
import microphoneOFFIcon from "../resources/img/mute_game.png";
import speakerONIcon from "../resources/img/play_voice.png";
import speakerOFFIcon from "../resources/img/mute_voice.png";
import telegramIcon from "../resources/img/telegram_object.png";
import instagramIcon from "../resources/img/insta.png";
import coinIcon from "../resources/img/coin_main.png";

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { visible, isMicActive, isSpeakerActive, data = {}, onProfileClick } = this.props;
    const { coinStats } = data;

    return (
      <ModalForm
        handleCloseModal={this.closeModal}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          transformOrigin: "right",
          maxHeight: "90%"
        }}
        outsideStyle={{ justifyContent: "flex-end" }}
      >
        <div className={styles.drawerConatainer}>
          <div className={styles.drawerRow}>
            <DrawerButton text="تاریخچه سکه" to="/coinHistory" />
            <DrawerButton text="پروفایل شما" onClick={onProfileClick} />
          </div>
          <DrawerShareButton
            prize={coinStats ? coinStats.refer : ""}
            onClick={this.props.onInviteFriendsClick}
          />
          <div className={styles.drawerRow}>
            <DrawerButton text="سوالات متداول" to="/faq" />
            <DrawerButton text="ارتباط با ما" to="/messages" />
          </div>
          <div className={styles.drawerRow}>
            <DrawerButton text="قوانین" to="/laws" />
            <DrawerButton text="سکه رایگان" to="/freeCoin" />
          </div>
          <div className={styles.drawerText}>
            {
              "در کانال تلگرام و صفحه اینستاگرام هوش برنده عضو شود و سکه جایزه بگیرید"
            }
          </div>
          <div className={styles.drawerRow}>
            <div onClick={this.props.onShareInstagramClick}>
              <img
                src={instagramIcon}
                className={styles.socialMediaIcon}
                alt=""
              />
            </div>
            <div onClick={this.props.onShareTelegramClick}>
              <img
                src={telegramIcon}
                className={styles.socialMediaIcon}
                alt=""
              />
            </div>
          </div>
          <div className={styles.drawerRow}>
            <DrawerButton
              icon={isMicActive ? microphoneONIcon : microphoneOFFIcon}
              backgroundColor="white"
              width={"20vw"}
              onClick={() => this.props.dispatch({ type: "TOGGLE_MIC" })}
            />
            <DrawerButton
              icon={isSpeakerActive ? speakerONIcon : speakerOFFIcon}
              backgroundColor="white"
              width={"20vw"}
              onClick={() => this.props.dispatch({ type: "TOGGLE_SPEAKER" })}
            />
            <DrawerButton
              text="خروج"
              backgroundColor="#E45D5A"
              width={"20vw"}
              onClick={() => this.props.dispatch({ type: "LOGGED_OUT" })}
            />
          </div>
          <a
            className={styles.drawerText}
            href="https://hooshebarandeh.com/privacy-policy"
          >
            {"Privacy and Policy"}
          </a>
        </div>
      </ModalForm>
    );
  }
}

const DrawerButton = ({
  text,
  icon,
  backgroundColor = "#09A25F",
  width,
  to,
  onClick
}) =>
  to ? (
    <Link
      to={to}
      className={styles.drawerButton}
      style={{ backgroundColor, width, textDecoration: "none" }}
    >
      {text || <img src={icon} className={styles.drawerButtonIcon} alt="" />}
    </Link>
  ) : (
    <div
      className={styles.drawerButton}
      style={{ backgroundColor, width }}
      onClick={onClick}
    >
      {text || <img src={icon} className={styles.drawerButtonIcon} alt="" />}
    </div>
  );

const DrawerShareButton = ({ prize, onClick }) => (
  <div className={styles.drawerShareButton} onClick={onClick}>
    {"معرفی به دوستان"}
    <div className={styles.drawerShareButtonPrize}>
      {prize}
      <img
        src={coinIcon}
        className={styles.drawerShareButtonPrizeIcon}
        alt=""
      />
    </div>
  </div>
);

const select = store => {
  return {
    data: store.auth.data,
    isSpeakerActive: store.auth.isSpeakerActive,
    isMicActive: store.auth.isMicActive
  };
};

export default connect(select)(Drawer);
