import React, { Fragment } from "react";
import styles from "./ShopButton.module.css";
import { CoinPrice, Button } from "./index";
import discountBadgeIcon from "../resources/img/test___2.png";

export const ShopButton = ({
  icon,
  iconStyle,
  beforeDiscountPrice,
  price,
  title,
  stock,
  coinPrice,
  buyCount,
  onClick,
  loading,
  isConvert
}) => (
  <div className={styles.container} onClick={onClick}>
    {beforeDiscountPrice ? (
      <img
        src={discountBadgeIcon}
        className={styles.discountBadgeIcon}
        alt=""
      />
    ) : null}
    <div className={styles.coinCardTopContainer}>
      <CoinPrice price={coinPrice} reverseColor icon={icon} iconStyle={iconStyle} />
      {isConvert ? (
        <CoinPrice price={price} />
      ) : (
        <>
          <div className={styles.price}>{price.addComma() + " تومان"}</div>
          <div className={styles.beforeDiscountPrice}>
            {beforeDiscountPrice
              ? beforeDiscountPrice.addComma() + " تومان"
              : ""}
          </div>
        </>
      )}
    </div>
    {/* <Fragment>
        <Title text={title} icon={icon} />
        <Stock amount={stock} />
        <CoinPrice price={coinPrice} />
      </Fragment> */}
    <Button
      text={isConvert ? "تبدیل" : `خرید${buyCount ? " +" + buyCount : ""}`}
      loading={loading}
      backgroundColor={beforeDiscountPrice ? "#723739" : null}
    />
  </div>
);

const Title = ({ text, icon }) => (
  <div className={styles.titleContainer}>
    {text}
    <img src={icon} className={styles.titleIcon} alt="" />
  </div>
);

const Stock = ({ amount }) => (
  <div className={styles.stockContainer}>{`موجودی: ${amount}`}</div>
);
