import env from "../env";
const { baseUrl } = env;

function watchVideo({ provider, videoModel } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(
        `${baseUrl}/watchVideoV2/${provider}/${videoModel}`,
        {
          headers: {
            Authorization: token
          }
        }
      );
      console.log("TCL: watchVideo -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: watchVideo -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: watchVideo -> error", error);
      return Promise.reject(error);
    }
  };
}

export { watchVideo };
