import React from "react";
import { connect } from "react-redux";
import styles from "./Page.module.css";
import Protected from "./protected";
import { goBack } from "connected-react-router";
import { LoadingMotion, RawNotifModal } from "./index";
import backIcon from "../resources/img/back_button.png";
import coinIcon from "../resources/img/coin_main.png";
import gemIcon from "../resources/img/gem.svg";
import gpicIcon from "../resources/img/game_ticket.png";
// import ErrorModal from "./ErrorModal";
import { Sound } from "./Sound";

class Page extends React.Component {
  handleCloseError = () => {
    const { onCloseErrorModal, goBackOnCloseError = true } = this.props;

    onCloseErrorModal();
    if (goBackOnCloseError) {
      this.props.dispatch(goBack());
    }
  };

  renderContent = () => {
    const {
      children,
      style,
      pageBackgroundColor,
      title = "",
      hasHeader,
      onBackPressed,
      headerColor,
      footer,
      isLoading,
      hideHeaderWhileLoading,
      transparentLoading = false,
      userData,
      hideBack,
      hideCoins,
      showDiamonds,
      showPicTickets,
      data,
      error,
      soundSpeaker,
      soundMic,
      isSpeakerActive,
      isMicActive
    } = this.props;

    const coin = data.coin || "?";
    const diamond = data.diamond || "?";
    const picTickets = data.tGpic || "?";

    return (
      <Content
        children={children}
        style={style}
        pageBackgroundColor={pageBackgroundColor}
        title={title}
        hasHeader={hasHeader}
        onBackPressed={onBackPressed}
        headerColor={headerColor}
        footer={footer}
        isLoading={isLoading}
        hideHeaderWhileLoading={hideHeaderWhileLoading}
        transparentLoading={transparentLoading}
        coin={coin}
        diamond={diamond}
        picTickets={picTickets}
        hideBack={hideBack}
        hideCoins={hideCoins}
        showDiamonds={showDiamonds}
        showPicTickets={showPicTickets}
        dispatch={this.props.dispatch}
        soundSpeaker={soundSpeaker}
        soundMic={soundMic}
        isSpeakerActive={isSpeakerActive}
        isMicActive={isMicActive}
        error={error}
        onCloseErrorModal={this.handleCloseError}
      />
    );
  };

  render() {
    const { isProtected = true } = this.props;

    if (isProtected) {
      return <Protected>{this.renderContent()}</Protected>;
    } else {
      return this.renderContent();
    }
  }
}

const Content = ({
  children,
  style,
  pageBackgroundColor,
  title,
  hasHeader,
  onBackPressed,
  headerColor,
  footer,
  isLoading,
  hideHeaderWhileLoading,
  transparentLoading,
  coin,
  diamond,
  picTickets,
  hideBack,
  hideCoins,
  showDiamonds,
  showPicTickets,
  dispatch,
  soundSpeaker,
  soundMic,
  isSpeakerActive,
  isMicActive,
  error,
  onCloseErrorModal
}) => (
  <div
    className={styles.container}
    style={
      pageBackgroundColor ? { backgroundColor: pageBackgroundColor } : null
    }
  >
    {hasHeader && (!isLoading || !hideHeaderWhileLoading) ? (
      <Header
        headerColor={headerColor}
        title={title}
        coin={coin}
        diamond={diamond}
        picTickets={picTickets}
        hideBack={hideBack}
        hideCoins={hideCoins}
        showDiamonds={showDiamonds}
        showPicTickets={showPicTickets}
        dispatch={dispatch}
        onBackPressed={onBackPressed}
      />
    ) : null}
    {isLoading ? (
      <div
        className={styles.loadingModal}
        style={{
          height: hasHeader && transparentLoading ? "90%" : "100%",
          ...(transparentLoading ? { position: "fixed" } : {}),
          backgroundColor: `${pageBackgroundColor || "#038e6e"}${
            transparentLoading ? "8e" : ""
          }`
        }}
      >
        <LoadingMotion loadingContainer />
      </div>
    ) : null}
    {(isLoading && transparentLoading) || !isLoading ? (
      <>
        <div
          className={styles.body}
          style={{
            ...style,
            ...(pageBackgroundColor
              ? { backgroundColor: pageBackgroundColor }
              : {})
          }}
        >
          {children}
          {soundSpeaker && isSpeakerActive ? (
            <Sound src={soundSpeaker} />
          ) : null}
          {soundMic && isMicActive ? <Sound src={soundMic} /> : null}
          <RawNotifModal
            visible={!!error}
            closeModal={onCloseErrorModal}
            text={error}
            type="error"
          />
        </div>
        <Footer content={footer} />
      </>
    ) : null}
  </div>
);

const CoinContainer = ({ icon, iconStyle, coin, style }) => (
  <div className={styles.coinContainer} style={style}>
    {(coin || "0").addComma()}
    <img
      src={icon || coinIcon}
      className={styles.coinIcon}
      alt=""
      style={iconStyle}
    />
  </div>
);

const Header = ({
  headerColor,
  title,
  coin,
  diamond,
  picTickets,
  hideBack,
  onBackPressed,
  hideCoins,
  showDiamonds,
  showPicTickets,
  dispatch
}) => (
  <div
    className={styles.header}
    style={{
      backgroundColor: headerColor || "transparent",
      height: showDiamonds ? "10%" : "8%"
    }}
  >
    {!hideBack ? (
      <img
        src={backIcon}
        className={styles.backIcon}
        onClick={() => {
          if (onBackPressed) {
            onBackPressed();
          } else {
            dispatch(goBack());
          }
        }}
        alt=""
      />
    ) : null}
    {title ? <div className={styles.title}>{title}</div> : null}
    <div
      className={styles.rightContainer}
      style={{ justifyContent: showDiamonds ? "space-between" : "center" }}
    >
      {showDiamonds ? (
        <CoinContainer
          coin={diamond}
          style={{ backgroundColor: "#030E3C", color: "gold" }}
          icon={gemIcon}
        />
      ) : null}
      {showPicTickets ? (
        <CoinContainer coin={picTickets} icon={gpicIcon} />
      ) : null}
      {!hideCoins ? <CoinContainer coin={coin} /> : null}
    </div>
  </div>
);

const Footer = ({ content }) =>
  content ? <div className={styles.footer}>{content}</div> : null;

const select = store => {
  return {
    data: store.auth.data,
    isSpeakerActive: store.auth.isSpeakerActive,
    isMicActive: store.auth.isMicActive
  };
};

export default connect(select)(Page);
