import React from "react";
import styles from "./TabSelector.module.css";

export const TabSelector = ({ tabs, selectedIndex, onClick }) => (
  <div className={styles.container}>
    {tabs.map((text, index) => (
      <div
        key={index}
        className={styles.option}
        style={
          selectedIndex == index
            ? { color: "white", backgroundColor: "#1C3A3C" }
            : null
        }
        onClick={() => onClick(index)}
      >
        {text}
      </div>
    ))}
  </div>
);
