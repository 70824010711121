import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./NotifModal.module.css";
import { ModalForm } from "./index";
import { Link } from "react-router-dom";
import infoIcon from "../resources/img/info_i.png";

class NotifModal extends Component {
  constructor(props) {
    super(props);
    this.initialState = {};
    this.state = { ...this.initialState };
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visible && nextProps.visible) {
    }
    if (this.props.visible && !nextProps.visible) {
      this.setState({ ...this.initialState });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const {
      visible,
      title,
      icon,
      text,
      button1Text,
      button2Text,
      onClick,
      button2OnClick,
      to,
      dismissModal,
      centerText
    } = this.props;

    return (
      <ModalForm
        handleCloseModal={() => {
          this.closeModal();
          if (dismissModal) {
            dismissModal();
          }
        }}
        mounted={visible}
        style={{
          backgroundColor: "transparent",
          justifyContent: "center"
        }}
      >
        <div className={styles.startConatainer}>
          {title ? (
            <div className={styles.header}>
              {title}
              <img src={icon || infoIcon} className={styles.startIcon} alt="" />
            </div>
          ) : null}
          <div
            className={styles.startText}
            style={centerText ? { textAlign: "center" } : null}
          >
            {String(text)}
          </div>
          <div
            className={styles.startButtonsContainer}
            style={{
              justifyContent:
                button1Text && button2Text ? "space-between" : "center"
            }}
          >
            {button2Text ? (
              <div
                className={styles.startButtonText}
                onClick={() => {
                  this.closeModal();
                  if (button2OnClick) {
                    button2OnClick();
                  }
                  if (dismissModal) {
                    dismissModal();
                  }
                }}
              >
                {button2Text}
              </div>
            ) : null}
            {!button1Text ? null : to ? (
              <Link
                to={to}
                style={{
                  backgroundColor: "var(--green)",
                  color: "white",
                  width: "50vw",
                  marginLeft: "3vw"
                }}
                className={styles.startButtonText}
              >
                {button1Text}
              </Link>
            ) : (
              <div
                className={styles.startButtonText}
                onClick={onClick}
                style={{
                  backgroundColor: "var(--green)",
                  color: "white",
                  width: "50vw",
                  marginLeft: "3vw"
                }}
              >
                {button1Text}
              </div>
            )}
          </div>
        </div>
      </ModalForm>
    );
  }
}

const select = store => {
  return {
    data: store.auth.data
  };
};

export default connect(select)(NotifModal);
