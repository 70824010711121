import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import styles from "./CheckCode.module.css";
import { checkCode, checkMobile, getData } from "../../actions";
import { Page, LoginBox } from "../../components";

class CheckCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      redirect: "",
      timer: 60,
      error: ""
    };
  }

  componentDidMount() {
    this.setTimerInterval();
  }

  componentWillUnmount() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  setTimerInterval = () => {
    this.timerInterval = setInterval(
      () => this.setState({ timer: this.state.timer - 1 }),
      1000
    );
  };

  checkCode = async code => {
    const { phoneNumber: mobile } = this.props;
    this.setState({ loading: true });

    try {
      await this.props.dispatch(checkCode({ code, mobile }));
      const { acceptRules } = await this.props.dispatch(getData());
      const redirect = acceptRules ? "/dashboard" : "/laws";
      this.setState({
        loading: false,
        redirect
      });
    } catch (error) {
      console.log("TCL: CheckCode -> checkCode -> error", error);
      this.setState({ loading: false }, () => {
        this.setState({ error });
      });
    }
  };

  renderTipText = () => {
    const { timer } = this.state;
    if (timer === 0) {
      clearInterval(this.timerInterval);
      return "برای ارسال مجدد کلیک کنید!";
    }
    return `ارسال مجدد پیام در ${timer} ثانیه`;
  };

  sendCodeAgain = async () => {
    const { phoneNumber } = this.props;
    const { timer } = this.state;
    if (timer === 0) {
      this.setState({ loading: true });
      try {
        const response = await this.props.dispatch(checkMobile(phoneNumber));
        console.log("TCL: CheckCode -> sendCodeAgain -> response", response);
        if (response === 0 || response === 1) {
          this.setState({ loading: false, timer: 60 });
          this.setTimerInterval();
        } else {
          //! err wrong number
          this.setState({ loading: false }, () => {
            this.setState({ error: "شماره اشتباهی وارد کرده‌اید" });
          });
        }
      } catch (error) {
        console.log("TCL: CheckCode -> sendCodeAgain -> error", error);
        this.setState({ loading: false }, () => {
          this.setState({ error });
        });
      }
    }
  };

  render() {
    const { phoneNumber } = this.props;
    const { loading, redirect, error } = this.state;

    if (redirect) {
      return <Redirect push={false} to={redirect} />;
    }

    return (
      <Page
        isProtected={false}
        style={{ justifyContent: "center" }}
        error={error}
        onCloseErrorModal={() => this.setState({ error: "" })}
      >
        <LoginBox
          title={`کد ارسال شده به ${phoneNumber}`}
          firstPlaceholder="کد تایید"
          tipText={this.renderTipText()}
          onTipTextClick={this.sendCodeAgain}
          buttonText="ورود"
          loading={loading}
          onSubmit={this.checkCode}
          type="number"
        />
      </Page>
    );
  }
}

const select = store => {
  return {
    phoneNumber: store.auth.phoneNumber
  };
};

export default connect(select)(CheckCode);
