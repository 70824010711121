export function parseAction(action) {
  const actionsTable = {
    roll: "/wheel", //گردونه
    shop: "/shop", //فروشگاه
    // cancel: "", //بستن
    game: "/selectPlan/0", //لیست بازی‌ها
    gameS: "/selectPlan/1", //لیست بازی‌های ویژه
    // video: "", //ویدئو
    wall: "/wallet", //کیف پول
    buyT: "/shop", //کیف پول
    picG: "/gamePic", //صفحه حدس تصویر
    main: "/dashboard", //صفحه حدس تصویر
    // cont: "", //ویدئو
    free: "/freeCoin" //صفحه رایگان‌ها
  };

  return actionsTable[action] || false;
}
