import env from "../env";
const { baseUrl } = env;

function openGift() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/gift/open_V2`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: openGift -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: openGift -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: openGift -> error", error);
      return Promise.reject(error);
    }
  };
}

function getProgressiveGift() {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/gift/progressive/getV2`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: getProgressiveGift -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: getProgressiveGift -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: getProgressiveGift -> error", error);
      return Promise.reject(error);
    }
  };
}

export { openGift, getProgressiveGift };
