import * as Sentry from '@sentry/browser';
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import { configureStore, history } from "./store/configureStore";

Sentry.init({dsn: "https://d207da0964a74708b7dbad1061e7d952@sentry.io/5148019"});
const { store, persistor } = configureStore();

Number.prototype.secToFa = function({
  d = false,
  h = true,
  m = true,
  s = false,
  ceilD = false,
  farsiDesc = true
} = {}) {
  const sec_num = parseInt(this, 10); // don't forget the second param
  const days = Math.floor(sec_num / 3600 / 24);
  const hours = Math.floor((sec_num - days * 3600 * 24) / 3600);
  const minutes = Math.floor((sec_num - days * 3600 * 24 - hours * 3600) / 60);
  const seconds = sec_num - days * 3600 * 24 - hours * 3600 - minutes * 60;

  const res = farsiDesc
    ? `${d ? (ceilD ? days + 1 : days) + " روز " : ""}${d && h ? " و " : ""}${
        h ? hours + " ساعت " : ""
      }${h && m ? " و " : ""}${m ? minutes + " دقیقه " : ""}${
        m && s ? " و " : ""
      }${s ? seconds + " ثانیه" : ""}`
    : `${h ? hours : ""}${h && m ? ":" : ""}${m ? minutes : ""}${
        m && s ? ":" : ""
      }${s ? seconds : ""}`;

  return res;
};
String.prototype.secToFa = function({
  d = false,
  h = true,
  m = true,
  s = false,
  ceilD = false,
  farsiDesc = true
} = {}) {
  const sec_num = parseInt(this, 10); // don't forget the second param
  const days = Math.floor(sec_num / 3600 / 24);
  const hours = Math.floor((sec_num - days * 3600 * 24) / 3600);
  const minutes = Math.floor((sec_num - days * 3600 * 24 - hours * 3600) / 60);
  const seconds = sec_num - days * 3600 * 24 - hours * 3600 - minutes * 60;

  const res = farsiDesc
    ? `${d ? (ceilD ? days + 1 : days) + " روز " : ""}${d && h ? " و " : ""}${
        h ? hours + " ساعت " : ""
      }${h && m ? " و " : ""}${m ? minutes + " دقیقه " : ""}${
        m && s ? " و " : ""
      }${s ? seconds + " ثانیه" : ""}`
    : `${h ? hours : ""}${h && m ? ":" : ""}${m ? minutes : ""}${
        m && s ? ":" : ""
      }${s ? seconds : ""}`;

  return res;
};
Number.prototype.addComma = function() {
  return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
String.prototype.addComma = function() {
  return this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};
String.prototype.toEnDigit = function() {
  return this.replace(/[\u06F0-\u06F9]+/g, digit => {
    let ret = "";
    for (let i = 0, len = digit.length; i < len; i++) {
      ret += String.fromCharCode(digit.charCodeAt(i) - 1728);
    }
    return ret;
  });
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

