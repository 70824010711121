import env from "../env";
const { baseUrl } = env;

function viewFreePageAdId({ adID } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/freePageAd/view/${adID}`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: viewFreePageAdId -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: viewFreePageAdId -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: viewFreePageAdId -> error", error);
      return Promise.reject(error);
    }
  };
}

function clickFreePageAdId({ adID } = {}) {
  return async (dispatch, getState) => {
    const store = getState();
    const { token, deviceToken } = store.auth;

    try {
      const response = await fetch(`${baseUrl}/freePageAd/click/${adID}`, {
        headers: {
          Authorization: token
        }
      });
      console.log("TCL: clickFreePageAdId -> response", response);
      if (response.ok) {
        const responseJson = await response.json();
        console.log("TCL: clickFreePageAdId -> responseJson", responseJson);
        if (responseJson) {
          return Promise.resolve(responseJson);
        } else {
          return Promise.reject(true);
        }
      } else {
        return Promise.reject(true);
      }
    } catch (error) {
      console.log("TCL: clickFreePageAdId -> error", error);
      return Promise.reject(error);
    }
  };
}

export { viewFreePageAdId, clickFreePageAdId };
